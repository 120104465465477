import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from '../../services/events.service';
import { RoutesService } from '../../services/routes.service';


import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/index';
import { AuthState } from '../../../store/auth/auth.state';

import { User } from '@app/shared/models';



@Component({
  selector: 'app-admin-info',
  templateUrl: './admin-info.component.html',
})
export class AdminInfoComponent implements OnInit {
  currentUser: User;
  loggedUser$: Observable<AuthState>;

  constructor(
    private router: Router,
    private eventsService: EventsService,
    public routesService: RoutesService,
    private store: Store<AppState>
  ) {
      this.loggedUser$ = this.store.select(state => state.auth);
      this.loggedUser$.subscribe(res => {
        // this.currentUser = res.user;
      });
  }

  ngOnInit(): void {
    
  }
  
}
