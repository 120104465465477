import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import * as LayoutActions from '@app/store/layout/layout.actions';
import * as AsideActions from '@app/store/aside/aside.actions';

declare var noScroll: any;

@Component({
    selector: 'app-aside-secondary',
    templateUrl: './aside-secondary.component.html',
})
export class AsideSecondaryComponent implements OnDestroy {
    openAsideSecondary: boolean;

    mode: string;

    title: string;

    hasBtnOnFooter: boolean;
    isSaving: boolean;
    hideActions: boolean;

    error: string = null;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>
    ) {

        this.initSuscriptions();

    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    initSuscriptions() {
        this.suscriptionsToManage$.push(this.store.select(state => state.aside.error).subscribe(res => {
            this.error = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.layout.openAsideSecondary).subscribe(res => {
            this.openAsideSecondary = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.mode).subscribe(res => {
            this.mode = res;

            this.initMode();
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.toSave).subscribe(res => {
            this.isSaving = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.hideActions).subscribe(res => {
            this.hideActions = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.title).subscribe(res => {
            this.title = res;
        }));
    }

    initMode() {
        switch (this.mode) {
            case 'newModule':
                this.title = 'Nuevo módulo';
                this.hasBtnOnFooter = false;
                break;

            default:
                this.hasBtnOnFooter = true;
                break;
        }
    }

    close() {
        this.store.dispatch(new LayoutActions.CloseAsideSecondary());
        this.store.dispatch(new AsideActions.ResetInfo());
    }

    save() {
        this.store.dispatch(new AsideActions.SetToSave());
    }
}
