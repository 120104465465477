import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Policy } from '../models/policy';

import { PoliciesCategoriesService } from './policies-categories.service';
import { PagesService } from './pages.service';
import { CustomRequest } from '../helpers/custom-request';
import { I18n } from '../models';


@Injectable({ providedIn: 'root' })
export class PoliciesService {
    constructor(
        private categoriesService: PoliciesCategoriesService,
        private request: CustomRequest,
        private pagesService: PagesService
    ) { }

    getFilters() {
        return this.request.get(`${environment.apiUrl}/policy-category`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/policy`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/policy/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/policy/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/policy/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/policy/` + id);
    }
    
    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/policy/` + id + `/enabled/` + status, null);
    }

    transformDataFromApi(data: any) {
        const policy = new Policy();

        policy._id = data._id;
        policy.title = data.title;
        policy.description = data.description;
        policy.category = data.category ? this.categoriesService.transformDataFromApi(data.category) : null;
        policy.enabled = data.enabled;
        policy.order = data.order;
        policy.created_at = data.created_at;
        policy.updated_at = data.updated_at;
        
        // policy.sites = data.sites;

        return policy;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }

    prepareToJsonLangs(dataJson: any, langs: Array<I18n>) {     
        const newJsonData = {
            politicas: this.pagesService.setNewJsonElements(dataJson, langs)
        };   

        return newJsonData;
    }
}