import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from '../../services/events.service';
import { RoutesService } from '../../services/routes.service';

import { Store } from '@ngrx/store';
import { Observable, fromEventPattern } from 'rxjs';
import { AppState } from '../../../store/index';
import * as LayoutActions from '../../../store/layout/layout.actions';
import * as loggedActions from '../../../store/auth/auth.actions';
import { MenuService } from '../../services/menu.service';
import { first } from 'rxjs/operators';
import { SitesService } from '../../services/sites.service';
import { I18n } from '../../models/i18n';
import { environment } from '../../../../environments/environment';
import { AppConfigService } from '../../services/app-config.service';

declare var noScroll: any;

@Component({
    selector: 'app-aside-left-menu',
    templateUrl: './aside-left-menu.component.html',
})
export class AsideLeftMenuComponent implements OnInit {
    openAsideConfig$: Observable<boolean>;

    currentSection$: Observable<string>;

    currentAsideSection$: Observable<string>;
    currentAsideSection = '';

    generalConfig: any;


    // currentLanguage: I18n;

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private routesService: RoutesService,
        private menuService: MenuService,
        private sitesService: SitesService,
        private appConfig: AppConfigService
    ) {
        this.generalConfig = this.appConfig.webConfig;

        this.openAsideConfig$ = this.store.select(state => state.layout.openAsideConfig);

        this.currentSection$ = this.store.select(state => state.layout.menuSection);

        this.setCurrentAsideSectionObserve();
    }

    ngOnInit() {
        // this.getMenus();

        // this.initSuscriptionsSites();
        this.initReloadChangeLanguage();
    }

    // initSuscriptionsSites() {
    //   this.sitesService.currentLanguage$.subscribe(
    //     i18n => {
    //       this.currentLanguage = i18n;
    //       this.getMenus();
    //     }
    //   );
    // }

    initReloadChangeLanguage() {
        this.store.select(state => state.layout.reload).subscribe(res => {
            if (res) {
                // this.getMenus();
            }
        });
    }


    setCurrentAsideSectionObserve() {
        this.currentAsideSection$ = this.store.select(state => state.layout.asideLeftSection);
        this.currentAsideSection$.subscribe(res => {
            this.currentAsideSection = res;
        });
    }

    closeConfigPanelAction() {
        this.store.dispatch(new LayoutActions.CloseAsideConfig());
    }

    closeConfigPanel(ev) {
        ev.preventDefault();

        this.closeConfigPanelAction();
    }


    changeSection(ev, section, itemMenu) {
        ev.preventDefault();

        this.closeConfigPanelAction();

        noScroll.off();

        this.router.navigate([this.routesService.getRoute(section, (itemMenu) ? itemMenu._id : '')], {});
    }

    logout(ev) {
        ev.preventDefault();

        this.store.dispatch(
            new loggedActions.DoLogout()
        );
    }

    hasSection(section) {
        return this.appConfig.webConfig.sections[section];
    }

    getSectionName(sectionData) {
        return this.appConfig.webConfig[sectionData] ? this.appConfig.webConfig[sectionData].name : 'NO DEFINIDO [' + sectionData + ' ]';
    }
}
