import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LocaleUtils } from '../../../helpers/locale-utils';
import { TextI18nPipe } from '../../../pipes/text-i18n.pipe';
import { I18nService } from '../../../services/18n.service';

@Component({
    selector: 'app-items-ctas',
    templateUrl: './items-ctas.component.html',
    providers: [TextI18nPipe]
})
export class ItemsCtasComponent implements OnChanges {

    @Input() dataLoaded: boolean;
    @Input() data: any;
    @Input() type: string;
    @Input() hide: boolean;
    @Input() lang: any;
    @Input() configurations: any;
    @Input() field: any;
    @Input() submitted: boolean;
    @Input() hasError: boolean;

    @Output() sendItems = new EventEmitter<any>();
    @Output() indexErased = new EventEmitter<number>();

    errorCtas: string = null;
    hasLanguages = true;
    items = [];

    indexToEdit = -1;
    panelEditShow = false;
    newCta = {
        name: '',
        errorName: false,
        text: '',
        errorText: false,
        url: '',
        errorUrl: false,
        type: '',
        analytics: {
            label: '',
            category: '',
            value: '',
            action: ''
        }
    };

    originalCta: any;

    constructor(
        private i18nService: I18nService,
        private localeUtils: LocaleUtils,
        private textI18n: TextI18nPipe
    ) {

    }

    setConfigs() {
        this.hasLanguages = this.configurations.has_languages ? this.configurations.has_languages : true;
    }

    initItems() {
        this.setConfigs();
        if (this.data) {
            this.items = this.data;
        }

        this.validCtas();
    }

    setValue() {
        this.validCtas();
        this.sendItems.emit(this.items);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataLoaded && this.dataLoaded) {
            this.initItems();
        }

        if (changes.hasError) {
            this.validCtas();
        }
    }

    drop(event: CdkDragDrop<object[]>) {
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
        this.setValue();
    }

    removeCta(ev, index) {
        this.items.splice(index, 1);
        if(this.type == 'edit-promotion') {
            this.setValue();
            this.whichIndex(index);
        }
    }

    whichIndex(i:number) {
        this.indexErased.emit(i);
    }

    addCta(ev) {
        this.newCta.errorName = !this.newCta.name;
        this.newCta.errorText = !this.newCta.text;
        this.newCta.errorUrl = !this.newCta.url;
        
        const locale = this.lang.locale;

        if (this.newCta.errorName || this.newCta.errorText || this.newCta.errorUrl) {
            return;
        }
        
        this.items.push(this.getCtaToFill(locale));
        this.resetCta();
        this.setValue();

        this.panelEditShow = false;
    }

    resetCta() {
        this.newCta.name = '';
        this.newCta.errorName = false;
        this.newCta.text = '';
        this.newCta.errorText = false;
        this.newCta.url = '';
        this.newCta.errorUrl = false;
        this.newCta.type = '';
        this.newCta.analytics.label = '';
        this.newCta.analytics.category = '';
        this.newCta.analytics.value = '';
        this.newCta.analytics.action = '';
    }

    checkFieldNew(field: string) {
        if (field === 'text') {
            this.newCta.errorText = !this.newCta.text;
            return;
        }
        if (field === 'url') {
            this.newCta.errorUrl = !this.newCta.url;
            return;
        }
    }

    validCtas() {
        let validCtas = true;
        this.errorCtas = null;

        if (this.field && this.field.required) {
                
            if (!this.items.length) {
                validCtas = false;
                this.errorCtas = 'length';
            }

            if (this.configurations) {
                if (this.configurations.min && this.items.length < this.configurations.min) {
                    validCtas = false;
                    this.errorCtas = 'min';
                }
            }
        }

        return validCtas;
    }

    canAddCta() {
        return (this.indexToEdit > -1) || 
            (this.configurations.max ? (this.configurations.max === -1 || this.items.length < this.configurations.max) : true);
    }

    initCta() {
        this.newCta.name = '';
        this.newCta.errorName = false;
        this.newCta.text = '';
        this.newCta.errorText = false;
        this.newCta.url = '';
        this.newCta.errorUrl = false;
        this.newCta.type = '';
        this.newCta.analytics.label = '';
        this.newCta.analytics.category = '';
        this.newCta.analytics.value = '';
        this.newCta.analytics.action = '';

        this.originalCta = null;
    }

    setCta(cta) {
        this.originalCta = cta;

        const locale = this.lang.locale;

        this.newCta.name = cta.name;
        this.newCta.errorName = false;


        this.newCta.text = (this.hasLanguages ? (cta.text[locale] ? cta.text[locale] : '') : cta.text);
        this.newCta.errorText = false;
        this.newCta.url = (this.hasLanguages ? (cta.url[locale] ? cta.url[locale] : '') : cta.url);
        this.newCta.errorUrl = false;
        this.newCta.type = cta.type ? cta.type : '';
        this.newCta.analytics.label = (cta.analytics && cta.analytics.label) ? cta.analytics.label : '';
        this.newCta.analytics.category = (cta.analytics && cta.analytics.category) ? cta.analytics.category : '';
        this.newCta.analytics.value = (cta.analytics && cta.analytics.value) ? cta.analytics.value : '';
        this.newCta.analytics.action = (cta.analytics && cta.analytics.action) ? cta.analytics.action : '';
    }

    clickNewCta() {
        this.initCta();
        this.indexToEdit = -1;
        this.panelEditShow = true;
    }

    cancelCta() {
        this.panelEditShow = false;
    }

    clickEditCta(i:number) {
        this.indexToEdit = i;
        this.setCta(this.items[i]);
        this.panelEditShow = true;
    }

    getCtaToFill(locale) {             
        let cta;
        if (this.originalCta) {
            cta = JSON.parse(JSON.stringify(this.originalCta));

            if (this.hasLanguages) {
                cta.url = this.localeUtils.getDataLocaleToForm('url,', cta.url, this.newCta.url, locale);
                cta.text = this.localeUtils.getDataLocaleToForm('text,', cta.text, this.newCta.text, locale);
            } else {
                cta.url = this.newCta.url;
                cta.text = this.newCta.text;
            }

            cta.name = this.newCta.name;
            cta.type = this.newCta.type;

            cta.analytics = {
                label: this.newCta.analytics.label,
                category: this.newCta.analytics.category,
                value: this.newCta.analytics.value,
                action: this.newCta.analytics.action
            };

        } else {
            cta = {
                url: this.hasLanguages ? this.localeUtils.getDataLocaleToForm('url,', null, this.newCta.url, locale) : this.newCta.url,
                text: this.hasLanguages ? this.localeUtils.getDataLocaleToForm('text', null, this.newCta.text, locale) : this.newCta.text,
                name: this.newCta.name,
                type: this.newCta.type,
                analytics: {
                    label: this.newCta.analytics.label,
                    category: this.newCta.analytics.category,
                    value: this.newCta.analytics.value,
                    action: this.newCta.analytics.action
                }
                // title: this.newCta.name
    
            };
        }
        


        return cta;
    }

    editCta() {
        this.newCta.errorName = !this.newCta.name;
        this.newCta.errorText = !this.newCta.text;
        this.newCta.errorUrl = !this.newCta.url;
        
        const locale = this.lang.locale;

        if (this.newCta.errorName || this.newCta.errorText || this.newCta.errorUrl) {
            return;
        }
       
        this.items[this.indexToEdit] = this.getCtaToFill(locale);
        this.resetCta();
        this.setValue();
        this.panelEditShow = false;
    }

}
