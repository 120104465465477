export class VideoCategory {
    _id: string;
    enabled: boolean;
    name: any;
    code: string;
    order: number;
    sites: Array<string>;

    // created_at: string;
    // updated_at: string;
    // policies: Array<any>;
    
}