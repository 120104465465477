import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { WrapperComponent } from './wrapper/wrapper.component';
import { HeaderMobileComponent } from '../components/header-mobile/header-mobile.component';
import { MainMenuComponent } from '../components/main-menu/main-menu.component';
import { AsideLeftMenuComponent } from '../components/aside-left-menu/aside-left-menu.component';
import { AdminInfoComponent } from '../components/admin-info/admin-info.component';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { PaginatorComponent } from '../components/paginator/paginator.component';
import { DialogConfirmComponent } from '../components/dialog/dialog-confirm/dialog-confirm.component';
import { DialogErrorComponent } from '../components/dialog/dialog-error/dialog-error.component';


import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';

import { SitesComponent } from '../components/sites/sites.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModulesComponent } from '../components/modules/modules.component';
import { DialogInfoComponent } from '../components/dialog/dialog-info/dialog-info.component';
import { AsideSecondaryComponent } from '../components/aside-secondary/aside-secondary.component';
import { NewModuleComponent } from '../components/modules/new-module/new-module.component';
import { UndefinedComponent } from '../components/modules/undefined/undefined.component';
import { PipesModule } from '../pipes/pipes.module';


import { CommonConfigurationsComponent } from '../components/modules/common-configurations/common-configurations.component';
import { CommonItemsComponent } from '../components/modules/common-items/common-items.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ItemsInfoComponent } from '../components/modules/items-info/items-info.component';
import { ItemsIconComponent } from '../components/modules/items-icon/items-icon.component';
// import { ItemsCtasComponent } from '../components/modules/items-ctas/items-ctas.component';
import { SitesFormItemComponent } from '../components/sites-form-item/sites-form-item.component';
import { PageContentComponent } from '../components/page-content/page-content.component';
import { AsideItemsComponent } from '../components/aside-items/aside-items.component';
import { MenuService } from '../services/menu.service';
import { ItemLiteralComponent } from '../components/items-literal/items-literal.component';

import { I18nsComponent } from '../components/i18ns/i18ns.component';
import { I18nsTranslateComponent } from '../components/i18ns-translate/i18ns-translate.component';

import { JoditAngularModule } from 'jodit-angular';

import { CommonComponent } from '../components/modules/common/common.component';
import { EditCommonComponent } from '../components/modules/common/edit/edit-common.component';
import { CtasModuleModule } from '@app/ctas-module.module';
import { CommonAsideComponent } from '../components/modules/common-aside/common-aside.component';
import { CategoryRelationComponent } from '../components/modules/fields/category-relation/category-relation.component';
import { ModulesContentComponent } from '../components/modules-content/modules-content.component';
import { FieldItemsComponent } from '../components/modules/fields/field-items/field-items.component';
import { TagsTranslateComponent } from '../components/modules/tags-translate/tags-translate.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,

    FormsModule,
    ReactiveFormsModule,
    PipesModule,

    DragDropModule,
    JoditAngularModule,
    CtasModuleModule
  ],
  declarations: [
    WrapperComponent,
    HeaderMobileComponent,
    MainMenuComponent,
    AsideLeftMenuComponent,
    AsideSecondaryComponent,
    AsideItemsComponent,
    AdminInfoComponent,

    BreadcrumbComponent,
    PaginatorComponent,
    DialogConfirmComponent,
    DialogErrorComponent,
    DialogInfoComponent,
    SitesComponent,
    SitesFormItemComponent,
    PageContentComponent,
    ModulesContentComponent,
    I18nsComponent,
    I18nsTranslateComponent,

    ItemLiteralComponent,

    ModulesComponent,
    NewModuleComponent,

    CommonComponent,
    EditCommonComponent,
    CommonAsideComponent,

    UndefinedComponent,

    CommonConfigurationsComponent,
    CommonItemsComponent,
    ItemsInfoComponent,
    ItemsIconComponent,

    CategoryRelationComponent,
    FieldItemsComponent,
    TagsTranslateComponent
    // ItemsCtasComponent,
  ],

  exports: [
    BreadcrumbComponent,
    PaginatorComponent,
    DialogConfirmComponent,
    DialogErrorComponent,
    SitesComponent,
    ModulesComponent,
    NewModuleComponent,
    SitesFormItemComponent,
    PageContentComponent,
    ModulesContentComponent,
    ItemLiteralComponent,
    I18nsComponent,
    I18nsTranslateComponent,
    ItemsIconComponent,
    TagsTranslateComponent
  ],

  providers: [
    MenuService
  ],

  entryComponents: [
  ]
})
export class LayoutsModule {
}
