import { Action } from '@ngrx/store';

// LEADS
export const DO_GET_INFO_LEADS = '[Leads] Get Info Leads';
export class DoGetInfoLeads implements Action {
    type = DO_GET_INFO_LEADS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_LEADS_OK = '[Leads] Get Info Leads OK';
export class DoGetInfoLeadsOK implements Action {
    type = DO_GET_INFO_LEADS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_LEADS_KO = '[Leads] Get Info Leads KO';
export class DoGetInfoLeadsKO implements Action {
    type = DO_GET_INFO_LEADS_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_LEADS_FILTERS = '[Leads] Get Info Filters';
export class DoGetInfoLeadsFilters implements Action {
    type = DO_GET_INFO_LEADS_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_LEADS_FILTERS_OK = '[Leads] Get Info Filters OK';
export class DoGetInfoLeadsFiltersOK implements Action {
    type = DO_GET_INFO_LEADS_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_LEADS_FILTERS_KO = '[Leads] Get Info Filters KO';
export class DoGetInfoLeadsFiltersKO implements Action {
    type = DO_GET_INFO_LEADS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Leads] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_TYPE_FILTERS = '[Leads] Set selected Type Filter';
export class DoSetSelectedTypeFilters implements Action {
    type = DO_SET_SELECTED_TYPE_FILTERS;
    constructor(public payload: any) { }
}

export const DO_SET_CURRENT_LEAD = '[Leads] Set current lead';
export class DoSetCurrentLead implements Action {
    type = DO_SET_CURRENT_LEAD;
    constructor(public payload: any) { }
}


export type All = DoGetInfoLeads | DoGetInfoLeadsOK | DoGetInfoLeadsKO |
    DoGetInfoLeadsFilters | DoGetInfoLeadsFiltersOK | DoGetInfoLeadsFiltersKO 
    | DoSetSelectedFilters | DoSetSelectedTypeFilters | DoSetCurrentLead
    ;
