import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { EventCategory } from '../models/event-category';
import { CustomRequest } from '../helpers/custom-request';

@Injectable({ providedIn: 'root' })
export class EventsCategoriesService {
    constructor(
        private request: CustomRequest
    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/event-category`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/event-category/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/event-category/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/event-category/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/event-category/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/event-category/` + id + `/enabled/` + status, null);
    }

    transformCategoriesFromApi(data: any) {
        const categories = [];

        data.forEach(element => {
            categories.push(this.transformDataFromApi(element));
            
        });

        return categories;
    }

    transformDataFromApi(dataCategory: any) {
        const category = new EventCategory();

        category._id = dataCategory._id;
        category.code = dataCategory.code;
        category.enabled = dataCategory.enabled;
        category.name = dataCategory.name;
        category.sites = dataCategory.sites;
        // category.posts = dataCategory.posts;

        return category;
    }
}