import { Component, Input, OnInit, OnDestroy, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { SitesService } from '../../services/sites.service';
import { Subscription } from 'rxjs';
import { I18n } from '../../models/i18n';
import { Site } from '../../models/site';

@Component({
    selector: 'app-i18ns',
    templateUrl: './i18ns.component.html',
})
export class I18nsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() currentI18n: I18n;
    @Output() sendI18n = new EventEmitter<I18n>();

    currentSite: Site = null;
    languages: Array<I18n> = null;

    currentI18nId: string;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private sitesService: SitesService
    ) {

    }

    ngOnInit() {
        this.initSuscriptions();
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    initSuscriptions() {
        this.suscriptionsToManage$.push(this.sitesService.currentSite$.subscribe(
            site => {
                this.currentSite = site;
                this.languages = site.i18n;
            }
        ));
    }

    setLanguage() {
        const i18n = this.getLanguageById(this.currentI18nId);
        this.sendI18n.emit(i18n);
    }

    getLanguageById(id: string) {
        const lang = this.languages.filter(x => x._id === id)[0];
        return lang;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentI18n) {
            this.currentI18nId = this.currentI18n._id;
        }
    }
}
