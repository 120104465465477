import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

import { AuthenticationService } from '@app/shared/services';
import { Site } from '../models/site';
import { SitesService } from '../services/sites.service';
import { I18n } from '../models/i18n';
import { I18nService } from '../services/18n.service';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

    constructor(
        private authenticationService: AuthenticationService,
        private sitesService: SitesService,
        private i18nService: I18nService
    ) {
    }

    getSiteData() {
        const currentSite = this.sitesService.getCurrentSiteStorage();
        if (!currentSite) {
            return null;
        }

        return JSON.parse(currentSite);
    }

    getLanguageData() {
        const currentLanguage = this.i18nService.getCurrentLanguage();

        return currentLanguage ? currentLanguage : null;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authData = this.authenticationService.getAuthData();
        if (!authData) {
            authData = this.authenticationService.authDataCredentials;
        }
        const site = this.getSiteData();
        const lang = this.getLanguageData();

        // console.log('COMPROBANDO ACCEPT AUTH DATA', authData);
        // console.log('COMPROBANDO ACCEPT SITE', site);
        // console.log('COMPROBANDO ACCEPT LANGUAGE', lang);

        if (authData && site && site.code && lang && lang.locale) {
            // console.log('entra por aqui con ACCEPT');
            request = request.clone({
                setHeaders: {
                    Authorization: `Basic ${authData}`,
                    'Accept-Site': `${site.code}`,
                    'Accept-Language': `${lang.locale}`
                }
            });
        } else {
            // console.log('entra por aqui sin accept site');
            request = request.clone({
                setHeaders: {
                    Authorization: `Basic ${authData}`
                }
            });
        }

        return next.handle(request);
    }
}