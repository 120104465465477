import { Action } from '@ngrx/store';

// OBITUARIES
export const DO_GET_INFO_OBITUARIES = '[Obituaries] Get Info Obituaries';
export class DoGetInfoObituaries implements Action {
    type = DO_GET_INFO_OBITUARIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_OBITUARIES_OK = '[Obituaries] Get Info Obituaries OK';
export class DoGetInfoObituariesOK implements Action {
    type = DO_GET_INFO_OBITUARIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_OBITUARIES_KO = '[Obituaries] Get Info Obituaries KO';
export class DoGetInfoObituariesKO implements Action {
    type = DO_GET_INFO_OBITUARIES_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Obituaries] Get Info Filters';
export class DoGetInfoObituariesFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Obituaries] Get Info Filters OK';
export class DoGetInfoObituariesFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Obituaries] Get Info Filters KO';
export class DoGetInfoObituariesFiltersKO implements Action {
    type = DO_GET_INFO_OBITUARIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Obituaries] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoObituaries | DoGetInfoObituariesOK | DoGetInfoObituariesKO |
    DoGetInfoObituariesFilters | DoGetInfoObituariesFiltersOK | DoGetInfoObituariesFiltersKO 
    | DoSetSelectedFilters
    ;
