import { Action } from '@ngrx/store';

// Promotions
export const DO_GET_INFO_PROMOTIONS = '[Promotions] Get Info Promotions';
export class DoGetInfoPromotions implements Action {
    type = DO_GET_INFO_PROMOTIONS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_PROMOTIONS_OK = '[Promotions] Get Info Promotions OK';
export class DoGetInfoPromotionsOK implements Action {
    type = DO_GET_INFO_PROMOTIONS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_PROMOTIONS_KO = '[Promotions] Get Info Promotions KO';
export class DoGetInfoPromotionsKO implements Action {
    type = DO_GET_INFO_PROMOTIONS_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Promotions] Get Info Filters';
export class DoGetInfoPromotionsFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Promotions] Get Info Filters OK';
export class DoGetInfoPromotionsFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Promotions] Get Info Filters KO';
export class DoGetInfoPromotionsFiltersKO implements Action {
    type = DO_GET_INFO_PROMOTIONS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Promotions] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoPromotions | DoGetInfoPromotionsOK | DoGetInfoPromotionsKO |
    DoGetInfoPromotionsFilters | DoGetInfoPromotionsFiltersOK | DoGetInfoPromotionsFiltersKO 
    | DoSetSelectedFilters
    ;
