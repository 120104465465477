import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomRequest } from '../helpers/custom-request';
import { ModuleType } from '../models/module-type';

@Injectable({ providedIn: 'root' })
export class ModulesTypesService {
    constructor(
        private request: CustomRequest
    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/module-type`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/module-type/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/module-type/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/module-type/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/module-type/` + id);
    }

    transformDataFromApi(data: any) {
        const moduleType = new ModuleType();

        if (data) {
            moduleType.name = data.name ? data.name : '';
            moduleType._id = data._id;
            moduleType.view = data.view;
            moduleType.image = data.image;
            moduleType.enabled = data.enabled;
            moduleType.config = data.config;
            moduleType.created_at = data.created_at;
            moduleType.updated_at = data.updated_at;
        }

        return moduleType;

    }
}