import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { GalleryPost } from '../models/gallery';

import { GalleriesCategoriesService } from './galleries-categories.service';
import { PagesService } from './pages.service';
import { CustomRequest } from '../helpers/custom-request';


@Injectable({ providedIn: 'root' })
export class GalleriesService {
    constructor(
        private categoriesService: GalleriesCategoriesService,
        private pagesService: PagesService,
        private request: CustomRequest
    ) { }

    getFilters() {
        return this.request.get(`${environment.apiUrl}/gallery-category`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/gallery`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/gallery/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/gallery/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/gallery/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/gallery/` + id);
    }

    setContent(data: any) {
        return this.request.post(`${environment.apiUrl}/gallery/` + data._id + `/content/`, data.content);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/gallery/` + id + `/enabled/` + status, null);
    }

    transformDataFromApi(dataPost: any) {
        const post = new GalleryPost();

        post._id = dataPost._id;
        post.enabled = dataPost.enabled;
        post.title = dataPost.title;
        post.category = dataPost.category ? this.categoriesService.transformDataFromApi(dataPost.category) : null;

        post.image = dataPost.image;
        post.image_preview = dataPost.image_preview;
        
        post.published_at = dataPost.published_at;
        
        post.sites = dataPost.sites;
        

        // post.created_at = dataPost.created_at;
        // post.updated_at = dataPost.updated_at;

        return post;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}