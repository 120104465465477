export class Catalog {
    _id: string;
    enabled: boolean;
    name: any;
    file: any;
    file_name: any;
    image: any;
    image_name: any;
    created_at: string;
    updated_at: string;
    sites: Array<string>;
}