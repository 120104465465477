import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as videosActions from './videos.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { VideosService } from '../../shared/services/videos.service';
import { DO_GET_INFO_FILTERS, DO_GET_INFO_VIDEOS } from './videos.actions';





@Injectable()
export class VideosEffects {

    constructor(
        private videosService: VideosService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoVideos$: Observable<Action> = this.actions$.pipe(
        ofType(videosActions.DO_GET_INFO_VIDEOS),
        switchMap((action: videosActions.DoGetInfoVideos) => {
            return this.videosService.get(action).pipe(
                map( response => {
                    response.list = this.videosService.transformListFromApi(response.list);
                    return new videosActions.DoGetInfoVideosOK(response);
                }),
                catchError(error => of(new videosActions.DoGetInfoVideosKO(error)))
            );
        }));

    @Effect()
    DoGetInfoVideosFilters$: Observable<Action> = this.actions$.pipe(
        ofType(videosActions.DO_GET_INFO_FILTERS),
        switchMap((action: videosActions.DoGetInfoVideosFilters) => {
            return this.videosService.getFilters().pipe(
                map( response => new videosActions.DoGetInfoVideosFiltersOK(response)),
                catchError(error => of(new videosActions.DoGetInfoVideosFiltersKO(error)))
            );
        }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(videosActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: videosActions.DoSetSelectedFilters) => {
            return this.videosService.get(action).pipe(
                map( response => new videosActions.DoGetInfoVideosOK(response)),
                catchError(error => of(new videosActions.DoGetInfoVideosKO(error)))
            );
        }));
}
