export class DocumentCategory {
    _id: string;
    code: string;
    name: any;
    enabled: boolean;

    order:number;

    sites: Array<string>;

    // created_at: string;
    // updated_at: string;
    // posts: Array<any>;
}