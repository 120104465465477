import { GalleryCategory } from './gallery-category';
import { Page } from './page';

export class GalleryPost {
    _id: string;
    enabled: boolean;
    title: any;
    category: GalleryCategory;
    image: any;
    image_name: any;
    image_preview: any;
    image_preview_name: any;
    published_at: string;

    sites: Array<string>;


    // created_at: string;
    // updated_at: string;
    
}