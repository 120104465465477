import { Page } from './page';

export class CatalogCategory {
    _id: string;

    code: string;
    name: any;
    name_img: any;
    name_img_name: any;
    description: any;
    image: any;
    icon_hero: any;
    icons: any;
    title_list_products: any;
    description_list_products: any;
    products_list: boolean;
    products_carousel: boolean;
    order: number;
    enabled: boolean;

    page: Page;
    sites: Array<string>;
    catalogs: Array<any>;
    subcategories: Array<any>;

    created_at: string;
    updated_at: string;

}
