import { Component, Input, OnChanges } from '@angular/core';
// import { TextI18nPipe } from '../../../pipes/text-i18n.pipe';

@Component({
  selector: 'app-item-literal',
  templateUrl: './items-literal.component.html',
  // providers: [ TextI18nPipe ]
})
export class ItemLiteralComponent {

  @Input() data: any;
  @Input() textSearch: string;


  constructor(

  ) {

  }


  getJsonData(jsonData: any) {
    if (!jsonData) {
      return;
    }

    if (this.getType(jsonData) == 'object') {
      return Object.keys(jsonData);
    } else {
      return jsonData;
    }

  }

  getType(val) {
    return typeof (val);
  }


  hasTextSearch(dataAux) {
    // console.log("busca ", dataAux);
    if (!this.textSearch) {
      return true;
    }
    const textSearchClear = this.textSearch.toLowerCase();

    // console.log("key:", dataAux, '::', this.getType(dataAux), '::', this.textSearch);

    if (this.getType(dataAux) == 'string') {
      return dataAux.toLowerCase().indexOf(textSearchClear) !== -1;
    } else {
      for (let key in dataAux) {
        if (key) {
          if (key != '__proto__') {
            return this.hasTextSearch(dataAux[key]);
          }
        }
      }
    }

  }


}
