import { Action } from '@ngrx/store';

// BLOG
export const DO_GET_INFO_CATALOG_SUBCATEGORIES = '[Catalog Subcategories] Get Info Categories';
export class DoGetInfoCatalogSubcategories implements Action {
    type = DO_GET_INFO_CATALOG_SUBCATEGORIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_CATALOG_SUBCATEGORIES_OK = '[Catalog Subcategories] Get Info Categories OK';
export class DoGetInfoCatalogSubcategoriesOK implements Action {
    type = DO_GET_INFO_CATALOG_SUBCATEGORIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_CATALOG_SUBCATEGORIES_KO = '[Catalog Subcategories] Get Info Categories KO';
export class DoGetInfoCatalogSubcategoriesKO implements Action {
    type = DO_GET_INFO_CATALOG_SUBCATEGORIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Catalog Subcategories] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoCatalogSubcategories | DoGetInfoCatalogSubcategoriesOK | DoGetInfoCatalogSubcategoriesKO 
    | DoSetSelectedFilters
    ;
