import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomRequest } from '../helpers/custom-request';
import { Office } from '../models/office';
import { PagesService } from './pages.service';
import { CitiesService } from './cities.service';
import { OfficesTypesService } from './offices-types.service';
import { ProvincesService } from './provinces.service';
import { AppConfigService } from './app-config.service';
import { I18n } from '../models';

@Injectable({ providedIn: 'root' })
export class OfficesService {
    constructor(
        private request: CustomRequest,
        private citiesService: CitiesService,
        private provincesService: ProvincesService,
        private officesTypesService: OfficesTypesService,
        private pagesService: PagesService,
        private appConfig: AppConfigService

    ) { }

    getFilters() {
        return this.request.get(`${environment.apiUrl}/office-type`);
    }

    getFiltersGroup() {
        return this.request.get(`${environment.apiUrl}/office-filters`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/office`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/office/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/office/` + data._id, data);
    }

    duplicate(id) {
        return this.request.post(`${environment.apiUrl}/office/`+ id + `/duplicate`, null);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/office/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/office/` + id);
    }

    order(data) {
        return this.request.post(`${environment.apiUrl}/office/order`, data);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/office/` + id + `/enabled/` + status, null);
    }

    transformDataFromApi(data: any) {
        const configData = this.appConfig.webConfig.officesData;

        const office = new Office();

        office._id = data._id;
        office.address = data.address;
        office.postal_code = data.postal_code;
        office.business_link = data.business_link;
        office.city = configData.cityIsObject ? this.citiesService.transformDataFromApi(data.city) : data.city;
        office.province = this.provincesService.transformDataFromApi(data.province);
        office.name = data.name;
        office.name_location = data.name_location;
        office.description = data.description;
        office.email1 = data.email1;
        office.email2 = data.email2;
        office.email_commercial = data.email_commercial;
        office.phone1 = data.phone1;
        office.phone2 = data.phone2;
        office.fax = data.fax;
        office.image = data.image;
        office.location = data.location;
        office.schedule = data.schedule;
        office.main = data.main;
        office.enabled = data.enabled;
        office.navision = data.navision;

        office.type = this.officesTypesService.transformDataFromApi(data.type);
        office.page = this.pagesService.transformDataFromApi(data.page);

        office.created_at = data.created_at;
        office.updated_at = data.updated_at;
        
        return office;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }

    prepareToJsonLangs(dataJson: any, langs: Array<I18n>) {     
        const newJsonData = {
            offices: this.pagesService.setNewJsonElements(dataJson, langs)
        };   

        return newJsonData;
    }
}