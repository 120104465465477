import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import * as LayoutActions from '@app/store/layout/layout.actions';
import * as AsideItemActions from '@app/store/aside-item/aside-item.actions';
import { SaveChild } from '../../../store/aside-item/aside-item.actions';
import * as AsideActions from '@app/store/aside/aside.actions';

declare var noScroll: any;

@Component({
    selector: 'app-aside-items',
    templateUrl: './aside-items.component.html',
})
export class AsideItemsComponent implements OnDestroy {
    openAsideItems: boolean;

    indexModule: number;
    data: any;
    title: string;
    mode: string;

    section: string;

    hasBtnOnFooter: boolean;
    isSaving: boolean;
    hideActions: boolean;


    newItem = {
        title: '',
        errorTitle: false,
        description: '',
        errorDescription: false
    };

    error: string = null;
    errorChild: string = null;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>
    ) {

        this.initSuscriptions();

    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    initSuscriptions() {
        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.error).subscribe(res => {
            this.error = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.errorChild).subscribe(res => {
            this.errorChild = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.section).subscribe(res => {
            this.section = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.data).subscribe(res => {
            this.data = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.index).subscribe(index => {
            this.indexModule = index;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.layout.openAsideItems).subscribe(res => {
            this.openAsideItems = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.mode).subscribe(res => {
            this.mode = res;

            this.initMode();
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.toSave).subscribe(res => {
            this.isSaving = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.hideActions).subscribe(res => {
            this.hideActions = res;
        }));
    }

    setTitle() {
        if (this.indexModule !== null) {
            switch (this.mode) {
                case 'steps':
                    if (this.section === 'modules') {
                        this.title = this.data.name + ' - Bloque';
                    } else {
                        this.title = this.data.modules[this.indexModule].module.name + ' - Paso';
                    }
                    break;

                case 'menu-steps':
                    if (this.section === 'modules') {
                        this.title = this.data.name + ' - Bloque';
                    } else {
                        this.title = this.data.modules[this.indexModule].module.name + ' - Opción';
                    }
                    break;

                default:
                    if (this.section === 'modules') {
                        this.title = this.data.name + ' - Bloque';
                    } else {
                        this.title = this.data.modules[this.indexModule].module.name + ' - Bloque';
                    }
                    break;
            }
        } else {
            switch (this.mode) {
                case 'steps':
                    this.title = 'Paso';
                    break;

                case 'menu-steps':
                    this.title = 'Opción';
                    break;

                default:
                    this.title = 'Bloque';
                    break;
            }
        }
    }

    initMode() {
        this.setTitle();

        switch (this.mode) {
            case '':
                this.hasBtnOnFooter = false;
                break;

            default:
                this.hasBtnOnFooter = true;
                break;
        }
    }

    close() {
        this.store.dispatch(new AsideActions.ChangeHide(false));
        this.store.dispatch(new LayoutActions.CloseAsideItems());
        this.store.dispatch(new AsideItemActions.ResetInfo());
    }

    save() {
        this.store.dispatch(new AsideItemActions.ChangeToSave(true));
    }

    closeChild() {
        this.store.dispatch(new AsideItemActions.CloseForm());
    }

    saveChild() {
        this.store.dispatch(new AsideItemActions.SaveChild());
    }


}
