import { Action } from '@ngrx/store';


export const SET_INFO = '[Aside] Set Info';
export class SetInfo implements Action {
    type = SET_INFO;
    constructor(public payload: any) { }
}

export const RESET_INFO = '[Aside] Reset Info';
export class ResetInfo implements Action {
    type = RESET_INFO;
    constructor() { }
}

export const CHANGE_MODE = '[Aside] Change Mode';
export class ChangeMode implements Action {
    type = CHANGE_MODE;
    constructor(public payload: any) { }
}

export const SET_RESULT = '[Aside] Set Result';
export class SetResult implements Action {
    type = SET_RESULT;
    constructor(public payload: any) { }
}

export const SET_TO_SAVE = '[Aside] Set to Save';
export class SetToSave implements Action {
    type = SET_TO_SAVE;
    constructor() { }
}

export const CANCEL_TO_SAVE = '[Aside] Cancel to Save';
export class CancelToSave implements Action {
    type = CANCEL_TO_SAVE;
    constructor() { }
}

export const SET_TO_DELETE = '[Aside] Set to delete';
export class SetToDelete implements Action {
    type = SET_TO_DELETE;
    constructor(public payload: any) { }
}

export const SET_TO_EDIT = '[Aside] Set to Edit';
export class SetToEdit implements Action {
    type = SET_TO_EDIT;
    constructor(public payload: any) { }
}

export const SET_TO_ADD = '[Aside] Set to Add';
export class SetToAdd implements Action {
    type = SET_TO_ADD;
    constructor(public payload: any) { }
}

export const SET_TO_ACTIVE = '[Aside] Set to Active';
export class SetToActive implements Action {
    type = SET_TO_ACTIVE;
    constructor(public payload: any) { }
}

export const END_TO_ACTIVE = '[Aside] End to Active';
export class EndToActive implements Action {
    type = END_TO_ACTIVE;
    constructor() { }
}

export const CHANGE_HIDE_ACTIONS = '[Aside] Change Hide Actions';
export class ChangeHide implements Action {
    type = CHANGE_HIDE_ACTIONS;
    constructor(public payload: boolean) { }
}

export const SET_CONFIG_MODE = '[Aside Item] Change config mode';
export class SetConfigMode implements Action {
    type = SET_CONFIG_MODE;
    constructor(public payload: any) { }
}

export const SET_ERROR = '[Aside] Set Error';
export class SetError implements Action {
    type = SET_ERROR;
    constructor(public payload: any) { }
}


export type All = SetInfo | SetResult | SetToSave 
| CancelToSave | ChangeMode | ResetInfo
| SetToDelete | SetToEdit | SetToAdd | SetToActive | EndToActive | ChangeHide | SetConfigMode | SetError;
