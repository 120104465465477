import { Page } from "./page";

export class BlogCategory {
    _id: string;
    name: any;
    code: string;
    created_at: string;
    updated_at: string;
    enabled: boolean;
    sites: Array<string>;
    posts: Array<any>;
    page: Page;
    url: any;
}