import { Action } from '@ngrx/store';

// BLOG
export const DO_GET_INFO_BLOG_CATEGORIES = '[Blog Second Categories] Get Info Categories';
export class DoGetInfoBlogCategories implements Action {
    type = DO_GET_INFO_BLOG_CATEGORIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_BLOG_CATEGORIES_OK = '[Blog Second Categories] Get Info Categories OK';
export class DoGetInfoBlogCategoriesOK implements Action {
    type = DO_GET_INFO_BLOG_CATEGORIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_BLOG_CATEGORIES_KO = '[Blog Second Categories] Get Info Categories KO';
export class DoGetInfoBlogCategoriesKO implements Action {
    type = DO_GET_INFO_BLOG_CATEGORIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Blog Second Categories] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoBlogCategories | DoGetInfoBlogCategoriesOK | DoGetInfoBlogCategoriesKO 
    | DoSetSelectedFilters
    ;
