import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as leadsActions from './leads.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { LeadsService } from '../../shared/services/leads.service';
import { DO_GET_INFO_LEADS_FILTERS, DO_GET_INFO_LEADS } from './leads.actions';
import { AppState } from '../index';

import * as LeadsActions from './leads.actions';


@Injectable()
export class LeadsEffects {

    constructor(
        private leadsService: LeadsService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService,
        private store$: Store<AppState>
    ) { }

    @Effect()
    DoGetInfoLeads$: Observable<Action> = this.actions$.pipe(
        ofType(leadsActions.DO_GET_INFO_LEADS),
        switchMap((action: leadsActions.DoGetInfoLeads) => {
            return this.leadsService.get(action).pipe(
                map(response => {
                    response.list = this.leadsService.transformListFromApi(response.list);
                    return new leadsActions.DoGetInfoLeadsOK(response);
                }),
                catchError(error => of(new leadsActions.DoGetInfoLeadsKO(error)))
            );
        }));

    @Effect()
    DoGetInfoLeadsFilters$: Observable<Action> = this.actions$.pipe(
        ofType(leadsActions.DO_GET_INFO_LEADS_FILTERS),
        switchMap((action: leadsActions.DoGetInfoLeadsFilters) => {
            return this.leadsService.getFilters().pipe(
                map(response => {
                    this.store$.dispatch(
                        new LeadsActions.DoSetSelectedTypeFilters({
                            filters: {
                                textSearch: '',
                                status: -1,
                                date: '',
                                type: response.list[response.list.length - 1]._id
                            },
                        })
                    );
                    return new leadsActions.DoGetInfoLeadsFiltersOK(response);
                }),
                catchError(error => of(new leadsActions.DoGetInfoLeadsFiltersKO(error)))
            );
        }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(leadsActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: leadsActions.DoSetSelectedFilters) => {
            if (!action.payload.filters.type) {
                return of (new leadsActions.DoGetInfoLeadsKO({}));
            }
            return this.leadsService.get(action).pipe(
                map(response => new leadsActions.DoGetInfoLeadsOK(response)),
                catchError(error => of(new leadsActions.DoGetInfoLeadsKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedTypeFilters$: Observable<Action> = this.actions$.pipe(
        ofType(leadsActions.DO_SET_SELECTED_TYPE_FILTERS),
        switchMap((action: leadsActions.DoSetSelectedTypeFilters) => {
            if (!action.payload.filters.type) {
                return of (new leadsActions.DoGetInfoLeadsKO({}));
            }
            return this.leadsService.get(action).pipe(
                map(response => new leadsActions.DoGetInfoLeadsOK(response)),
                catchError(error => of(new leadsActions.DoGetInfoLeadsKO(error)))
            );
        }));

}
