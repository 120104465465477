import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { BlogCategory } from '../models/blog-category';
import { CustomRequest } from '../helpers/custom-request';
import { PagesService } from './pages.service';

@Injectable({ providedIn: 'root' })
export class BlogSecondCategoriesService {
    constructor(
        private request: CustomRequest,
        private pagesService: PagesService
    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/blog-category`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/blog-category/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/blog-category/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/blog-category/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/blog-category/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/blog-category/` + id + `/enabled/` + status, null);
    }

    movePostCategory(data: any) {
        return this.request.post(`${environment.apiUrl}/blog-category/move-posts-category`, data);
    }

    transformCategoriesFromApi(data: any) {
        const categories = [];

        data.forEach(element => {
            categories.push(this.transformDataFromApi(element));
        });

        return categories;
    }

    transformDataFromApi(dataCategory: any) {
        const category = new BlogCategory();

        category.name = dataCategory.name;
        category._id = dataCategory._id;
        category.enabled = dataCategory.enabled;
        category.code = dataCategory.code;
        category.sites = dataCategory.sites;
        category.posts = dataCategory.posts;

        category.page = dataCategory.page ? this.pagesService.transformDataFromApi(dataCategory.page) : null;

        return category;
    }
}