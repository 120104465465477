import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { GalleryCategory } from '../models/gallery-category';
import { CustomRequest } from '../helpers/custom-request';

@Injectable({ providedIn: 'root' })
export class GalleriesCategoriesService {
    constructor(
        private request: CustomRequest
    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/gallery-category`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/gallery-category/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/gallery-category/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/gallery-category/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/gallery-category/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/gallery-category/` + id + `/enabled/` + status, null);
    }

    transformCategoriesFromApi(data: any) {
        const categories = [];

        data.forEach(element => {
            categories.push(this.transformDataFromApi(element));
            
        });

        return categories;
    }

    transformDataFromApi(dataCategory: any) {
        const category = new GalleryCategory();

        category._id = dataCategory._id;
        category.code = dataCategory.code;
        category.name = dataCategory.name;
        category.enabled = dataCategory.enabled;

        category.sites = dataCategory.sites;

        return category;
    }
}