import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as testimonialsActions from './testimonials.actions';

import { TestimonialsService } from '../../shared/services/testimonials.service';



@Injectable()
export class TestimonialsEffects {

    constructor(
        private testimonialsService: TestimonialsService,
        private actions$: Actions
    ) { }

    @Effect()
    DoGetInfoTestimonials$: Observable<Action> = this.actions$.pipe(
        ofType(testimonialsActions.DO_GET_INFO_TESTIMONIALS),
        switchMap((action: testimonialsActions.DoGetInfoTestimonials) => {
            return this.testimonialsService.get(action).pipe(
                map( response => {
                    response.list = this.testimonialsService.transformListFromApi(response.list);
                    return new testimonialsActions.DoGetInfoTestimonialsOK(response);
                }),
                catchError(error => of(new testimonialsActions.DoGetInfoTestimonialsKO(error)))
            );
        }));

    // @Effect()
    // DoGetInfoTestimonialsFilters$: Observable<Action> = this.actions$.pipe(
    //     ofType(testimonialsActions.DO_GET_INFO_FILTERS),
    //     switchMap((action: testimonialsActions.DoGetInfoTestimonialsFilters) => {
    //         return this.testimonialsService.getFilters().pipe(
    //             map( response => new testimonialsActions.DoGetInfoTestimonialsFiltersOK(response)),
    //             catchError(error => of(new testimonialsActions.DoGetInfoTestimonialsFiltersKO(error)))
    //         );
    //     }));

    @Effect()
    DoSetSelectedFiltersTestimonials$: Observable<Action> = this.actions$.pipe(
        ofType(testimonialsActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: testimonialsActions.DoSetSelectedFilters) => {
            return this.testimonialsService.get(action).pipe(
                map( response => new testimonialsActions.DoGetInfoTestimonialsOK(response)),
                catchError(error => of(new testimonialsActions.DoGetInfoTestimonialsKO(error)))
            );
        }));
}
