import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as videosCategoriesActions from './videos-categories.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { VideosCategoriesService } from '../../shared/services/videos-categories.service';



@Injectable()
export class VideosCategoriesEffects {

    constructor(
        private videosCategoriesService: VideosCategoriesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoVideosCategories$: Observable<Action> = this.actions$.pipe(
        ofType(videosCategoriesActions.DO_GET_INFO_VIDEOS_CATEGORIES),
        switchMap((action: videosCategoriesActions.DoGetInfoVideosCategories) => {
            return this.videosCategoriesService.get(action).pipe(
                map( response => new videosCategoriesActions.DoGetInfoVideosCategoriesOK(response)),
                catchError(error => of(new videosCategoriesActions.DoGetInfoVideosCategoriesKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedFiltersCategories$: Observable<Action> = this.actions$.pipe(
        ofType(videosCategoriesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: videosCategoriesActions.DoSetSelectedFilters) => {
            return this.videosCategoriesService.get(action).pipe(
                map( response => new videosCategoriesActions.DoGetInfoVideosCategoriesOK(response)),
                catchError(error => of(new videosCategoriesActions.DoGetInfoVideosCategoriesKO(error)))
            );
        }));
}
