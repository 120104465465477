export class ModuleType {
    _id: string;
    view: string;
    image: string;
    image_name: string;
    name: any;
    enabled: boolean;
    config: any;
    created_at: string;
    updated_at: string;
}