import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';



export interface NtsEvent {
    key: string;
    data?: any;
}

@Injectable()
export class EventsService {
    private eventsSource: Subject<NtsEvent>;

    UPDATE_LAYOUT: string = 'UPDATE_LAYOUT';

    constructor() {
        this.eventsSource = new Subject<NtsEvent>();
    }

    broadcast(key: string, data?: any) {
        this.eventsSource.next({ key, data });
    }

    on<T>(key: any): Observable<T> {
        return this.eventsSource.asObservable().pipe(filter(e => e.key === key), map(e => <T>e.data));
    }
    onBatch(cbs: { [key: string]: (value: {}) => void }) {
        Object.keys(cbs).forEach(
            key => this.on(key).subscribe(cbs[key])
        );
    }
}
