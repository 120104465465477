import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as pressActions from './press.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { PressService } from '../../shared/services/press.service';
import { DO_GET_INFO_FILTERS, DO_GET_INFO_PRESS } from './press.actions';





@Injectable()
export class PressEffects {

    constructor(
        private pressService: PressService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoPress$: Observable<Action> = this.actions$.pipe(
        ofType(pressActions.DO_GET_INFO_PRESS),
        switchMap((action: pressActions.DoGetInfoPress) => {
            return this.pressService.get(action).pipe(
                map( response => {
                    response.list = this.pressService.transformListFromApi(response.list);
                    return new pressActions.DoGetInfoPressOK(response);
                }),
                catchError(error => of(new pressActions.DoGetInfoPressKO(error)))
            );
        }));

    @Effect()
    DoGetInfoPressFilters$: Observable<Action> = this.actions$.pipe(
        ofType(pressActions.DO_GET_INFO_FILTERS),
        switchMap((action: pressActions.DoGetInfoPressFilters) => {
            return this.pressService.getFilters().pipe(
                map( response => new pressActions.DoGetInfoPressFiltersOK(response)),
                catchError(error => of(new pressActions.DoGetInfoPressFiltersKO(error)))
            );
        }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(pressActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: pressActions.DoSetSelectedFilters) => {
            return this.pressService.get(action).pipe(
                map( response => new pressActions.DoGetInfoPressOK(response)),
                catchError(error => of(new pressActions.DoGetInfoPressKO(error)))
            );
        }));
}
