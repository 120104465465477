import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ItemsModule, ItemsModuleHelper } from '@app/shared/models/itemsModule';
import { LocaleUtils } from '@app/shared/helpers/locale-utils';
import { I18n } from '@app/shared/models/i18n';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AppState } from '../../../../store/index';
import { Store } from '@ngrx/store';
import * as AsideActions from '@app/store/aside/aside.actions';
import { TextI18nPipe } from '../../../pipes/text-i18n.pipe';
import { I18nService } from '../../../services/18n.service';
import { AppConfigService } from '../../../services/app-config.service';
import { lang } from 'moment';

@Component({
    selector: 'app-items-icon',
    templateUrl: './items-icon.component.html',
    providers: [TextI18nPipe]
})
export class ItemsIconComponent implements OnChanges {

    @Input() dataLoaded: boolean;
    @Input() data: any;
    @Input() type: string;
    @Input() hide: boolean;
    @Input() lang: any;
    @Input() languagesToTranslate: any;
    @Input() configurations: any;
    @Input() field: any;
    @Input() submitted: boolean;

    @Input() hasError: boolean;

    @Input() integrate: boolean;

    @Output() sendItems = new EventEmitter<any>();

    items = [];
    itemsValues = [];

    isIntegrate = true;

    errorIcons: string = null;
    textEnabled = false;
    textLanguage = false;

    icons: Array<any>;
    newIcon = {
        name: '',
        text: '',
        error: false,
        icon: ''
    };

    constructor(
        private store: Store<AppState>,
        private textI18n: TextI18nPipe,
        private i18nService: I18nService,
        private localeUtils: LocaleUtils,
        public appConfig: AppConfigService
    ) {
        const currentLanguage = this.i18nService.getCurrentLanguage();
    }

    setConfigs() {
        if (!this.configurations) {
            return;
        }

        this.textEnabled = this.configurations.has_text;
        this.textI18n = this.configurations.has_text_language ? this.configurations.has_text_language : false;

        this.icons = this.configurations.common ? this.appConfig.webConfig.modulesData.commonIcons : null;
        if (this.configurations.custom) {
            if (this.icons) {
                this.icons = this.configurations.custom.concat(this.icons);
            } else {
                this.icons = this.configurations.custom;
            }
        }

        if (this.icons) {
            this.icons.sort((a, b) => a.name.localeCompare(b.name));
        }
    }

    initItemsObjects() {

        let icon;
        this.items = [];
        if (this.itemsValues.length) {
            this.itemsValues.forEach(element => {
                
                if (element instanceof Object) {
                    icon = {
                        icon: element.icon instanceof Object ? element.icon[this.lang.locale] : element.icon,
                        name: element.name instanceof Object ? element.name[this.lang.locale] : element.name,
                        text: element.text instanceof Object ? element.text[this.lang.locale] : element.text,
                    };
                } else {
                    icon = {
                        icon: element ,
                        name: this.getNameIconByValue(element),
                        text: ''
                    };
                }
                this.items.push(icon);
            });
        }
    }

    sanitizeItems() {
        let localeValue;
        let iconValue;
        let icon;

        // console.log('this.items', this.items);
        // console.log('this.items', typeof this.items);
        // if (this.items instanceof Object) {
        //     this.items = [this.items];
        // }

        // console.log('entra sanitizeItems', this.items);
        if ( !Array.isArray(this.items)) {
            return [];
        }

        const items = [];
        this.items.forEach(element => {
            if (element[0]) {
                element = element[0];
            }
            if (element instanceof Object) {
                localeValue = element[this.lang.locale] ? element[this.lang.locale] : element;
                
                if (localeValue instanceof Object) {
                    iconValue = localeValue.icon ? localeValue.icon : (localeValue.image ? localeValue.image : '');
                } else {
                    iconValue = localeValue;
                }

                icon = {
                    icon: iconValue,
                    name: (localeValue instanceof Object && localeValue.name) ? localeValue.name : '',
                    text: (localeValue instanceof Object && localeValue.text) ? localeValue.text : ''
                };
                items.push(icon);
            } else {
                items.push(element);
            }
        });

        // console.log('items', items);

        return items;
    }

    initItemsValues() {
        this.items = this.sanitizeItems();

        let icon;

        this.itemsValues = [];
        if (this.items.length) {
            this.items.forEach(element => {
                if (element instanceof Object) {
                    icon = element.icon instanceof Object ? element.icon[this.lang.locale] : element.icon;
                    this.itemsValues.push(icon);
                } else {
                    this.itemsValues.push(element.icon);
                }
            });
        }
    }

    initItems() {
        this.setConfigs();

        let dataTmp;
        if (this.data) {
            dataTmp = this.configurations.multiple ? this.data : [this.data];
        } else {
            dataTmp = [];
        }
        
        if (this.configurations.has_text || this.configurations.has_languages) {
            this.items = dataTmp;
            this.initItemsValues();
        } else {
            this.itemsValues = dataTmp;
            this.initItemsObjects();
        }

        // console.log('this.items', this.items);
        // console.log('this.itemsValues', this.itemsValues);
    }

    getIconValue(iconValue) {
        const icon = this.getDataIconByValue(iconValue);

        if (!icon) {
            return null;
        }

        const iconFinal =  {
            icon: {},
            name: {},
            text: {}
        };

        iconFinal.icon[this.lang.locale] = icon.icon;
        iconFinal.name[this.lang.locale] = icon.name;
        iconFinal.text[this.lang.locale] = (icon.text ? icon.text : icon.name);

        if (this.languagesToTranslate) {
            let valueLang;
            let locale;
            this.languagesToTranslate.forEach(langTrans => {
                locale = langTrans.locale;
                if (icon.languages && icon.languages[locale]) {
                    valueLang = icon.languages[locale];
                    iconFinal.icon[locale] = valueLang.icon ? valueLang.icon : icon.icon;
                    iconFinal.name[locale] = valueLang.name ? valueLang.name : '';
                    iconFinal.text[locale] = valueLang.text ? valueLang.text : (valueLang.name ? valueLang.name : '');
                } else {
                    iconFinal.icon[locale] = icon.icon;
                    iconFinal.name[locale] = icon.name;
                    iconFinal.text[locale] = (icon.text ? icon.text : icon.name);
                }
            });
        }

        // console.log('icon Final', iconFinal);

        return iconFinal;

    }

    getIconValueSimple(iconValue) {
        const icon = this.getDataIconByValue(iconValue);

        if (!icon) {
            return null;
        }

        const iconFinal =  {};

        iconFinal[this.lang.locale] = icon.icon;

        if (this.languagesToTranslate) {
            let valueLang;
            let locale;
            
            this.languagesToTranslate.forEach(langTrans => {
                locale = langTrans.locale;
                if (icon.languages && icon.languages[locale]) {
                    valueLang = icon.languages[locale];
                    iconFinal[locale] = valueLang.icon ? valueLang.icon : icon.icon;
                } else {
                    iconFinal[locale] = icon.icon;
                }
            });
        }

        // console.log('iconFinal', iconFinal);

        return iconFinal;

    }

    getValuesToReturnObject(values) {
        let icon;
        let name;
        let valuesToReturn;
        if (this.configurations.multiple) {
            if (!values.length) {
                return [];
            }

            valuesToReturn = [];
            values.forEach(element => {
                icon = this.getIconValue(element);
                valuesToReturn.push(icon);
            });
        } else {
            valuesToReturn = this.getIconValue(values);
        }

        return valuesToReturn;
    }

    getValuesToReturnSimple(values) {
        let icon;
        let name;
        let valuesToReturn;
        if (this.configurations.multiple) {
            if (!values.length) {
                return [];
            }

            valuesToReturn = [];
            values.forEach(element => {
                icon = this.getIconValueSimple(element);
                valuesToReturn.push(icon);
            });
        } else {
            valuesToReturn = this.getIconValueSimple(values);
        }

        return valuesToReturn;
    }

    setValuesToReturn(values) {
        if (!this.configurations.has_languages) {
            return values;
        }

        let valuesToReturn;
        if (!this.configurations.onlyValue) {
            valuesToReturn = this.getValuesToReturnObject(values);
        } else {
            valuesToReturn = this.getValuesToReturnSimple(values);
        }

        // console.log('valuesToReturn', valuesToReturn);

        return valuesToReturn;

    }

    setValue() {
        this.valid();

        let valuesToReturn;
        let values;
        if (this.configurations.multiple) {
            values = this.configurations.has_text ? this.items : this.itemsValues;
            valuesToReturn = this.setValuesToReturn(values);
        } else {
            values = this.itemsValues ? this.itemsValues[0] : '';

            if (this.configurations.has_languages) {
                valuesToReturn = this.setValuesToReturn(values);
            } else {
                valuesToReturn = values;
            }
        }
        
        // console.log('valuesToReturn', valuesToReturn);

        this.sendItems.emit(valuesToReturn);
        // this.sendItems.emit(values);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataLoaded && this.dataLoaded) {
            this.initItems();
        }

        if (changes.field) {
            this.initItems();
        }

        if (changes.integrate) {
            this.isIntegrate = changes.integrate.currentValue;
        }

        if (changes.hasError) {
            this.valid();
        }
    }

    drop(event: CdkDragDrop<object[]>) {
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
        this.setValue();
    }

    getDataIconByValue(value) {
        if ((value instanceof Object)) {
            value = value[this.lang.locale];
        }

        const module = this.icons.filter(x => x.icon === value)[0];
        return module ? module : null;
    }

    getNameIconByValue(value) {
        
        if ((value instanceof Object)) {
            value = value[this.lang.locale];
        }

        const module = this.icons.filter(x => x.icon === value)[0];

        return module ? module.name : null;
    }
    removeIcon(ev, index) {
        this.items.splice(index, 1);
        this.itemsValues.splice(index, 1);
        this.setValue();
    }

    addIcon(ev) {
        this.newIcon.error = !this.newIcon.icon;

        if (this.newIcon.error) {
            return;
        }

        const locale = this.i18nService.getCurrentLanguage().locale;
        const icon = {
            icon: this.newIcon.icon,
            name: this.getNameIconByValue(this.newIcon.icon),
            text: this.textLanguage ? this.localeUtils.getDataLocaleToForm('text', null, this.newIcon.text, locale) : this.newIcon.text
        };


        this.items.push(icon);
        this.itemsValues.push(this.newIcon.icon);
        this.resetIcon();
        this.setValue();
    }
    resetIcon() {
        this.newIcon.icon = '';
        this.newIcon.error = false;
        this.newIcon.text = '';
    }

    valid() {        
        let values;

        let validIcons = true;
        this.errorIcons = null;

        if (this.field && this.field.required) {

            if (this.configurations.multiple) {
                values = this.configurations.has_text ? this.items : this.itemsValues;
            } else {
                values = this.itemsValues;
            }
        
            if (!values.length) {
                validIcons = false;
                this.errorIcons = 'length';
            }

            if (this.configurations) {
                if (this.configurations.min && values.length < this.configurations.min) {
                    validIcons = false;
                    this.errorIcons = 'min';
                }
            }
        }

        return validIcons;
    }

    canAddIcon() {
        return this.configurations.max ? (this.configurations.max === -1 || this.items.length < this.configurations.max) : true;
    }

}
