import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { BlogPost } from '../models/blog-post';

import { PagesService } from './pages.service';
import { CustomRequest } from '../helpers/custom-request';
import { BlogSecondCategoriesService } from './blog-second-categories.service';
import { AppConfigService } from './app-config.service';


@Injectable({ providedIn: 'root' })
export class BlogSecondService {
    constructor(
        private categoriesService: BlogSecondCategoriesService,
        private pagesService: PagesService,
        private request: CustomRequest,
        private appConfig: AppConfigService
    ) { }

    getFilters() {
        return this.request.get(`${environment.apiUrl}/blog-category`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/blog`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/blog/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/blog/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/blog/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/blog/` + id);
    }

    setContent(data: any) {
        return this.request.post(`${environment.apiUrl}/blog/` + data._id + `/content/`, data.content);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/blog/` + id + `/enabled/` + status, null);
    }

    transformDataFromApi(dataPost: any) {
        const generalConfig = this.appConfig.webConfig.blogData;

        const post = new BlogPost();

        post.sites = dataPost.sites;
        post._id = dataPost._id;
        post.alt = dataPost.alt;

        post.category = dataPost.category ? this.categoriesService.transformDataFromApi(dataPost.category) : null;
        post.categories = dataPost.categories ? this.categoriesService.transformCategoriesFromApi(dataPost.categories) : null;
        
        post.content = dataPost.content;
        post.enabled = dataPost.enabled;
        post.highlighted = dataPost.highlighted;
        post.image = dataPost.image;
        post.legend = dataPost.legend;

        post.page = dataPost.page ? this.pagesService.transformDataFromApi(dataPost.page) : null;

        post.title = dataPost.title;
        post.preview = dataPost.preview;

        post.published_at = dataPost.published_at;
        post.created_at = dataPost.created_at;
        post.updated_at = dataPost.updated_at;
        // post.published_at = dataPost.published_at.slice(0, -1);
        // post.created_at = dataPost.created_at.slice(0, -1);
        // post.updated_at = dataPost.updated_at.slice(0, -1);
        post.tags = dataPost.tags;

        if (generalConfig.controlLangs) {
            post.languages = dataPost.languages ? dataPost.languages : [];
        }

        return post;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}