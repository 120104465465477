import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as obituariesActions from './obituaries.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { ObituariesService } from '../../shared/services/obituaries.service';
import { DO_GET_INFO_FILTERS, DO_GET_INFO_OBITUARIES } from './obituaries.actions';





@Injectable()
export class ObituariesEffects {

    constructor(
        private obituariesService: ObituariesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoObituaries$: Observable<Action> = this.actions$.pipe(
        ofType(obituariesActions.DO_GET_INFO_OBITUARIES),
        switchMap((action: obituariesActions.DoGetInfoObituaries) => {
            return this.obituariesService.get(action).pipe(
                map( response => {
                    response.list = this.obituariesService.transformListFromApi(response.list);
                    return new obituariesActions.DoGetInfoObituariesOK(response);
                }),
                catchError(error => of(new obituariesActions.DoGetInfoObituariesKO(error)))
            );
        }));

    // @Effect()
    // DoGetInfoObituariesFilters$: Observable<Action> = this.actions$.pipe(
    //     ofType(obituariesActions.DO_GET_INFO_FILTERS),
    //     switchMap((action: obituariesActions.DoGetInfoObituariesFilters) => {
    //         return this.obituariesService.getFilters().pipe(
    //             map( response => new obituariesActions.DoGetInfoObituariesFiltersOK(response)),
    //             catchError(error => of(new obituariesActions.DoGetInfoObituariesFiltersKO(error)))
    //         );
    //     }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(obituariesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: obituariesActions.DoSetSelectedFilters) => {
            return this.obituariesService.get(action).pipe(
                map( response => new obituariesActions.DoGetInfoObituariesOK(response)),
                catchError(error => of(new obituariesActions.DoGetInfoObituariesKO(error)))
            );
        }));
}
