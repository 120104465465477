import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { PromotionPost } from '../models/promotion';

// import { BlogCategoriesService } from './blog-categories.service';
import { PagesService } from './pages.service';
import { CustomRequest } from '../helpers/custom-request';


@Injectable({ providedIn: 'root' })
export class PromotionsService {
    constructor(
        // private categoriesService: BlogCategoriesService,
        private pagesService: PagesService,
        private request: CustomRequest
    ) { }

    // NO HAY CATEGORIAS
    getFilters() {
        // console.log("PROMO CATEGORIES");
        // return this.request.get(`${environment.apiUrl}/promotion-category`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/promotion`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/promotion/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/promotion/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/promotion/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/promotion/` + id);
    }

    setContent(data: any) {
        return this.request.post(`${environment.apiUrl}/promotion/` + data._id + `/content/`, data.content);
    }

    order(data) {
        return this.request.post(`${environment.apiUrl}/promotion/order`, data);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/promotion/` + id + `/enabled/` + status, null);
    }

    transformCtas(ctas) {
        if (!ctas) {
            return [];
        }

        if (ctas instanceof Array) {
            return ctas;
        }

        if (ctas instanceof Object) {

            const ctasTmp = [];
            for (let prop in ctas) {
                ctasTmp.push(ctas[prop]);
            }

            return ctasTmp;
        }

        return [];

    }

    transformDataFromApi(data: any) {
        const promotion = new PromotionPost();

        promotion._id = data._id;
        promotion.title = data.title;
        promotion.antetitle = data.antetitle;
        promotion.subtitle = data.subtitle;
        promotion.image = data.image;
        promotion.icon = data.icon;
        promotion.ctas = this.transformCtas(data.ctas);
        promotion.page = data.page ? this.pagesService.transformDataFromApi(data.page) : null;

        promotion.sites = data.sites;

        promotion.enabled = data.enabled;

        // promotion.content = data.content;

        promotion.created_at = data.created_at;
        promotion.created_at = data.created_at.slice(0, -1);
        promotion.updated_at = data.updated_at;
        promotion.updated_at = data.updated_at.slice(0, -1);
        promotion.published_at = data.published_at;
        if(data.published_at) {
            promotion.published_at = data.published_at.slice(0, -1);
        }


        return promotion;
    }
    
    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });
        

        return data;

    }
}