import { Action } from '@ngrx/store';

// GALLERIES
export const DO_GET_INFO_GALLERIES_CATEGORIES = '[Galleries Categories] Get Info Categories';
export class DoGetInfoGalleriesCategories implements Action {
    type = DO_GET_INFO_GALLERIES_CATEGORIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_GALLERIES_CATEGORIES_OK = '[Galleries Categories] Get Info Categories OK';
export class DoGetInfoGalleriesCategoriesOK implements Action {
    type = DO_GET_INFO_GALLERIES_CATEGORIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_GALLERIES_CATEGORIES_KO = '[Galleries Categories] Get Info Categories KO';
export class DoGetInfoGalleriesCategoriesKO implements Action {
    type = DO_GET_INFO_GALLERIES_CATEGORIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Galleries Categories] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoGalleriesCategories | DoGetInfoGalleriesCategoriesOK | DoGetInfoGalleriesCategoriesKO 
    | DoSetSelectedFilters
    ;
