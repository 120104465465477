import { Action } from '@ngrx/store';

// DOCUMENTS
export const DO_GET_INFO_DOCUMENTS_CATEGORIES = '[Documents Categories] Get Info Categories';
export class DoGetInfoDocumentsCategories implements Action {
    type = DO_GET_INFO_DOCUMENTS_CATEGORIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_DOCUMENTS_CATEGORIES_OK = '[Documents Categories] Get Info Categories OK';
export class DoGetInfoDocumentsCategoriesOK implements Action {
    type = DO_GET_INFO_DOCUMENTS_CATEGORIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_DOCUMENTS_CATEGORIES_KO = '[Documents Categories] Get Info Categories KO';
export class DoGetInfoDocumentsCategoriesKO implements Action {
    type = DO_GET_INFO_DOCUMENTS_CATEGORIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Documents Categories] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoDocumentsCategories | DoGetInfoDocumentsCategoriesOK | DoGetInfoDocumentsCategoriesKO 
    | DoSetSelectedFilters
    ;
