export class GalleryCategory {
    _id: string;
    code: string;
    name: any;
    enabled: boolean;
    
    sites: Array<string>;

    // created_at: string;
    // updated_at: string;
    // posts: Array<any>;
}