import { Component, Input, EventEmitter, Output, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '../../services/routes.service';

import * as LayoutActions from '@app/store/layout/layout.actions';
import * as AsideActions from '@app/store/aside/aside.actions';
import * as ModulesActions from '@app/store/modules/modules.actions';
import { first } from 'rxjs/operators';
import * as _ from 'lodash';

import { Store } from '@ngrx/store';
import { AppState } from '../../../store/index';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { I18n } from '../../models/i18n';
import { SitesService } from '../../services/sites.service';

import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '@app/shared/components/dialog/dialog-confirm/dialog-confirm.component';
import { DialogErrorComponent } from '@app/shared/components/dialog/dialog-error/dialog-error.component';


import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { I18nService } from '../../services/18n.service';
import { Module } from '../../models/module';
import { ModuleType } from '../../models/module-type';
import { Pagination } from '../../models/pagination';
import { UtilsService } from '../../services/utils.service';
import { ModulesService } from '../../services/modules.service';
import { TextI18nPipe } from '../../pipes/text-i18n.pipe';
import { UrlHelper } from '../../helpers/url-helper';

declare var noScroll: any;

@Component({
    selector: 'app-modules-content',
    templateUrl: './modules-content.component.html',
    providers: [TextI18nPipe, UrlHelper]
})
export class ModulesContentComponent implements OnDestroy, OnChanges, OnInit {

    @Input() breadcrumb: any;
    @Input() typeSection: string;

    items: Array<Module>;
    filtersStatus: Array<any>;
    filtersTypes: Array<ModuleType>;

    paginatorPage$: Observable<number>;
    currentFilters$: Observable<any>;

    // paginator$: Observable<Pagination>;
    paginator: Pagination;
    currentPage = 1;
    totalPages = 1;

    query = '';

    currentFilters: {
        type: string;
        textSearch: string;
        status: number
    };


    fieldsToOrder: Array<any>;
    currentOrderList: any = 'name';
    currentOrderTypeList: any = 'desc';

    loading = true;
    init = false;

    queryParams = null;

    filterActive: boolean;

    filtersToList: Array<any>;

    currentLanguage: I18n;

    changeFilters = false;

    currentSite = null;

    confirmActive = false;
    isChangingStatus: boolean;
    currentIdxModule: number;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private router: Router,
        public routesService: RoutesService,
        private sitesService: SitesService,
        private i18nService: I18nService,
        private utilsService: UtilsService,
        public dialog: MatDialog,
        private itemsService: ModulesService,
        private textI18n: TextI18nPipe,
        private urlHelper: UrlHelper
    ) {

    }

    ngOnInit() {
        this.initSuscriptions();

        this.initItems();
        this.initDataToFilter();
        this.initFieldsToOrder();
        this.initRoute();

        this.initSelectedFilters();

        this.initReloadChangeLanguage();

        const payload = { typeSection: this.typeSection};
        this.store.dispatch(
            new ModulesActions.DoGetInfoModulesFilters(payload)
        );

    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.typeSection) {
        }
    }

    initReloadChangeLanguage() {
        this.suscriptionsToManage$.push(this.store.select(state => state.layout.reload).subscribe(res => {
            if (res) {
                this.changeFilters = true;
                this.doRefresh();
                this.store.dispatch(new LayoutActions.SetReload(false));
            }
        }));
    }


    initSuscriptions() {
        this.i18nService.currentLanguage$.subscribe(
            i18n => {
                this.currentLanguage = i18n;
            }
        );

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.result).subscribe(res => {
            if (res && res.result) {
                this.saveModules(res);
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.index).subscribe(res => {
            if (!this.confirmActive) {
                this.currentIdxModule = res;
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.edit).subscribe(res => {
            if (res && !this.isChangingStatus) {
                this.editModule();
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.active).subscribe(res => {
            if (res) {
                this.changeStatusModule();
            }
        }));
    }

    initItems() {
        this.suscriptionsToManage$.push(this.store.select(state => state.modules.loading).subscribe(res => {
            this.loading = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.modules.items).subscribe(res => {
            this.items = res;
            this.init = true;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.modules.paginator).subscribe(res => {
            this.paginator = res;
        }));
    }

    initFieldsToOrder() {
        this.fieldsToOrder = [
            { name: 'ID', value: '_id' },
            { name: 'Nombre', value: 'name' }
        ];
    }

    initDataToFilter() {
        this.suscriptionsToManage$.push(combineLatest(
            this.store.select(state => state.modules.filtersTypes),
            this.store.select(state => state.modules.filtersStatus)
        ).subscribe(
            ([
                filtersTypes,
                filtersStatus
            ]) => {
                this.filtersTypes = filtersTypes;
                this.filtersStatus = filtersStatus;
            }
        ));
    }

    initRoute() {
        this.suscriptionsToManage$.push(this.store.select(state => state.router).subscribe(res => {
            // console.log('Blog LISTADO router observe changing', res);
            if (res.state.url.search(/glosario/g) >= 0) {
                if (this.query !== res.state.url) {
                    this.query = res.state.url;

                    // this.queryParams = res.state.root.queryParams;
                    this.queryParams = res.state.queryParams;

                    this.updateFiltersByParams();
                }
            }
        }));
    }

    initSelectedFilters() {
        this.initFilters();
        // console.log('haciendo refresh TRAS FILTRS');
        this.doRefresh();
    }

    initFilters() {
        this.filterActive = false;
        this.currentFilters = {
            type: '',
            textSearch: '',
            status: -1
        };

        this.updateFiltersByParams();
    }

    onChangePagination(ev) {
        this.paginator.currentPage = Number(ev.page);

        this.sendParams();
    }

    sendParams() {
        this.urlHelper.sendParams('modules', this.currentFilters, this.paginator, this.currentOrderList, this.currentOrderTypeList);
        this.doRefresh();
        this.updateFiltersToList();
        this.changeFilters = false;
    }

    updateFiltersByParams() {
        // console.log('update filters params', this.queryParams);
        if (this.queryParams && this.queryParams.page) {
            const queryFilters = _.cloneDeep(this.queryParams);

            if (queryFilters.page && Number(queryFilters.page) > 0) {
                this.paginator.currentPage = Number(queryFilters.page);
            }
            if (queryFilters.limit && Number(queryFilters.limit) > 0) {
                this.paginator.perPage = Number(queryFilters.limit);
            }

            if (queryFilters.search && queryFilters.search !== '') {
                this.currentFilters.textSearch = queryFilters.search;
                this.filterActive = true;
            }
            if (queryFilters.type && queryFilters.type !== '') {
                this.currentFilters.type = queryFilters.type;
                this.filterActive = true;
            }
            if (queryFilters.enabled && Number(queryFilters.enabled) > -1) {
                this.currentFilters.status = Number(queryFilters.enabled);
                this.filterActive = true;
            }
        } else {
            this.paginator.currentPage = 1;
        }
    }

    goTopScroll() {
        this.utilsService.goTopScroll('modules-scroll');
    }

    doRefresh() {
        this.goTopScroll();

        this.init = false;

        this.paginator.perPage = this.typeSection === 'search' ? 15 : 100;

        this.store.dispatch(
            /*
            * Esta seria para el caso de que hubiera filtros que hubiera que pedir a la api.
            * En caso contrario, se pide directamente la peticion de informacion
            */
            // new ModulesActions.DoSetSelectedFilters({
            //     filters: this.currentFilters,
            //     paginator: this.paginator,
            //     orderBy: this.currentOrderList,
            //     orderByType: this.currentOrderTypeList
            // })
            new ModulesActions.DoGetInfoModules({
                filters: this.currentFilters,
                paginator: this.paginator,
                orderBy: this.currentOrderList,
                orderByType: this.currentOrderTypeList
            })

        );
    }

    clickSearch(ev) {
        ev.preventDefault();

        this.filterActive = true;
        this.doSearch('search');
        this.router.navigate([this.routesService.getRoute('modules')], {});
    }

    doSearch(key = null) {
        if (this.isFiltered()) {
            this.filterActive = true;
            this.paginator.currentPage = 1;
            this.sendParams();
        }
    }

    changeOrder(key = null) {
        this.filterActive = true;
        this.paginator.currentPage = 1;
        this.sendParams();
    }

    isFiltered() {
        return this.changeFilters;
    }

    setChangeFilters() {
        this.changeFilters = true;
    }

    doReset(key = null) {
        this.filterActive = false;

        this.paginator.currentPage = 1;
        this.currentFilters = {
            type: '',
            textSearch: '',
            status: -1
        };
        this.sendParams();
    }

    getOrdersBy() {
        // this.store.dispatch(new ModulesActions.DoGetOrdersBy({}));
    }

    clickDetail(idPost) {
        this.router.navigate([this.routesService.getRoute('modulesDetail', idPost)]);
    }
    clickEdit(idPost) {
        this.router.navigate([this.routesService.getRoute('modulesEdit', idPost)]);
    }

    findItemById(id) {
        return this.items.filter(x => x._id === id)[0];
    }

    findIdxItemById(id) {
        return this.items.filter(x => x._id === id)[0];
    }

    clickDelete(ev, idItem) {
        ev.preventDefault();

        const item = this.findItemById(idItem);
        if (!item) {
            this.showError('No existe elemento con ese id');
        }

        const dialogRef = this.dialog.open(DialogConfirmComponent, {
            // width: '300px',
            panelClass: 'modal-custom',
            data: { name: this.textI18n.transform(item.name), id: idItem }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === idItem) {
                this.deletePost(idItem);
            }
        });
    }

    deletePost(idItem) {
        this.itemsService.delete(idItem).pipe(
            first()).subscribe(data => {
                this.doRefresh();
            },
                error => {
                    // console.log('ERROR PETICION', error);
                    // return this.router.navigate([this.routesService.getRoute('modules')], {});
                    this.showError(error);
                }
            );
    }

    clickChangeStatus(ev, idItem) {
        ev.preventDefault();

        const item = this.findItemById(idItem);
        if (!item) {
            this.showError('No existe elemento con ese id');
        }

        this.itemsService.changeStatus(idItem, (item.enabled ? 0 : 1)).pipe(
            first()).subscribe(data => {
                this.doRefresh();
            },
                error => {
                    // console.log('ERROR PETICION', error);
                    // return this.router.navigate([this.routesService.getRoute('modules')], {});
                    this.showError(error);
                }
            );
    }


    showError(error) {
        const dialogRef = this.dialog.open(DialogErrorComponent, {
            // width: '300px',
            panelClass: 'modal-custom',
            data: { error }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.router.navigate([this.routesService.getRoute('modules')], {});
        });
    }

    findTypeById(id: string) {
        return this.filtersTypes.filter(x => x._id === id)[0];
    }

    updateFiltersToList() {
        const filters = [];

        if (this.currentFilters.textSearch) {
            filters.push(this.currentFilters.textSearch);
        }

        if (this.currentFilters.type) {
            const filterType = this.findTypeById(this.currentFilters.type);
            if (filterType) {
                filters.push(filterType.name);
            }
        }

        if (this.currentFilters.status && this.currentFilters.status > -1) {
            if (this.currentFilters.status > 0) {
                filters.push('Activos');
            } else {
                filters.push('Inactivos');
            }
        }

        this.filtersToList = filters;

    }

    getListToUpdate(items) {
        const list = [];

        let index = 1;
        let element;
        Object.keys(items).forEach((key) => {
            element = items[key];
            list.push({
                _id: element._id,
                order: index
            });
            index++;
        });

        return list;

    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);

        const groups = this.getListToUpdate(this.items);

        this.itemsService.order(groups).pipe(
            first()).subscribe(data => {
                // this.refreshPage();
            },
                error => {
                    this.showError(error);
                }
            );
    }

    changeStatusModule() {
        const module = this.items[this.currentIdxModule];

        if (!module) {
            this.showError('No existe elemento con ese id');
        }

        this.itemsService.changeStatus(module._id, (module.enabled ? 0 : 1)).pipe(
            first()).subscribe(data => {
                this.items[this.currentIdxModule].enabled = data.enabled;
                this.store.dispatch(new AsideActions.EndToActive());

                this.isChangingStatus = false;
            },
                error => {
                    console.log('ERROR PETICION', error);
                    // return this.router.navigate([this.routesService.getRoute('pages')], {});
                    this.showError(error);
                    this.isChangingStatus = false;
                }
            );
    }

    editModule() {
        const index = this.currentIdxModule;
        const module = this.items[index];

        noScroll.on();

        const data = {
            modules: null,
            action: 'edit',
            index,
            _id: null,
            box: null
        };

        const mode = module.type.view;
        const payload = {
            mode,
            data: module,
            section: 'modules',
            box: null,
            title: module.type.name,
            idType: module.type._id,
            type: module.type
        };
        this.store.dispatch(new AsideActions.SetInfo(payload));
        this.store.dispatch(new LayoutActions.OpenAsideSecondary());
    }

    saveModules(res) {
        if (res.module) {
            this.doSave(res.module);
        } else {
            // POR AHORA NO SE CREAN DESDE AQUI
            // this.createModule(res.module);
        }
    }

    doSave(moduleToSave) {
        moduleToSave.type = moduleToSave.type._id;
        this.itemsService.update(moduleToSave).pipe(
            first()).subscribe(data => {
                this.items[this.currentIdxModule] = data;

                this.store.dispatch(new LayoutActions.CloseAsideSecondary());
                this.store.dispatch(new AsideActions.ResetInfo());
                this.confirmActive = false;
            },
                error => {
                    this.store.dispatch(new LayoutActions.CloseAsideSecondary());
                    this.showError(error);
                    this.store.dispatch(new AsideActions.ResetInfo());
                    this.confirmActive = false;
                }
            );
    }
}
