import { DocumentCategory } from './document-category';
import { Page } from './page';

export class DocumentPost {
    _id: string;
    enabled: boolean;
    title: any;
    category: DocumentCategory;
    file: any;
    file_name: any;

    order: number;

    published_at: string;

    sites: Array<string>;

    // created_at: string;
    // updated_at: string;
    
}