import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { Admin } from '../models/admin';
import { CustomRequest } from '../helpers/custom-request';

@Injectable({ providedIn: 'root' })
export class AdminsService {
    constructor(
        private request: CustomRequest
    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/user`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/user/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/user/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/user/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/user/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/user/` + id + `/enabled/` + status, null);
    }

    transformCategoriesFromApi(data: any) {
        const admins = [];

        data.forEach(element => {
            admins.push(this.transformDataFromApi(element));
        });

        return admins;
    }

    transformDataFromApi(dataAdmin: any) {
        const admin = new Admin();

        admin._id = dataAdmin._id;
        admin.name = dataAdmin.name;
        admin.enabled = dataAdmin.enabled;
        admin.email = dataAdmin.email;
        admin.password = dataAdmin.password;

        return admin;
    }
}