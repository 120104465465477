import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as publicationsActions from './publications.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { PublicationsService } from '../../shared/services/publications.service';
import { DO_GET_INFO_FILTERS, DO_GET_INFO_PUBLICATIONS } from './publications.actions';





@Injectable()
export class PublicationsEffects {

    constructor(
        private publicationsService: PublicationsService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoPublications$: Observable<Action> = this.actions$.pipe(
        ofType(publicationsActions.DO_GET_INFO_PUBLICATIONS),
        switchMap((action: publicationsActions.DoGetInfoPublications) => {

            return this.publicationsService.get(action).pipe(
                map( response => {
                    response.list = this.publicationsService.transformListFromApi(response.list);
                    return new publicationsActions.DoGetInfoPublicationsOK(response);
                }),
                catchError(error => of(new publicationsActions.DoGetInfoPublicationsKO(error)))
            );
        }));

    @Effect()
    // DoGetInfoPublicationsFilters$: Observable<Action> = this.actions$.pipe(
    //     ofType(publicationsActions.DO_GET_INFO_FILTERS),
    //     switchMap((action: publicationsActions.DoGetInfoPublicationsFilters) => {
    //         return this.publicationsService.getFilters().pipe(
    //             map( response => new publicationsActions.DoGetInfoPublicationsFiltersOK(response)),
    //             catchError(error => of(new publicationsActions.DoGetInfoPublicationsFiltersKO(error)))
    //         );
    //     }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(publicationsActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: publicationsActions.DoSetSelectedFilters) => {
            return this.publicationsService.get(action).pipe(
                map( response => new publicationsActions.DoGetInfoPublicationsOK(response)),
                catchError(error => of(new publicationsActions.DoGetInfoPublicationsKO(error)))
            );
        }));
}
