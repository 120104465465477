import { Component, Input, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '../../services/routes.service';
import { SitesService } from '../../services/sites.service';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-sites',
    templateUrl: './sites.component.html',
})
export class SitesComponent implements OnInit, OnDestroy, OnChanges {

    @Input() itemSites: any;
    @Input() mode: string;

    hasSites: boolean;
    sites: Array<any>;

    currentSite = null;
    currentSiteId = null;

    itemSitesData = [];

    loadSites = false;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private router: Router,
        public routesService: RoutesService,
        private sitesService: SitesService
    ) {

    }

    ngOnInit() {
        this.initSuscriptions();
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    initSuscriptions() {
        this.suscriptionsToManage$.push(this.sitesService.sitesData$.subscribe(
            sites => {
                // console.log('sites init...', sites);
                this.sites = sites;
                this.hasSites = this.sites.length > 1;
                this.loadSites = true;


                if (this.mode) {
                    this.setItemSites();
                }
            }
        ));

        this.suscriptionsToManage$.push(this.sitesService.currentSite$.subscribe(
            site => {
                this.currentSite = site;
                this.currentSiteId = site._id;
            }
        ));
    }

    findIdxItemById(id) {
        return this.sites.filter(x => x._id === id)[0];
    }

    setSite() {
        const site = this.findIdxItemById(this.currentSiteId);
        this.sitesService.setCurrentSite(site);
    }

    getItemById(id: string) {
        const site = this.sites.filter(x => x._id === id)[0];
        return site;
    }

    setItemSites() {
        const sites = [];

        let site;
        if(this.itemSites){
            this.itemSites.forEach(siteItem => {
                const siteId = siteItem instanceof Object ? siteItem._id : siteItem;

                site = this.getItemById(siteId);
                if (site) {
                    sites.push(site);
                }
            });
        }

        this.itemSitesData = sites;
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log('entra por aqui');
        if (changes.itemSites && this.loadSites) {
            this.setItemSites();
        }
    }

}
