import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { User } from '@app/shared/models';
import { Credentials, CredentialsHelper } from '../models/credentials';

import { map } from 'rxjs/operators';

// Utils
import { getAuthString, Base64, cifrarXor } from '../lib/helpers/utils';
import { Md5 } from 'ts-md5/dist/md5';

import { Store } from '@ngrx/store';
import { AppState } from '../../store/index';
import { AuthState } from '../../store/auth/auth.state';
import * as loggedActions from '../../store/auth/auth.actions';
import { RouterStateSnapshot } from '@angular/router';
import { SitesService } from './sites.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    // private currentUserSubject: BehaviorSubject<User>;
    // public currentUser: Observable<User>;

    initialized: boolean;

    loggedUser$: Observable<AuthState>;
    authdata: string;
    authdataCredentials: string;
    logged: boolean;

    credentials: any = {
        email: 'resetPass',
        password: 'rPeass'
    };

    constructor(
        private http: HttpClient,
        private store: Store<AppState>,
        private sitesService: SitesService,
    ) {
        this.initialized = false;

        this.logged = false;

        this.loggedUser$ = this.store.select(state => state.auth);
        this.loggedUser$.subscribe(res => {
            if (res.authData) {
                this.authdata = res.authData;
            } else {
                this.authdata = null;
            }
        });
    }

    public get authData(): string {
        return this.authdata;
    }

    public get authDataCredentials(): string {
        return this.authdataCredentials;
    }

    public isLogged(): boolean {
        if (!this.initialized) {
            this.initAutoLogin();
        }

        const authData = this.getAuthData();
        // console.log('token isLogged', token);
        return authData ? true : false;
    }

    public initAutoLogin(): void
    {
        let authData = sessionStorage.getItem('authData');
        if (authData && authData.length < 5) {
            authData = '';
        }

        if (!(authData) || !this.sitesService.hasSiteAndLanguage()) {
            this.store.dispatch(
                new loggedActions.InitApp()
            );
        } else {
            this.store.dispatch(
                new loggedActions.AutoLogin(authData)
            );
        }

        this.initialized = true;
    }

    autoLogin() {
        return this.http.get<User>(`${environment.apiUrl}/auth`);
    }

    login(credentials: Credentials) {
        this.authdataCredentials = window.btoa(credentials.user + ':' + credentials.password);
        return this.http.get<any>(`${environment.apiUrl}/auth`);

    }

    logout() {
        // remove user from local storage to log user out
        // sessionStorage.removeItem('currentUser');
        // this.currentUserSubject.next(null);
    }

    getAuthData(): string {
        return sessionStorage.getItem('authData');
    }

    deleteAuthData(): void {
        sessionStorage.removeItem('authData');
    }

    saveAuthData(): void {
        sessionStorage.setItem('authData', this.authDataCredentials);
    }

    saveAuthDataLocalStorage(): void {
        localStorage.setItem('authData', this.authData);
    }

    /**
     * Save token in sessionStorage
     *
     * @param string authToken
     * @param string userMd5
     *
     * @memberOf SoledadAuthenticationService
     */
    // saveToken(authToken: string, user: any): void {
    //     sessionStorage.setItem('authToken', authToken);
    //     sessionStorage.setItem('user', JSON.stringify(user));
    // }

    // saveUser(user: any) {
    //     // console.log('user to save', user);
    //     sessionStorage.setItem('user', JSON.stringify(user));
    // }

    /**
     * Save token in localStorage
     *
     * @param string authToken
     *
     * @memberOf SoledadAuthenticationService
     */
    saveTokenLocalStorage(authToken: string): void {
        localStorage.setItem('authToken', authToken);
    }

    saveAccessDataLocalStorage(credential: Credentials): void {
        // console.log('saveAccessDataLocalStorage', credential);

        const cred: any = {
            login: credential.user,
            password: credential.password
        };

        const encode: string = Base64.encode(
            cifrarXor(JSON.stringify(cred), this.credentials.publicKey)
        );
        localStorage.setItem('credentials', encode);
    }

    /**
     * @param  {string} encryptedCredentials encryptedCredentials The encrypted credentials
     * @return {Credentials} Credentials decrypted
     */
    decryptCredentials(encryptedCredentials: string): Credentials {
        const aux = cifrarXor(
            Base64.decode(encryptedCredentials),
            this.credentials.publicKey
        );
        return CredentialsHelper.newFromJSON(aux);
    }
}