import { Action } from '@ngrx/store';

// CATALOG
export const DO_GET_INFO_CATALOG = '[Catalog catalog] Get Info Catalog';
export class DoGetInfoCatalog implements Action {
    type = DO_GET_INFO_CATALOG;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_CATALOG_OK = '[Catalog catalog] Get Info Catalog OK';
export class DoGetInfoCatalogOK implements Action {
    type = DO_GET_INFO_CATALOG_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_CATALOG_KO = '[Catalog catalog] Get Info Catalog KO';
export class DoGetInfoCatalogKO implements Action {
    type = DO_GET_INFO_CATALOG_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Catalog catalog] Get Info Filters';
export class DoGetInfoCatalogFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Catalog catalog] Get Info Filters OK';
export class DoGetInfoCatalogFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Catalog catalog] Get Info Filters KO';
export class DoGetInfoCatalogFiltersKO implements Action {
    type = DO_GET_INFO_CATALOG_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Catalog catalog] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoCatalog | DoGetInfoCatalogOK | DoGetInfoCatalogKO |
    DoGetInfoCatalogFilters | DoGetInfoCatalogFiltersOK | DoGetInfoCatalogFiltersKO 
    | DoSetSelectedFilters
    ;
