import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { EventPost } from '../models/event-post';

import { EventsCategoriesService } from './events-categories.service';
import { PagesService } from './pages.service';
import { CustomRequest } from '../helpers/custom-request';


@Injectable({ providedIn: 'root' })
export class EventsPostService {
    constructor(
        private categoriesService: EventsCategoriesService,
        private pagesService: PagesService,
        private request: CustomRequest
    ) { }

    getFilters() {
        return this.request.get(`${environment.apiUrl}/event-category`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/event`, data.payload);
    }
    
    detail(id) {
        return this.request.get(`${environment.apiUrl}/event/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/event/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/event/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/event/` + id);
    }

    setContent(data: any) {
        return this.request.post(`${environment.apiUrl}/event/` + data._id + `/content/`, data.content);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/event/` + id + `/enabled/` + status, null);
    }

    transformDataFromApi(dataPost: any) {
        const post = new EventPost();

        /**
        address: object
        category: Tipo event_category
        city: Tipo City
        content: object
        enabled: boolean
        title: object
        image: string
        page: Tipo pagina
        phone: string
        preview: object
        schedule: object
        sites

        start_at datetime
        end_at: datetime
        published_at: datetime
        created_at
        update_at
        */

        post._id = dataPost._id;
        post.address = dataPost.address;
        post.category = dataPost.category ? this.categoriesService.transformDataFromApi(dataPost.category) : null;
        post.city = dataPost.city;
        post.content = dataPost.content;
        post.enabled = dataPost.enabled;
        post.title = dataPost.title;
        post.image = dataPost.image;
        post.page = dataPost.page ? this.pagesService.transformDataFromApi(dataPost.page) : null;
        post.phone = dataPost.phone ? dataPost.phone : "";
        post.preview = dataPost.preview;
        post.schedule = dataPost.schedule;
        post.sites = dataPost.sites;

        post.start_at = dataPost.start_at;
        post.end_at = dataPost.end_at;
        post.published_at = dataPost.published_at;
        post.created_at = dataPost.created_at;
        post.updated_at = dataPost.updated_at;

        // post.published_at = dataPost.published_at.slice(0, -1);
        // post.created_at = dataPost.created_at.slice(0, -1);
        // post.updated_at = dataPost.updated_at.slice(0, -1);
        
                
        return post;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}