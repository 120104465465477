import { Component, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as AsideItemActions from '@app/store/aside-item/aside-item.actions';
// import * as AsideActions from '@app/store/aside/aside.actions';
import { ConfigurationsModule } from '@app/shared/models/configurationsModule';
import { LocaleUtils } from '@app/shared/helpers/locale-utils';
import { SitesService } from '@app/shared/services/sites.service';
import { I18n } from '@app/shared/models/i18n';
import { TextI18nPipe } from '@app/shared/pipes/text-i18n.pipe';
import { FilesManager } from '@app/shared/helpers/files-manager';

import { ItemsModule } from '@app/shared/models/itemsModule';
import { ModulesUtils } from '@app/shared/helpers/modules-utils';
import { environment } from '../../../../../environments/environment';
import { I18nService } from '@app/shared/services/18n.service';
import { Site } from '@app/shared/models/site';
import { AppConfigService } from '../../../services/app-config.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-module-common-aside',
    templateUrl: './common-aside.component.html',
    providers: [TextI18nPipe]
})
export class CommonAsideComponent implements OnChanges, OnDestroy {

    moduleConfig: any;
    moduleConfigParent: any;

    @Input() type: string;
    @Input() itemsView: boolean;

    @Input() customConfig: any;
    @Input() isChild: boolean;
    @Input() customData: any;
    @Input() showForm: boolean;

    @Output() sendItems = new EventEmitter<any>();
    @Output() closeForm = new EventEmitter<any>();


    typeChild = false;

    viewFields: any;

    elementsForm = [];
    loadedForms = false;

    configBasic: any;
    configFull: any;

    environement = environment;

    public filesManager: FilesManager;
    editingItem: boolean;

    data: any;
    dataLoaded = false;


    modules: Array<any>;

    itemForm: FormGroup = null;
    submitted = false;

    currentSite: Site = null;
    editintLanguage: I18n;
    currentLanguage: I18n;
    languagesToTranslate: Array<I18n> = null;
    visibilityLanguagesToTranslate: any;

    ctas: Array<any> = [];
    icons: any;
    items: Array<any> = [];

    itemsError = false;

    errorForm = null;

    tabChange = 0;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        private localeUtils: LocaleUtils,
        private sitesService: SitesService,
        private i18nService: I18nService,
        private textI18n: TextI18nPipe,
        private appConfig: AppConfigService
    ) {
        this.data = null;

        this.initSuscriptions();

        const currentLanguage = this.i18nService.getCurrentLanguage();
        this.configBasic = ModulesUtils.joditBasic(currentLanguage.iso);
        this.configFull = ModulesUtils.joditFull(currentLanguage.iso);
    }

    initConfigModule() {
        if (!this.isChild) {
            this.moduleConfigParent = this.appConfig.webConfig.modulesData[this.type];
            this.moduleConfig = this.moduleConfigParent ? this.moduleConfigParent.items : null;
        } else {
            this.moduleConfigParent = null;
            this.moduleConfig = this.customConfig;
        }

        if (this.moduleConfig) {
            this.moduleConfig.fields.forEach(elements => {
                elements.forEach(element => {
                    this.elementsForm.push(element);
                });
            });
        }
    }

    getEditorMode(config) {
        switch (config) {
            case 'full': return this.configFull;
            default: return this.configBasic;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.type) {
            this.reset();
            this.initConfigModule();
            this.initGlobal();

            this.typeChild = false;
        }

        if (changes.showForm) {
            if (changes.showForm.currentValue) {
                this.reset();
                this.initConfigModule();
                this.initGlobal();

                this.editingItem = false;
                this.data = null;
                this.typeChild = true;
            }
        }

        if (changes.customData && this.isChild) {
            this.data = this.customData;

            this.reset();
            this.initConfigModule();
            this.initGlobal();

            this.typeChild = true;

            if (this.data) {
                // Para corregir un modulo de limbo
                if (this.data.images && (!this.data.items || this.items.length === 0)) {
                    this.items = this.data.images;
                } else {
                    this.items = this.data.items ? this.data.items : [];
                }

                this.fillForm();
                this.editingItem = true;
            }
        }
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }


    initSuscriptions() {
        this.suscriptionsToManage$.push(this.i18nService.currentLanguage$.subscribe(
            i18n => {
                this.currentLanguage = i18n;
            }
        ));

        this.suscriptionsToManage$.push(this.sitesService.currentSite$.subscribe(
            site => {
                if (!this.isChild) {
                    this.currentSite = site;
                    this.initLanguagesToTranslate();
                    this.initGlobal();
                }
            }
        ));

        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.data).subscribe(res => {
            if (!this.isChild) {
                if (res) {
                    this.data = res;
                    this.dataLoaded = true;
                    if (this.data) {
                        // Para corregir un modulo de limbo
                        if (this.data.images && (!this.data.items || this.items.length === 0)) {
                            this.items = this.data.images;
                        } else {
                            this.items = this.data.items ? this.data.items : [];
                        }

                        this.fillForm();
                        this.editingItem = true;
                    }
                } else {
                    this.editingItem = false;
                    this.data = null;
                    this.dataLoaded = true;
                }
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.toSave).subscribe(res => {
            if (res) {
                this.onSubmit();
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.closeForm).subscribe(res => {
            if (res) {
                this.closeForm.emit();
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.saveChild).subscribe(res => {
            if (res) {
                if (this.isChild) {
                    this.onSubmit();
                }
            }
        }));
    }

    setItems(items: any) {
        this.items = items;

        const payload = { error: null };
        this.store.dispatch(new AsideItemActions.SetErrorChild(payload));
    }

    initLanguagesToTranslate() {
        this.visibilityLanguagesToTranslate = {};
        this.languagesToTranslate = [];

        let init = false;

        this.currentSite.i18n.forEach(lang => {
            if (lang.locale !== this.currentLanguage.locale) {
                this.languagesToTranslate.push(lang);
                this.visibilityLanguagesToTranslate[lang.locale] = init ? false : true;

                if (this.visibilityLanguagesToTranslate[lang.locale]) {
                    this.editintLanguage = lang;
                }

                init = true;
            }
        });
    }

    setI18nLocaleTranslate(i18n) {
        this.languagesToTranslate.forEach(lang => {
            this.visibilityLanguagesToTranslate[lang.locale] = i18n._id === lang._id ? true : false;
        });
        this.editintLanguage = i18n;
    }

    get f() { return this.itemForm.controls; }

    getGroupField(groupName, field) {
        const group = this.itemForm.get(groupName);
        const fieldOnGroup = group.get(field);

        return fieldOnGroup;
    }

    calculateClasses(item) {
        return item.cols ? item.cols : null;
    }

    reset() {
        this.submitted = false;
        this.itemForm = null;
        this.elementsForm = [];
        this.ctas = [];
        this.icons = null;
        this.items = [];
    }

    initGlobal() {
        if (!this.moduleConfig || !this.currentSite) {
            return;
        }

        this.initLanguagesToTranslate();
        this.initFormImages();
        this.initForm();

        if (this.data) {
            // Para corregir un modulo de limbo
            if (this.data.images && (!this.data.items || this.items.length === 0)) {
                this.items = this.data.images;
            } else {
                this.items = this.data.items ? this.data.items : [];
            }
            this.fillForm();
        }
    }

    isFilesManagerField(element) {
        return (element.type === 'image' || element.type === 'file' || element.type === 'video');
    }

    getModeData(modes, currentMode) {
        let mode = null;
        modes.forEach(element => {
            if (element.value === currentMode) {
                mode = element;
            }
        });

        return mode;
    }

    setVisibility(field, value) {
        this.moduleConfig.fields.forEach(elements => {
            elements.forEach(element => {
                if (element.field === field) {
                    element['hide'] = value;
                }
            });
        });
    }

    setFieldValidators(mode) {
        
        const modesModule = this.moduleConfig.configurations && this.moduleConfig.configurations.modes ? this.moduleConfig.configurations.modes.cases : null;
        if (!modesModule) {
            return;
        }

        let currentMode = this.getModeData(modesModule, mode);
        if (!currentMode) {
            currentMode = {};
        }

        let isExclude;
        this.elementsForm.forEach(element => {
            isExclude = (currentMode.exclude && currentMode.exclude.indexOf(element.field) > -1);
            this.setVisibility(element.field, isExclude);

            if (this.isFilesManagerField(element)) {
                // fieldsControl[element.field] = element.required ? new FormControl('', Validators.required) : new FormControl('');
                this.filesManager.setProperties(element.field, { required: (isExclude ? false : element.required) });
            } else if (element.type === 'ctas') {

            } else if (element.type === 'icons') {

            } else if (element.type === 'category-relation') {

            } else {
                if (!element.required || isExclude) {
                    this.f[element.field].clearValidators();
                } else {
                    this.f[element.field].setValidators([Validators.required]);
                }
                this.f[element.field].updateValueAndValidity();
            }
        });

    }
    checkRequiredFields() {
        const modesModule = this.moduleConfig.configurations && this.moduleConfig.configurations.modes ? this.moduleConfig.configurations.modes.cases : null;
        if (!modesModule) {
            return;
        }

        this.setFieldValidators('default');
    }

    initFormImages() {
        let conf;
        let maxSize;
        let allowedTypes;
        let allowedTypesTxt;

        const formImages = [];

        this.elementsForm.forEach(element => {
            if (this.isFilesManagerField(element)) {
                conf = element.configurations;
                maxSize = conf.max_size ? conf.max_size : 20971520;
                allowedTypesTxt = conf.allowed_types_txt ? conf.allowed_types_txt : '';
                allowedTypes = conf.allowed_types ? conf.allowed_types : null;
                if (!allowedTypes) {
                    switch (element.type) {
                        case 'image':
                            allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
                            allowedTypesTxt = 'PNG | JPG';
                            break;

                        case 'file':
                            allowedTypes = ['application/pdf'];
                            allowedTypesTxt = 'PDF';
                            break;

                        case 'video':
                            allowedTypes = ['video/mp4'];
                            allowedTypesTxt = 'MP4';
                            break;
                    }
                }

                formImages.push(
                    {
                        field: element.field,
                        initial_value: null,
                        validators: {
                            max_size: maxSize,
                            allowed_types: allowedTypes,
                            allowed_types_txt: allowedTypesTxt,
                            max_height: conf.max_height ? conf.max_height : 108000,
                            max_width: conf.max_width ? conf.max_width : 192000,
                            is_required: element.required
                        },
                        base64: null,
                        error: null,
                        saved: false,
                        file_name: ''
                    }
                );

                if (element.languages) {

                    const requiredFieldsLang = this.moduleConfig.requiredTraduction;

                    Object.entries(this.visibilityLanguagesToTranslate).forEach(([key, value]) => {
                        formImages.push(
                            {
                                field: key + '_' + element.field,
                                initial_value: null,
                                validators: {
                                    max_size: maxSize,
                                    allowed_types: allowedTypes,
                                    allowed_types_txt: allowedTypesTxt,
                                    max_height: conf.max_height ? conf.max_height : 108000,
                                    max_width: conf.max_width ? conf.max_width : 192000,
                                    is_required: (requiredFieldsLang && element.required)
                                },
                                base64: null,
                                error: null,
                                saved: false,
                                file_name: ''
                            }
                        );
                    });
                }
            }
        });

        this.filesManager = new FilesManager();
        this.filesManager.setConfiguration(formImages);
    }

    initForm() {
        const fieldsControl: any = {};

        this.elementsForm.forEach(element => {
            if (!this.isFilesManagerField(element) && element.type !== 'ctas' && element.type !== 'icons') {
                fieldsControl[element.field] = element.required ? new FormControl('', Validators.required) : new FormControl('');
                if (element.level && element.level) {
                    fieldsControl[element.field + '_level'] = new FormControl('');
                }
            } else if (this.isFilesManagerField(element) && element.hasTitle) {
                fieldsControl[element.field + '_title'] = new FormControl('');
            }
        });

        this.itemForm = this.formBuilder.group(fieldsControl);

        this.completeFormLanguages();

        this.loadedForms = true;
    }


    completeFormLanguages() {

        if (this.currentSite.i18n.length > 1) {
            let locale;
            let fieldsControl;

            const requiredFieldsLang = this.moduleConfig.requiredTraduction;

            this.languagesToTranslate.forEach(lang => {
                locale = lang.locale;

                fieldsControl = {};
                this.elementsForm.forEach(element => {
                    if (element.languages) {
                        fieldsControl[element.field] = (requiredFieldsLang && element.required) ? new FormControl('', Validators.required) : new FormControl('');
                    }
                });

                this.itemForm.addControl(locale + '_info', this.formBuilder.group(fieldsControl));
            });
        }

    }

    fillForm() {
        if (!this.moduleConfig || !this.currentSite || !this.data ) {
            return;
        }

        const module = this.data;
        const locale = this.i18nService.getCurrentLanguage().locale;

        this.elementsForm.forEach(element => {
            switch (element.type) {
                case 'image':
                case 'file':
                case 'video':
                    if (module && module[element.field] instanceof Object) {
                        this.filesManager.setProperties(element.field, { initial_value: module[element.field][locale] });
                    } else {
                        this.filesManager.setProperties(element.field, { initial_value: module[element.field] });
                    }
                    if (element.hasTitle && module.titles_images && module.titles_images[element.field]) {
                        this.f[element.field + '_title'].setValue(module.titles_images[element.field]);
                    }
                    break;

                case 'ctas':
                    this.ctas = module[element.field] instanceof Array ? module[element.field] : [];
                    break;

                case 'icons':
                    // this.icons = module[element.field] instanceof Array ? module[element.field] : [];
                    this.icons = module[element.field];
                    break;

                case 'category-relation':
                    if (element.configurations.inConfigField) {
                        this.f[element.field].setValue(module.config[element.field]);
                    } else {
                        this.f[element.field].setValue(module[element.field]);
                    }
                    break;

                default:
                    if (element.languages) {
                        if (element.configurations && element.configurations.inConfigField) {
                            this.f[element.field].setValue(this.textI18n.transform(module.config[element.field]));
                        } else {
                            this.f[element.field].setValue(this.textI18n.transform(module[element.field]));
                        }
                    } else {
                        if (element.configurations && element.configurations.inConfigField) {
                            this.f[element.field].setValue(module.config[element.field]);
                        } else {
                            this.f[element.field].setValue(module[element.field]);
                        }
                    }

                    if (element.level && module.levels && module.levels[element.field]) {
                        this.f[element.field + '_level'].setValue(module.levels[element.field]);
                    }

                    break;
            }
        });

        this.fillFormLanguages();
    }

    fillFormLanguages() {
        if (this.currentSite.i18n.length > 1) {

            let locale: string;
            let group;
            const module = this.data;

            this.languagesToTranslate.forEach(lang => {
                locale = lang.locale;
                group = this.itemForm.get(locale + '_info');

                this.languagesToTranslate.forEach(lang => {
                    locale = lang.locale;
                    group = this.itemForm.get(locale + '_info');

                    this.elementsForm.forEach(element => {
                        if (element.languages) {

                            switch (element.type) {
                                case 'image':
                                case 'file':
                                case 'video':
                                    if (module && module[element.field] instanceof Object) {
                                        this.filesManager.setProperties(locale + '_' + element.field, { initial_value: module[element.field][locale] });
                                    } else {
                                        this.filesManager.setProperties(locale + '_' + element.field, { initial_value: module[element.field] });
                                    }
                                    break;

                                default:
                                    if (element.configurations && element.configurations.inConfigField) {
                                        group.get(element.field).setValue(this.textI18n.transform(module.config[element.field], locale));
                                    } else {
                                        group.get(element.field).setValue(this.textI18n.transform(module[element.field], locale));
                                    }
                                    break;
                            }
                        }
                    });
                });
            });
        }

    }

    cancelSave() {
        this.store.dispatch(new AsideItemActions.ChangeToSave(false));
    }

    cancelSaveChild() {
        this.store.dispatch(new AsideItemActions.CancelSaveChild());
    }

    validFiles() {
        let tmpValid;
        let validFiles = true;

        this.elementsForm.forEach(element => {
            if (this.isFilesManagerField(element)) {
                tmpValid = this.filesManager.isValidFile(element.field, !this.editingItem ? 'add' : 'edit');
                if (!tmpValid) {
                    validFiles = false;
                }
            }
        });

        return validFiles;
    }

    validItems() {
        let validItems = true;

        const items = this.items;

        if (this.moduleConfig.items && this.moduleConfig.items.configurations) {
            if (this.moduleConfig.items.configurations.required && !items.length) {
                validItems = false;
            }

            if (this.moduleConfig.items.configurations.min && (this.moduleConfig.items.configurations.min > items.length) ) {
                validItems = false;
            }
        }

        this.itemsError = validItems;

        return validItems;
    }

    validCtas() {
        let validCtas = true;

        this.elementsForm.forEach(element => {
            if (element.type === 'ctas' && element.required) {
                element.hasError = false;

                if (!this.ctas.length) {
                    validCtas = false;
                    element.hasError = true;
                }

                if (element.configurations) {
                    if (element.configurations.min && (!this.ctas || this.ctas.length < element.configurations.min)) {
                        validCtas = false;
                        element.hasError = true;
                    }
                }
            }
        });

        return validCtas;
    }

    validIcons() {
        let validIcons = true;

        this.elementsForm.forEach(element => {
            if (element.type === 'icons' && element.required) {
                element.hasError = false;

                if (!this.icons || !this.icons.length) {
                    validIcons = false;
                    element.hasError = true;
                }

                if (element.configurations) {
                    if (element.configurations.min && (!this.icons || this.icons.length < element.configurations.min)) {
                        validIcons = false;
                        element.hasError = true;
                    }
                }
            }
        });

        return validIcons;
    }

    getErrorMessage(validFiles) {
        let error = '';

        if (this.itemForm.invalid) {
            error += 'Revisa los campos obligatorios';
        } else if (!this.itemForm.invalid && !validFiles) {
            error += 'Revisa los archivos';
        } else {
            error += 'Revisa los campos obligatorios';
        }

        return error;
    }

    onSubmit() {
        this.submitted = true;

        const payload = { error: null };
        // this.store.dispatch(new AsideItemActions.SetError(payload));

        const validFiles = this.validFiles();
        const validCtas = this.validCtas();
        const validIcons = this.validIcons();
        const validItems = this.validItems();

        if (this.itemForm.invalid || !validFiles || !validCtas || !validIcons || !validItems) {
            payload.error = this.getErrorMessage(validFiles);

            if (this.isChild) {
                this.store.dispatch(new AsideItemActions.SetErrorChild(payload));

                return this.cancelSaveChild();
            } else {
                this.store.dispatch(new AsideItemActions.SetError(payload));

                return this.cancelSave();
            }
        }

        this.save();
    }

    initData() {
        const obj = {
            config: {},
            items: [],
            levels: {},
            titles_images: {}
        };

        this.elementsForm.forEach(element => {

            if (!obj[element.field]) {
                obj[element.field] = null;
            }
        });

        return obj;

    }

    getFieldInData(field) {
        let value = null;

        Object.keys(this.data).forEach((key) => {
            if (key === field) {
                value = this.data[key];
            }
        });

        return value;
    }

     fitData() {
        const obj = {
            config: {},
            levels: {},
            titles_images: {}
        };

        let valueInData;

        this.elementsForm.forEach(element => {

            valueInData = this.getFieldInData(element.field);
            if (element.languages && Array.isArray(valueInData)) {
                obj[element.field] = Object.assign({}, valueInData);
            } else {
                obj[element.field] = valueInData;
            }
        });

        return obj;
     }

    getDataToFormCommonLanguages(module) {
        let locale;
        let group;
        let valueField;

        this.languagesToTranslate.forEach(lang => {
            locale = lang.locale;
            group = this.itemForm.get(locale + '_info');

            this.elementsForm.forEach(element => {

                if (element.languages) {
                    if (this.isFilesManagerField(element)) {
                        valueField = this.filesManager.getBase64Image(locale + '_' + element.field);
                        if (!valueField) {
                            valueField = this.filesManager.getInitialImage(locale + '_' + element.field);
                        }
                    } else {
                        valueField = group.get(element.field).value;
                    }

                    if (element.type !== 'icons') {
                        module[element.field] = this.localeUtils.getDataLocaleToForm(element.field, module[element.field], valueField, locale);
                    }
                }
            });
        });

        return module;
    }

    getDataCommon() {
        const locale = this.currentLanguage.locale;
        const module = !this.editingItem ? this.initData() : this.fitData();

        const moduleTest = _.cloneDeep(module);

        let valueFieldTitles;
        let valueFieldLevel;
        let valueField;
        let valueFieldFileName;
        this.elementsForm.forEach(element => {

            if (this.isFilesManagerField(element)) {
                valueField = this.filesManager.getBase64Image(element.field);
                if (!valueField) {
                    valueField = this.filesManager.getInitialImage(element.field);
                } else {
                    valueFieldFileName = this.filesManager.getFileName(element.field);
                    module[element.field + '_name'] = valueFieldFileName;
                }

                if (element.hasTitle) {
                    valueFieldTitles = this.f[element.field + '_title'].value;

                    if (!module.titles_images) {
                        module.titles_images = {};
                    }
                    module.titles_images[element.field] = valueFieldTitles;
                }
            } else if (element.type === 'ctas') {
                valueField = this.ctas;
            } else if (element.type === 'icons') {
                valueField = this.icons;
            } else {
                valueField = this.f[element.field].value;

                if (element.level) {
                    valueFieldLevel = this.f[element.field + '_level'].value;

                    if (!module.levels) {
                        module.levels = {};
                    }
                    module.levels[element.field] = valueFieldLevel;
                }
            }

            if ((element.languages) && element.type !== 'category-relation' && element.type !== 'icons') {
                if (element.configurations && element.configurations.inConfigField) {
                    module.config[element.field] = this.localeUtils.getDataLocaleToForm(element.field, module.config[element.field], valueField, locale);
                } else {
                    module[element.field] = this.localeUtils.getDataLocaleToForm(element.field, module[element.field], valueField, locale);
                }
            } else if (element.type === 'icons') {
                if (element.languages && (!element.configurations || !element.configurations.has_languages)) {
                    
                    if (element.configurations && element.configurations.inConfigField) {
                        module.config[element.field] = this.localeUtils.getDataLocaleToForm(element.field, module.config[element.field], valueField, locale);
                    } else {
                        module[element.field] = this.localeUtils.getDataLocaleToForm(element.field, module[element.field], valueField, locale);
                    }
                } else {
                    if (element.configurations && element.configurations.inConfigField) {
                        module.config[element.field] = valueField;
                    } else {
                        module[element.field] = valueField;
                    }
                }
            } else {
                if (element.configurations && element.configurations.inConfigField) {
                    module.config[element.field] = valueField;
                } else {
                    module[element.field] = valueField;
                }
            }
        });

        return module;
    }

    saveItems(data) {
        data.items = this.items;

        return data;
    }

    save() {
        const locale = this.currentLanguage.locale;

        let data = this.getDataCommon();
        data = this.getDataToFormCommonLanguages(data);

        if (this.moduleConfig.items) {
            data = this.saveItems(data);
        }

        if (this.isChild) {
            this.sendItems.emit(data);
        } else {
            this.store.dispatch(new AsideItemActions.SetResult(data));
        }

        this.data = null;
    }

    setCtasInfo(items) {
        this.ctas = items;
    }

    setCategoryRelation(data) {
        this.f[data.item.field].setValue(data.values);
    }

    setIconsInfo(items) {
        this.icons = items;
    }

    changingTab(ev) {
        this.tabChange++;
        if (!this.isChild) {
            this.store.dispatch(new AsideItemActions.CloseForm());
        }
    }
}
