import { Injectable } from '@angular/core';


@Injectable()
export class RoutesService {

    getRoute(section: string, id?: any, idParent?: any) {

        switch (section) {
            case 'home': return '/home';
            case 'login': return '/login';
            case 'remember': return '/recordar';

            case 'blog': return '/blog/posts';
            case 'blogDetail': return '/blog/posts/' + id;
            case 'blogDetailContent': return '/blog/posts/' + id + ' /contenido';
            case 'blogEdit': return '/blog/posts/' + id + '/editar';
            case 'blogNew': return '/blog/posts/nuevo';
            case 'blogCsv': return '/blog/csv';

            case 'blog-categories':
            case 'blogCategories': return '/blog/categorias';
            case 'blogCategoriesDetail': return '/blog/categorias/' + id;
            case 'blogCategoriesEdit': return '/blog/categorias/' + id + '/editar';
            case 'blogCategoriesNew': return '/blog/categorias/nuevo';

            case 'blogSecond': return '/noticias/posts';
            case 'blogSecondDetail': return '/noticias/posts/' + id;
            case 'blogSecondDetailContent': return '/noticias/posts/' + id + ' /contenido';
            case 'blogSecondEdit': return '/noticias/posts/' + id + '/editar';
            case 'blogSecondNew': return '/noticias/posts/nuevo';
            case 'blogSecondCsv': return '/noticias/csv';

            case 'blogSecond-categories':
            case 'blogSecondCategories': return '/noticias/categorias';
            case 'blogSecondCategoriesDetail': return '/noticias/categorias/' + id;
            case 'blogSecondCategoriesEdit': return '/noticias/categorias/' + id + '/editar';
            case 'blogSecondCategoriesNew': return '/noticias/categorias/nuevo';

            case 'catalog': return '/catalogo/productos';
            case 'catalogDetail': return '/catalogo/productos/' + id;
            case 'catalogDetailContent': return '/catalogo/productos/' + id + ' /contenido';
            case 'catalogEdit': return '/catalogo/productos/' + id + '/editar';
            case 'catalogNew': return '/catalogo/productos/nuevo';

            case 'catalogCategories': return '/catalogo/categorias';
            case 'catalogCategoriesDetail': return '/catalogo/categorias/' + id;
            case 'catalogCategoriesDetailContent': return '/catalogo/categorias/' + id + ' /contenido';
            case 'catalogCategoriesEdit': return '/catalogo/categorias/' + id + '/editar';
            case 'catalogCategoriesNew': return '/catalogo/categorias/nueva';

            case 'catalogSubcategories': return '/catalogo/subcategorias';
            case 'catalogSubcategoriesDetail': return '/catalogo/subcategorias/' + id;
            case 'catalogSubcategoriesDetailContent': return '/catalogo/subcategorias/' + id + ' /contenido';
            case 'catalogSubcategoriesEdit': return '/catalogo/subcategorias/' + id + '/editar';
            case 'catalogSubcategoriesNew': return '/catalogo/subcategorias/nueva';


            case 'catalogs': return '/contenidos/catalogos';
            case 'catalogsDetail': return '/contenidos/catalogos/' + id;
            case 'catalogsEdit': return '/contenidos/catalogos/' + id + '/editar';
            case 'catalogsNew': return '/contenidos/catalogos/nuevo';

            case 'configMenus': return '/configuracion/menus';
            // case 'configMenu': return '/configuracion/menus/';
            case 'configMenu': return '/configuracion/menu/' + id;
            case 'configMenuItemNew': return '/configuracion/menu/' + idParent + '/item/nuevo';
            case 'configMenuItemDetail': return '/configuracion/menu/' + idParent + '/item/' + id;
            case 'configMenuItemEdit': return '/configuracion/menu/' + idParent + '/item/' + id + '/editar';

            // case 'configLiterals': return '/literales';
            case 'configLiterals': return '/configuracion/literales';

            case 'dashboard': return '/dashboard';

            case 'documents': return '/contenidos/documentos';
            case 'documentsNew': return '/contenidos/documentos/nuevo';
            case 'documentsDetail': return '/contenidos/documentos/' + id;
            case 'documentsEdit': return '/contenidos/documentos/' + id + '/editar';
            
            case 'documentsCategories': return '/contenidos/documentos/categorias';
            case 'documentsCategoriesDetail': return '/contenidos/documentos/categorias/' + id;
            case 'documentsCategoriesEdit': return '/contenidos/documentos/categorias/' + id + '/editar';
            case 'documentsCategoriesNew': return '/contenidos/documentos/categorias/nueva';

            // case 'events': return '/eventos';
            case 'events': return '/contenidos/eventos';
            case 'eventsNew': return '/contenidos/eventos/nuevo';
            case 'eventsDetail': return '/contenidos/eventos/' + id;
            case 'eventsEdit': return '/contenidos/eventos/' + id + '/editar';
            
            case 'eventsCategories': return '/contenidos/eventos/categorias';
            case 'eventsCategoriesDetail': return '/contenidos/eventos/categorias/' + id;
            case 'eventsCategoriesEdit': return '/contenidos/eventos/categorias/' + id + '/editar';
            case 'eventsCategoriesNew': return '/contenidos/eventos/categorias/nueva';

            case 'experiences': return '/contenidos/experiencias';
            case 'experiencesDetail': return '/contenidos/experiencias/' + id;
            case 'experiencesEdit': return '/contenidos/experiencias/' + id + '/editar';
            case 'experiencesNew': return '/contenidos/experiencias/nueva';

            case 'faqs': return '/contenidos/faqs';
            case 'faqsDetail': return '/contenidos/faqs/' + id;
            case 'faqsEdit': return '/contenidos/faqs/' + id + '/editar';
            case 'faqsNew': return '/contenidos/faqs/nueva';

            case 'faqsCategories': return '/contenidos/faqs/categorias';
            case 'faqsCategoriesDetail': return '/contenidos/faqs/categorias/' + id;
            case 'faqsCategoriesEdit': return '/contenidos/faqs/categorias/' + id + '/editar';
            case 'faqsCategoriesNew': return '/contenidos/faqs/categorias/nuevo';

            case 'help': return '/ayuda';

            case 'galleries': return '/contenidos/galerias';
            case 'galleriesNew': return '/contenidos/galerias/nueva';
            case 'galleriesDetail': return '/contenidos/galerias/' + id;
            case 'galleriesEdit': return '/contenidos/galerias/' + id + '/editar';
            
            case 'galleriesCategories': return '/contenidos/galerias/categorias';
            case 'galleriesCategoriesDetail': return '/contenidos/galerias/categorias/' + id;
            case 'galleriesCategoriesEdit': return '/contenidos/galerias/categorias/' + id + '/editar';
            case 'galleriesCategoriesNew': return '/contenidos/galerias/categorias/nueva';

            case 'glossaries': return '/contenidos/glosario';
            case 'glossariesDetail': return '/contenidos/glosario/' + id;
            case 'glossariesEdit': return '/contenidos/glosario/' + id + '/editar';
            case 'glossariesNew': return '/contenidos/glosario/nuevo';

            case 'leads': return '/leads';
            case 'leadsDetail': return '/leads/' + id;
            case 'leadsContact': return '/leads';
            case 'leadsInfo': return '/leads';
            case 'leadsCarpets': return '/leads';
            
            case 'leadsTypes':
            case 'leadsTypes': return '/leads/tipos';
            case 'leadsTypesDetail': return '/leads/tipos/' + id;
            case 'leadsTypesEdit': return '/leads/tipos/' + id + '/editar';
            case 'leadsTypesNew': return '/leads/tipos/nuevo';

            case 'modulesTypes': return '/paginas/modulos-tipos';
            case 'modulesTypesDetail': return '/paginas/modulos-tipos/' + id;
            case 'modulesTypesEdit': return '/paginas/modulos-tipos/' + id + '/editar';
            case 'modulesTypesNew': return '/paginas/modulos-tipos/nuevo';

            case 'modules': return '/paginas/modulos';
            case 'modulesDetail': return '/paginas/modulos/' + id;
            case 'modulesEdit': return '/paginas/modulos/' + id + '/editar';
            case 'modulesNew': return '/paginas/modulos/nuevo';

            case 'obituaries': return '/contenidos/laotraesquela';
            case 'obituariesNew': return '/contenidos/laotraesquela/nueva';
            case 'obituariesDetail': return '/contenidos/laotraesquela/' + id;
            case 'obituariesDetailContent': return '/contenidos/laotraesquela/' + id + '/contenido';
            case 'obituariesEdit': return '/contenidos/laotraesquela/' + id + '/editar';

            case 'offices': return '/contenidos/oficinas';
            case 'officesDetail': return '/contenidos/oficinas/' + id;
            case 'officesDetailContent': return '/contenidos/oficinas/' + id + '/contenido';
            case 'officesEdit': return '/contenidos/oficinas/' + id + '/editar';
            case 'officesNew': return '/contenidos/oficinas/nueva';

            case 'officesTypes': return '/contenidos/oficinas/tipos';
            case 'officesTypesDetail': return '/contenidos/oficinas/tipos/' + id;
            case 'officesTypesEdit': return '/contenidos/oficinas/tipos/' + id + '/editar';
            case 'officesTypesNew': return '/contenidos/oficinas/tipos/nuevo';

            case 'pages': return '/paginas';
            case 'pagesDetail': return '/paginas/' + id;
            case 'pagesDetailContent': return '/paginas/' + id + '/contenido';
            case 'pagesEdit': return '/paginas/' + id + '/editar';
            case 'pagesNew': return '/paginas/nuevo';
            case 'pagesComingSoon': return '/paginas/proximamente';

            case 'pagesTypes': return '/paginas/tipos';
            case 'pagesTypesDetail': return '/paginas/tipos/' + id;
            case 'pagesTypesEdit': return '/paginas/tipos/' + id + '/editar';
            case 'pagesTypesNew': return '/paginas/tipos/nuevo';

            case 'policies': return '/contenidos/politicas';
            case 'policiesDetail': return '/contenidos/politicas/' + id;
            case 'policiesEdit': return '/contenidos/politicas/' + id + '/editar';
            case 'policiesNew': return '/contenidos/politicas/nueva';

            case 'policiesCategories': return '/contenidos/politicas/categorias';
            case 'policiesCategoriesDetail': return '/contenidos/politicas/categorias/' + id;
            case 'policiesCategoriesEdit': return '/contenidos/politicas/categorias/' + id + '/editar';
            case 'policiesCategoriesNew': return '/contenidos/politicas/categorias/nueva';

            case 'press': return '/contenidos/prensa';
            case 'pressNew': return '/contenidos/prensa/nueva';
            case 'pressDetail': return '/contenidos/prensa/' + id;
            case 'pressEdit': return '/contenidos/prensa/' + id + '/editar';
            
            case 'pressCategories': return '/contenidos/prensa/categorias';
            case 'pressCategoriesDetail': return '/contenidos/prensa/categorias/' + id;
            case 'pressCategoriesEdit': return '/contenidos/prensa/categorias/' + id + '/editar';
            case 'pressCategoriesNew': return '/contenidos/prensa/categorias/nueva';

            case 'promotions': return '/contenidos/promocion';
            case 'promotionsNew': return '/contenidos/promocion/nuevo';
            case 'promotionsDetail': return '/contenidos/promocion/' + id;
            case 'promotionsEdit': return '/contenidos/promocion/' + id + '/editar';

            case 'publications': return '/contenidos/publicaciones';
            case 'publicationsNew': return '/contenidos/publicaciones/nuevo';
            case 'publicationsDetail': return '/contenidos/publicaciones/' + id;
            case 'publicationsEdit': return '/contenidos/publicaciones/' + id + '/editar';

            
            case 'repository': return '/configuracion/repositorio';
            // case 'repository': return '/multimedia';
            case 'repositoryDetail': return '/multimedia/' + id;
            case 'repositoryEdit': return '/multimedia/' + id + '/editar';
            case 'repositoryNew': return '/multimedia/nuevo';

            case 'testimonials': return '/contenidos/testimonios';
            case 'testimonialsDetail': return '/contenidos/testimonios/' + id;
            case 'testimonialsEdit': return '/contenidos/testimonios/' + id + '/editar';
            case 'testimonialsNew': return '/contenidos/testimonios/nuevo';

            case 'videos': return '/contenidos/videos';
            case 'videosDetail': return '/contenidos/videos/' + id;
            case 'videosEdit': return '/contenidos/videos/' + id + '/editar';
            case 'videosNew': return '/contenidos/videos/nuevo';

            case 'videosCategories': return '/contenidos/videos/categorias';
            case 'videosCategoriesDetail': return '/contenidos/videos/categorias/' + id;
            case 'videosCategoriesEdit': return '/contenidos/videos/categorias/' + id + '/editar';
            case 'videosCategoriesNew': return '/contenidos/videos/categorias/nueva';

            case 'admins':  return '/configuracion/usuarios';
            case 'adminsDetail': return '/configuracion/usuarios/' + id;
            case 'adminsEdit': return '/configuracion/usuarios/' + id + '/editar';
            case 'adminsNew': return '/configuracion/usuarios/nuevo';

            case 'cities':  return '/configuracion/ciudades';
            case 'citiesDetail': return '/configuracion/ciudades/' + id;
            case 'citiesEdit': return '/configuracion/ciudades/' + id + '/editar';
            case 'citiesNew': return '/configuracion/ciudades/nuevo';


            default: return '/' + section;
        }

    }
}
