import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { CatalogCatalog } from '../models/catalog-catalog';

import { CatalogCategoriesService } from './catalog-categories.service';
import { CatalogSubcategoriesService } from './catalog-subcategories.service';
import { PagesService } from './pages.service';
import { CustomRequest } from '../helpers/custom-request';


@Injectable({ providedIn: 'root' })
export class CatalogCatalogService {
    constructor(
        private categoriesService: CatalogCategoriesService,
        private subcategoriesService: CatalogSubcategoriesService,
        private pagesService: PagesService,
        private request: CustomRequest
    ) { }

    getFilters() {
        return this.request.get(`${environment.apiUrl}/catalog-filters`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/catalog`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/catalog/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/catalog/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/catalog/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/catalog/` + id);
    }

    setContent(data: any) {
        return this.request.post(`${environment.apiUrl}/catalog/` + data._id + `/content/`, data.content);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/catalog/` + id + `/enabled/` + status, null);
    }

    transformDataFromApi(data: any) {
        const catalog = new CatalogCatalog();

        catalog.sites = data.sites;

        catalog._id = data._id;
        catalog.code = data.code;
        catalog.name = data.name;
        catalog.description = data.description;
        catalog.details = data.details;
        catalog.download = data.download;
        catalog.icons = data.icons;
        catalog.image = data.image;
        catalog.media = data.media;
        catalog.features = data.features;
        catalog.only_list = data.only_list;
        catalog.order = data.order;
        catalog.enabled = data.enabled;

        catalog.category = data.category ? this.categoriesService.transformDataFromApi(data.category) : null;
        catalog.subcategory = data.subcategory ? this.subcategoriesService.transformDataFromApi(data.subcategory) : null;
        catalog.page = data.page ? this.pagesService.transformDataFromApi(data.page) : null;

        catalog.created_at = data.created_at;
        catalog.updated_at = data.updated_at;

        return catalog;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}
