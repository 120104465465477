import { ActionReducer, Action } from '@ngrx/store';
import * as fromActions from './layout.actions';
import { LayoutState } from './layout.state';
import { OPEN_MENU_MOBILE, CLOSE_MENU_MOBILE, CLOSE_BLACKDROP, SET_MAIN_SECTION } from './layout.actions';


const defaultState: LayoutState = {
    lastMenuSection: '',
    menuSection: '',
    currentSection: '',
    isMobileMenuOpen: false,
    isCollapseAside: false,
    openAsideConfig: false,
    openAsideSecondary: false,
    openAsideItems: false,
    asideLeftSection: '',
    reload: false
};

export type Action = fromActions.All;

export function LayoutReducer(state = defaultState, action: any) {
    // console.log(state, action);
    switch (action.type) {

        case fromActions.TOGGLE_COLLAPSED:
            return { ...state, isCollapseAside: !state.isCollapseAside };

        case fromActions.OPEN_ASIDE_CONFIG:
            return { ...state,
                openAsideConfig: true,
                asideLeftSection: action.payload
             };

        case fromActions.CLOSE_ASIDE_CONFIG:
            return { ...state,
                openAsideConfig: false,
                asideLeftSection: ''
            };

        case fromActions.OPEN_ASIDE_SECONDARY:
            return { ...state,
                openAsideConfig: false,
                openAsideSecondary: true
             };

        case fromActions.CLOSE_ASIDE_SECONDARY:
            return { ...state,
                openAsideConfig: false,
                openAsideSecondary: false
            };

        case fromActions.OPEN_ASIDE_ITEMS:
            return { ...state,
                openAsideItems: true
                };

        case fromActions.CLOSE_ASIDE_ITEMS:
            return { ...state,
                openAsideItems: false
            };

        case fromActions.OPEN_MENU_MOBILE:
            return { ...state, isMobileMenuOpen: true };

        case fromActions.CLOSE_MENU_MOBILE:
            return { ...state, isMobileMenuOpen: false };

        case fromActions.CLOSE_BLACKDROP:
            return {
                ...state,
                isMobileMenuOpen: false,
                openAsideConfig: false,
                openAsideSecondary: false
            };

        case fromActions.SET_MAIN_SECTION:
            return { ...state,
                lastMenuSection: state.menuSection,
                menuSection: action.payload };

        case fromActions.SET_CURRENT_SECTION:
            return { ...state,
                currentSection: action.payload };

        case fromActions.SET_RELOAD:
            // console.log('RELOAD ACTION PAYLOAD', action.payload);
            return { ...state,
                reload: action.payload };

        default:
            return state;
    }
}
