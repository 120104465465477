import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as officesTypesActions from './offices-types.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { OfficesTypesService } from '../../shared/services/offices-types.service';



@Injectable()
export class OfficesTypesEffects {

    constructor(
        private officesTypesService: OfficesTypesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoOfficesTypes$: Observable<Action> = this.actions$.pipe(
        ofType(officesTypesActions.DO_GET_INFO_OFFICES_TYPES),
        switchMap((action: officesTypesActions.DoGetInfoOfficesTypes) => {
            return this.officesTypesService.get(action).pipe(
                map( response => new officesTypesActions.DoGetInfoOfficesTypesOK(response)),
                catchError(error => of(new officesTypesActions.DoGetInfoOfficesTypesKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedFiltersCategories$: Observable<Action> = this.actions$.pipe(
        ofType(officesTypesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: officesTypesActions.DoSetSelectedFilters) => {
            // console.log('DO SELECTED FILTERS', action);
            return this.officesTypesService.get(action).pipe(
                map( response => new officesTypesActions.DoGetInfoOfficesTypesOK(response)),
                catchError(error => of(new officesTypesActions.DoGetInfoOfficesTypesKO(error)))
            );
        }));
}
