export class EventCategory {

    /**
    code: string
    enabled: boolean
    name: object
    sites
    */

    _id: string;
    code: string;
    enabled: boolean;
    name: any;
    sites: Array<string>;

    created_at: string;
    updated_at: string;

    // posts: Array<any>;
}