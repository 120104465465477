import { Component, Input, OnDestroy } from '@angular/core';
import { UrlImagePipe } from '../../../pipes/url-image.pipe';
import { AppState } from '@app/store/index';
import { Store } from '@ngrx/store';
import * as AsideActions from '@app/store/aside/aside.actions';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-module-common',
    templateUrl: './common.component.html',
    providers: [
        UrlImagePipe
    ]
})
export class CommonComponent implements OnDestroy {

    @Input() data: any;
    @Input() modeView: string;
    @Input() index: number;
    @Input() box: number;
    @Input() section: string;
    @Input() typeSection: string;

    isActivating = true;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(private store: Store<AppState>) {
        this.suscriptionsToManage$.push(this.store.select(state => state.aside.active).subscribe(res => {
            this.isActivating = res;
        }));
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    getUrlImage() {
        // return this.urlImage.transform(this.data.image);
        return this.data.image;
    }

    add() {
        const payload = { box: this.box, add: this.data };
        this.store.dispatch(new AsideActions.SetToAdd(payload));
    }

    edit() {
        const payload = { index: this.index, box: this.box };
        this.store.dispatch(new AsideActions.SetToEdit(payload));
    }

    delete() {
        const payload = { index: this.index, box: this.box };
        this.store.dispatch(new AsideActions.SetToDelete(payload));
    }

    clickChangeStatus() {
        const payload = { index: this.index, box: this.box, };
        this.store.dispatch(new AsideActions.SetToActive(payload));
    }
}

