import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as leadsTypesActions from './leads-types.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { LeadsTypesService } from '../../shared/services/leads-types.service';



@Injectable()
export class LeadsTypesEffects {

    constructor(
        private leadsTypesService: LeadsTypesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoLeadsTypes$: Observable<Action> = this.actions$.pipe(
        ofType(leadsTypesActions.DO_GET_INFO_LEADS_TYPES),
        switchMap((action: leadsTypesActions.DoGetInfoLeadsTypes) => {
            return this.leadsTypesService.get(action).pipe(
                map( response => new leadsTypesActions.DoGetInfoLeadsTypesOK(response)),
                catchError(error => of(new leadsTypesActions.DoGetInfoLeadsTypesKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedFiltersCategories$: Observable<Action> = this.actions$.pipe(
        ofType(leadsTypesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: leadsTypesActions.DoSetSelectedFilters) => {
            // console.log('DO SELECTED FILTERS', action);
            return this.leadsTypesService.get(action).pipe(
                map( response => new leadsTypesActions.DoGetInfoLeadsTypesOK(response)),
                catchError(error => of(new leadsTypesActions.DoGetInfoLeadsTypesKO(error)))
            );
        }));
}
