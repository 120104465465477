import { Page } from './page';
import { CatalogCategory } from './catalog-category';

export class CatalogSubcategory {
    _id: string;
    code: string;
    name: any;
    description: any;
    text_link_subcategory: any;
    image: any;
    icon_hero: any;
    icons: any;
    title_list_products: any;
    description_list_products: any;
    order: number;
    enabled: boolean;

    category: CatalogCategory;
    page: Page;
    sites: Array<string>;
    catalogs: Array<any>;

    created_at: string;
    updated_at: string;
}
