import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as modulesActions from './modules.actions';

import { ModulesService } from '../../shared/services/modules.service';



@Injectable()
export class ModulesEffects {

    constructor(
        private modulesService: ModulesService,
        private actions$: Actions
    ) { }

    @Effect()
    DoGetInfoModules$: Observable<Action> = this.actions$.pipe(
        ofType(modulesActions.DO_GET_INFO_MODULES),
        switchMap((action: modulesActions.DoGetInfoModules) => {
            return this.modulesService.get(action).pipe(
                map( response => {
                    response.list = this.modulesService.transformListFromApi(response.list);
                    return new modulesActions.DoGetInfoModulesOK(response);
                }),
                catchError(error => of(new modulesActions.DoGetInfoModulesKO(error)))
            );
        }));

    @Effect()
    DoGetInfoModulesFilters$: Observable<Action> = this.actions$.pipe(
        ofType(modulesActions.DO_GET_INFO_FILTERS),
        switchMap((action: modulesActions.DoGetInfoModulesFilters) => {
            return this.modulesService.getFilters().pipe(
                map( response => new modulesActions.DoGetInfoModulesFiltersOK(response)),
                catchError(error => of(new modulesActions.DoGetInfoModulesFiltersKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedFiltersModules$: Observable<Action> = this.actions$.pipe(
        ofType(modulesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: modulesActions.DoSetSelectedFilters) => {
            return this.modulesService.get(action).pipe(
                map( response => new modulesActions.DoGetInfoModulesOK(response)),
                catchError(error => of(new modulesActions.DoGetInfoModulesKO(error)))
            );
        }));
}
