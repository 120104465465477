import { Injectable } from '@angular/core';
import { FileForm } from '../models/file-form';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })

export class FilesManager {
    files: Array<FileForm>;

    setConfiguration(files: Array<FileForm>) {
        if (this.files && this.files.length) {
            let find = false;

            files.forEach(element => {
                let index = 0;
                find = false;
                this.files.forEach(item => {
                    if (item.field === element.field) {
                        find = true;
                        element.file_name = '';
                        this.files[index] = element;
                    }
                    index++;
                });

                if (!find) {
                    this.files.push(element);
                }

            });

        } else {
            // this.files = files;
            this.files = JSON.parse( JSON.stringify( files ) );
        }
    }

    findImageInFormImages(name: string) {
        return this.files.filter(x => x.field === name)[0];
    }

    setProperties(name: string, data: any) {
        this.files.forEach(file => {
            if (file.field !== name) {
                return;
            }

            if (data.hasOwnProperty('error')) {
                file.error = data.error;
            }

            if (data.hasOwnProperty('base64')) {
                file.base64 = data.base64;
            }

            if (data.hasOwnProperty('saved')) {
                file.saved = data.saved;
            }

            if (data.hasOwnProperty('initial_value')) {
                file.initial_value = data.initial_value;
            }

            if (data.hasOwnProperty('required')) {
                file.validators.is_required = data.required;
            }

            if (data.hasOwnProperty('file_name')) {
                file.file_name = data.file_name;
            }
        });
    }

    getFileNameWithNoExtension(fileName) {
        const fileData = fileName.split('.');

        return fileData[(fileData.length - 2)];
    }

    fileChangeEvent(field: any, name: string) {
        const data = this.findImageInFormImages(name);

        if (!data) {
            return null;
        }

        const validator = data.validators;
        const file = field.target.files[0] || null;

        this.setProperties(name, { error: null });

        if (!file) {
            return;
        }

        const isImage = this.isImage(file);
        const fileName = this.getFileNameWithNoExtension(file.name);

        if (validator.max_size && file.size > validator.max_size) {
            const errorTxt = 'El peso no puede exceder los ' + validator.max_size / 1000000 + 'Mb';
            return this.setProperties(name, { error: errorTxt, base64: null, saved: false });
        }

        if (validator.allowed_types && !_.includes(validator.allowed_types, file.type)) {
            const errorTxt = 'Sólo están permitidos los formatos  ' + validator.allowed_types_txt;
            return this.setProperties(name, { error: errorTxt, base64: null, saved: false });
        }


        const reader = new FileReader();

        reader.onload = (e: any) => {
            const image = new Image();

            image.src = e.target.result;

            if (isImage) {
                image.onload = rs => {
                    const height = rs.currentTarget['height'];
                    const width = rs.currentTarget['width'];

                    if (validator.max_height && height > validator.max_height) {
                        const errorTxt = 'El alto máximo es  ' + (validator.max_height / 100) + 'px';
                        return this.setProperties(name, { error: errorTxt, base64: null, saved: false });
                    }

                    if (validator.max_width && width > validator.max_width) {
                        const errorTxt = 'El ancho máximo es  ' + (validator.max_width / 100) + 'px';
                        return this.setProperties(name, { error: errorTxt, base64: null, saved: false });
                    }


                    this.setProperties(name, {
                        base64: e.target.result,
                        saved: true,
                        error: null,
                        file_name: fileName
                    });
                };
            } else {
                this.setProperties(name, {
                    base64: e.target.result,
                    saved: true,
                    error: null,
                    file_name: fileName
                });
            }

        };

        reader.readAsDataURL(file);
    }

    isImage(file: any) {
        return ['image/jpeg', 'image/png'].indexOf(file.type) !== -1;
    }

    removeImage(name: string) {
        this.setProperties(name, { base64: null, saved: false });
    }

    removeImageInitial(name: string) {
        this.setProperties(name, { initial_value: null });
    }

    isFileSaved(name: string) {
        const data = this.findImageInFormImages(name);

        if (data) {
            return data.saved;
        }
    }

    getBase64Image(name: string) {
        const data = this.findImageInFormImages(name);

        if (data) {
            return data.base64;
        }
    }

    getFileName(name: string) {
        const data = this.findImageInFormImages(name);

        if (data) {
            return data.file_name;
        }
    }

    getErrorImage(name: string) {
        const data = this.findImageInFormImages(name);

        if (data) {
            return data.error;
        }
    }

    getInitialImage(name: string) {
        const data = this.findImageInFormImages(name);

        if (data) {
            return data.initial_value;
        }
    }

    isValidAllFiles(action = 'edit') {
        let error = false;

        this.files.forEach(each => {
            if (each.error) {
                error = true;
            }

            if (action === 'add' && each.validators.is_required && !each.base64) {
                each.error = 'Archivo obligatorio';
                error = true;
            }

            if (action === 'edit' && each.validators.is_required && !each.initial_value) {
                each.error = 'Archivo obligatorio';
                error = true;
            }
        });

        return error ? false : true;
    }

    isValidFile(name: string, action = 'edit') {
        const data = this.findImageInFormImages(name);

        let error = false;

        if (data.error) {
            error = true;
        }

        if (action === 'add' && data.validators.is_required && !data.base64) {
            if (!data.error) {
                data.error = 'Archivo obligatorio';
            }
            error = true;
        }

        if (action === 'edit' && data.validators.is_required && !data.initial_value && !data.base64) {
            if (!data.error) {
                data.error = 'Archivo obligatorio';
            }
            error = true;
        }

        return error ? false : true;
    }

    isBase64(str) {
        return str.indexOf('data:') > -1;
    }
}
