import { Action } from '@ngrx/store';

// BLOG
export const DO_GET_INFO_OFFICES_TYPES = '[Offices Types] Get Info Tipos';
export class DoGetInfoOfficesTypes implements Action {
    type = DO_GET_INFO_OFFICES_TYPES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_OFFICES_TYPES_OK = '[Offices Types] Get Info Tipos OK';
export class DoGetInfoOfficesTypesOK implements Action {
    type = DO_GET_INFO_OFFICES_TYPES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_OFFICES_TYPES_KO = '[Offices Types] Get Info Tipos KO';
export class DoGetInfoOfficesTypesKO implements Action {
    type = DO_GET_INFO_OFFICES_TYPES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Offices Types] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoOfficesTypes | DoGetInfoOfficesTypesOK | DoGetInfoOfficesTypesKO 
    | DoSetSelectedFilters
    ;
