import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as citiesActions from './cities.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { CitiesService } from '../../shared/services/cities.service';
import { DO_GET_INFO_FILTERS, DO_GET_INFO_CITIES } from './cities.actions';





@Injectable()
export class CitiesEffects {

    constructor(
        private citiesService: CitiesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoCities$: Observable<Action> = this.actions$.pipe(
        ofType(citiesActions.DO_GET_INFO_CITIES),
        switchMap((action: citiesActions.DoGetInfoCities) => {
            return this.citiesService.get(action).pipe(
                map( response => {
                    response.list = this.citiesService.transformListFromApi(response.list);
                    return new citiesActions.DoGetInfoCitiesOK(response);
                }),
                catchError(error => of(new citiesActions.DoGetInfoCitiesKO(error)))
            );
        }));

    @Effect()
    DoGetInfoCitiesFilters$: Observable<Action> = this.actions$.pipe(
        ofType(citiesActions.DO_GET_INFO_FILTERS),
        switchMap((action: citiesActions.DoGetInfoCitiesFilters) => {
            return this.citiesService.getFilters().pipe(
                map( response => new citiesActions.DoGetInfoCitiesFiltersOK(response)),
                catchError(error => of(new citiesActions.DoGetInfoCitiesFiltersKO(error)))
            );
        }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(citiesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: citiesActions.DoSetSelectedFilters) => {
            return this.citiesService.get(action).pipe(
                map( response => new citiesActions.DoGetInfoCitiesOK(response)),
                catchError(error => of(new citiesActions.DoGetInfoCitiesKO(error)))
            );
        }));
}
