// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    // Meridiano
    // apiUrl: 'http://localhost:3002',
    // imageCDN: 'http://localhost:3002/resources',
    // web: 'http://localhost:4281',
    // apiUrl: 'https://grupoasv-meridiano-cms-api.servidoralfa.com',
    // imageCDN: 'https://grupoasv-meridiano-api.servidoralfa.com',
    // web: 'https://grupoasv-meridiano-frontend.servidoralfa.com',
    // webCode: 'meridiano',
    // apiUrl: 'https://grupoasv-meridiano-cms-api.servidorbeta.com',
    // imageCDN: 'https://grupoasv-meridiano-api.servidorbeta.com',
    // web: 'https://grupoasv-meridiano-frontend.servidorbeta.com',
    // webCode: 'meridiano',
    // apiUrl: 'https://cms-api.segurosmeridiano.com',
    // imageCDN: 'https://cdn.segurosmeridiano.com',
    // web: 'https://www.segurosmeridiano.com',
    // webCode: 'meridiano',

    // Servicios funerarios
    // apiUrl: 'http://localhost:3002',
    // imageCDN: 'http://localhost:3002/resources',
    // web: 'http://localhost:4281',
    // apiUrl: 'https://grupoasv-serviciosfunerarios-cms-api.servidoralfa.com',
    // imageCDN: 'https://grupoasv-serviciosfunerarios-api.servidoralfa.com',
    // web: 'https://grupoasv-serviciosfunerarios-frontend.servidoralfa.com',
    // webCode: 'servicios-funerarios',
    // apiUrl: 'https://grupoasv-serviciosfunerarios-cms-api.servidorbeta.com',
    // imageCDN: 'https://grupoasv-serviciosfunerarios-api.servidorbeta.com',
    // web: 'https://grupoasv-serviciosfunerarios-frontend.servidorbeta.com',
    // webCode: 'servicios-funerarios',
    // apiUrl: 'https://cms-api.grupoasvserviciosfunerarios.com',
    // imageCDN: 'https://cdn.grupoasvserviciosfunerarios.com',
    // web: 'https://www.grupoasvserviciosfunerarios.com',
    // webCode: 'servicios-funerarios'

    // Corporativa
    // apiUrl: 'http://localhost:3002',
    // imageCDN: 'http://localhost:3002/resources',
    // web: 'http://localhost:4200',
    // apiUrl: 'http://localhost:3850',
    // imageCDN: 'http://localhost:3850',
    // apiUrl: 'https://grupoasv-corporate-cms-api.servidorbeta.com',
    // imageCDN: 'https://grupoasv-corporate-api.servidorbeta.com',
    // web: 'https://grupoasv-corporate-frontend.servidorbeta.com',
    // webCode: 'corporate',
    // apiUrl: 'https://cms-api.grupoasv.com',
    // imageCDN: 'https://cdn.grupoasv.com',
    // web: 'https://www.grupoasv.com',
    // webCode: 'corporate'
    

    // Transporte sanitario
    // apiUrl: 'http://localhost:3900',
    // imageCDN: 'http://localhost:3900/resources/',
    // apiUrl: 'https://grupoasv-transportesanitario-cms-api.servidoralfa.com',
    // imageCDN: 'https://grupoasv-transportesanitario-api.servidoralfa.com',
    // web: 'https://grupoasv-transportesanitario-frontend.servidoralfa.com',
    // webCode: 'transporte-sanitario',
    // apiUrl: 'https://grupoasv-transportesanitario-cms-api.servidorbeta.com',
    // imageCDN: 'https://grupoasv-transportesanitario-api.servidorbeta.com',
    // web: 'https://grupoasv-transportesanitario-frontend.servidorbeta.com',
    // webCode: 'transporte-sanitario',
    apiUrl: 'https://cms-api.ambulanciasayuda.com',
    imageCDN: 'https://cdn.ambulanciasayuda.com',
    web: 'https://www.ambulanciasayuda.com',
    webCode: 'transporte-sanitario'

    // Limbo
    // apiUrl: 'http://localhost:4000',
    // imageCDN: 'http://localhost:4000/resources/',
    // apiUrl: 'https://grupoasv-limbo-cms-api.servidoralfa.com',
    // imageCDN: 'https://grupoasv-limbo-api.servidoralfa.com',
    // web: 'https://grupoasv-limbo-frontend.servidoralfa.com',
    // webCode: 'limbo',
    // apiUrl: 'https://grupoasv-limbo-cms-api.servidorbeta.com',
    // imageCDN: 'https://grupoasv-limbo-api.servidorbeta.com',
    // web: 'https://grupoasv-limbo-frontend.servidorbeta.com',
    // webCode: 'limbo',
    // apiUrl: 'https://cms-api.limboeurope.com',
    // imageCDN: 'https://cdn.limboeurope.com',
    // web: 'https://www.limboeurope.com',
    // webCode: 'limbo',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.