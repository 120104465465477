import { Action } from '@ngrx/store';

// BLOG
export const DO_GET_INFO_VIDEOS_CATEGORIES = '[Videos Categories] Get Info Categories';
export class DoGetInfoVideosCategories implements Action {
    type = DO_GET_INFO_VIDEOS_CATEGORIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_VIDEOS_CATEGORIES_OK = '[Videos Categories] Get Info Categories OK';
export class DoGetInfoVideosCategoriesOK implements Action {
    type = DO_GET_INFO_VIDEOS_CATEGORIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_VIDEOS_CATEGORIES_KO = '[Videos Categories] Get Info Categories KO';
export class DoGetInfoVideosCategoriesKO implements Action {
    type = DO_GET_INFO_VIDEOS_CATEGORIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Videos Categories] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoVideosCategories | DoGetInfoVideosCategoriesOK | DoGetInfoVideosCategoriesKO 
    | DoSetSelectedFilters
    ;
