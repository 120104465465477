import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LocaleUtils } from '@app/shared/helpers/locale-utils';
import { TextI18nPipe } from '@app/shared/pipes/text-i18n.pipe';
import { I18nService } from '@app/shared/services/18n.service';
import { CategoryRelationService } from '@app/shared/services/category-relation.service';
import { first } from 'rxjs/operators';
import { lang } from 'moment';

@Component({
    selector: 'app-field-category-relation',
    templateUrl: './category-relation.component.html',
    providers: [TextI18nPipe]
})
export class CategoryRelationComponent implements OnChanges {

    @Input() dataLoaded: boolean;
    @Input() data: any;
    @Input() lang: any;
    @Input() field: any;
    @Input() submitted;
    @Output() sendCategoryRelation = new EventEmitter<any>();

    value: string;
    values = [];
    configurations: any;
    texts: any;
    table: string;

    categories: Array<any>;
    categoriesSelect: Array<any>;
    newCategory = {
        name: '',
        error: false,
        _id: ''
    };


    constructor(
        private i18nService: I18nService,
        private localeUtils: LocaleUtils,
        private textI18n: TextI18nPipe,
        private categoryRelationService: CategoryRelationService
    ) {
        this.value = null;
        this.categories = [];
        this.categoriesSelect = [];
    }


    initValues() {
        this.configurations = this.field.configurations;
        this.texts = this.configurations.texts ? this.configurations.texts : null;
        this.table = this.configurations.table;

        this.getValues();
    }

    setInitValues() {
        if (this.data) {
            if (this.configurations.multiple) {
                this.values = this.data;
            } else {
                this.value = this.data;
            }
        }
    }

    updateSelectInit() {
        let index;

        if (this.values) {
            this.values.forEach(element => {
                index = this.getIndexCategoryById(element);
                if (index > -1) {
                    this.categoriesSelect.splice(index, 1);
                }
            });
        }
        
    }

    getValues() {
        this.categoryRelationService.get(this.table).pipe(
            first()).subscribe(data => {
                let cat;
                this.categories = [];
                data.list.map(itemCategory => {
                    

                    cat = { _id: itemCategory[this.configurations.fieldToSave], name: '' };
                    if (this.configurations.hasLanguages) {
                        cat.name = itemCategory[this.configurations.mainField] ? itemCategory[this.configurations.mainField][this.lang.locale] : '';
                    } else {
                        cat.name = itemCategory[this.configurations.mainField] ? itemCategory[this.configurations.mainField] : '';
                    }

                    this.categories.push(cat);
                    this.categoriesSelect.push(cat);

                    this.setInitValues();
                    this.updateSelectInit();
                });
            },
                error => {
                    console.log('ERROR PETICION', error);
                }
            );
    }


    setValue() {
        const dataSend = this.configurations.multiple ? this.values : this.value;

        this.sendCategoryRelation.emit({ item: this.field, values: dataSend});
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataLoaded && this.dataLoaded) {
            this.initValues();
        }
    }

    getNameCategoryByValue(value) {
        const cat = this.categories.filter(x => x._id === value)[0];
        return cat.name;
    }
    getCategoryById(value) {
        const cat = this.categories.filter(x => x._id === value)[0];
        return cat;
    }

    removeCategory(ev, index) {
        const item = this.getCategoryById(this.values[index]);
        this.categoriesSelect.push(item);
        this.orderCategories();
        this.values.splice(index, 1);

        this.resetCategory();

        this.setValue();
    }

    addCategory(ev) {
        this.newCategory.error = !this.newCategory._id;

        if (this.newCategory.error) {
            return;
        }

        this.values.push(this.newCategory._id);

        const index = this.getIndexCategoryById(this.newCategory._id);
        if (index > -1) {
            this.categoriesSelect.splice(index, 1);
        }
        

        this.resetCategory();

        this.setValue();
    }

    resetCategory() {
        this.newCategory._id = '';
        this.newCategory.error = false;
        this.newCategory.name = '';
    }

    getIndexCategoryById(itemId) {
        let find = false;
        let index = 0;
        this.categoriesSelect.forEach(element => {
            if (element._id === itemId) {
                find = true;
            }

            if (!find) {
                index++;
            }
        });


        return find ? index : -1;
    }

    orderCategories() {
        this.categoriesSelect.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
    }

    drop() {
        
    }

}
