import { Action } from '@ngrx/store';

// GALLERIES
export const DO_GET_INFO_GALLERIES = '[Galleries] Get Info Galleries';
export class DoGetInfoGalleries implements Action {
    type = DO_GET_INFO_GALLERIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_GALLERIES_OK = '[Galleries] Get Info Galleries OK';
export class DoGetInfoGalleriesOK implements Action {
    type = DO_GET_INFO_GALLERIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_GALLERIES_KO = '[Galleries] Get Info Galleries KO';
export class DoGetInfoGalleriesKO implements Action {
    type = DO_GET_INFO_GALLERIES_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Galleries] Get Info Filters';
export class DoGetInfoGalleriesFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Galleries] Get Info Filters OK';
export class DoGetInfoGalleriesFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Galleries] Get Info Filters KO';
export class DoGetInfoGalleriesFiltersKO implements Action {
    type = DO_GET_INFO_GALLERIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Galleries] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoGalleries | DoGetInfoGalleriesOK | DoGetInfoGalleriesKO |
    DoGetInfoGalleriesFilters | DoGetInfoGalleriesFiltersOK | DoGetInfoGalleriesFiltersKO 
    | DoSetSelectedFilters
    ;
