import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '../../services/routes.service';
import { Pagination } from '../../models/pagination';

import { Store } from '@ngrx/store';
import { AppState } from '../../../store/index';
import { Observable } from 'rxjs';



@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
})
export class PaginatorComponent implements OnInit {

    @Input() itemsShow: number;
    @Input() forceSection: string;

    @Output() currentChange = new EventEmitter();

    currentSection$: Observable<string>;
    currentSection: string;

    paginator$: Observable<Pagination>;
    paginator: Pagination;

    itemPaginators: Array<any>;

    constructor(
        private store: Store<AppState>
    ) {
        this.paginator = null;
    }

    initCurrentSection() {
        this.currentSection$ = this.store.select(state => state.layout.currentSection);
        this.currentSection$.subscribe(res => {
            this.currentSection = res;

            this.setCurrentPaginatorObserve();
        });
    }

    setCurrentPaginatorObserve() {

        if (this.forceSection) {
            switch (this.forceSection) {
                case 'modules': this.paginator$ = this.store.select(state => state.modules.paginator);
                    break;

                default: this.paginator$ = null;
                    break;
            }

        } else {
            switch (this.currentSection) {

                case 'pages': this.paginator$ = this.store.select(state => state.pages.paginator);
                    break;

                case 'blog': this.paginator$ = this.store.select(state => state.blog.paginator);
                    break;

                case 'blog-categories': this.paginator$ = this.store.select(state => state.blogCategories.paginator);
                    break;

                case 'blogSecond': this.paginator$ = this.store.select(state => state.blogSecond.paginator);
                    break;

                case 'blogSecond-categories': this.paginator$ = this.store.select(state => state.blogSecondCategories.paginator);
                    break;

                case 'leads': this.paginator$ = this.store.select(state => state.leads.paginator);
                    break;
                case 'leads-types': this.paginator$ = this.store.select(state => state.leadsTypes.paginator);
                    break;

                case 'testimonials': this.paginator$ = this.store.select(state => state.testimonials.paginator);
                    break;

                case 'glossaries': this.paginator$ = this.store.select(state => state.glossaries.paginator);
                    break;

                case 'catalogs': this.paginator$ = this.store.select(state => state.catalogs.paginator);
                    break;

                case 'faqs': this.paginator$ = this.store.select(state => state.faqs.paginator);
                    break;

                case 'faqs-categories': this.paginator$ = this.store.select(state => state.faqsCategories.paginator);
                    break;

                case 'offices': this.paginator$ = this.store.select(state => state.offices.paginator);
                    break;

                case 'offices-types': this.paginator$ = this.store.select(state => state.officesTypes.paginator);
                    break;

                case 'modulesTypes': this.paginator$ = this.store.select(state => state.modulesTypes.paginator);
                    break;

                case 'modules': this.paginator$ = this.store.select(state => state.modules.paginator);
                    break;

                case 'obituaries': this.paginator$ = this.store.select(state => state.obituaries.paginator);
                    break;

                case 'events': this.paginator$ = this.store.select(state => state.events.paginator);
                    break;

                case 'admins': this.paginator$ = this.store.select(state => state.admins.paginator);
                    break;

                default: this.paginator$ = null;
                    break;
            }
        }

        if (this.paginator$) {
            this.paginator$.subscribe(res => {
                this.paginator = res;

                this.calculatePagination();
            });
        }
    }

    ngOnInit() {
        this.initCurrentSection();
    }

    calculatePagination() {
        if (!this.paginator) {
            return;
        }

        const current: number = this.paginator.currentPage || 1;
        const last: number = this.paginator.lastPage;
        const delta: number = this.itemsShow || 2;
        const left: number = current - delta;
        const right: number = current + delta + 1;
        const range: Array<any> = [];

        let i: number;
        let l: number;

        this.itemPaginators = [];
        for (i = 1; i <= last; i++) {
            if (i === 1 || i === last || i >= left && i < right) {
                range.push(i);
            }
        }

        for (i of range) {
            if (l) {
                if (i - l === 2) {
                    this.itemPaginators.push(l + 1);
                } else if (i - l !== 1) {
                    this.itemPaginators.push('...');
                }
            }
            this.itemPaginators.push(i);
            l = i;
        }
    }

    disabledClick(ev) {
        ev.preventDefault();
    }


    clickItemPage(ev, page) {
        ev.preventDefault();

        if (isNaN(page)) {
            return;
        }

        this.paginator.currentPage = page;
        this.updateCurrent();
    }

    clickPrev(ev) {
        ev.preventDefault();

        this.paginator.currentPage--;
        this.updateCurrent();
    }

    clickNext(ev) {
        ev.preventDefault();

        this.paginator.currentPage++;
        this.updateCurrent();
    }

    updateCurrent() {
        this.calculatePagination();
        this.currentChange.emit({ page: this.paginator.currentPage });
    }

}
