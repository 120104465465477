export class ConfigurationsModule {
    name: string;
    classes: any;
    // classesInside: any;
    title_tag: string;
    mode: string;
    mode_bg: string;
    mode_sloped: string;
    id_anchor: string;
    mode_bg_color: string;
    mode_bg_decoration: string;

    constructor(obj: Object) {
        Object.assign(this, obj);
    }
}

export class ConfigurationsModuleHelper {

    static create(): ConfigurationsModule {
        return {
            name: null,
            classes: {
                utility: null,
                utility_internal: null
            },
            // classesInside: null,
            title_tag: null,
            mode: null,
            mode_sloped: null,
            mode_bg: null,
            id_anchor: null,
            mode_bg_color: null,
            mode_bg_decoration: null
        };
    }

    static newFromJSON(json: string): ConfigurationsModule {
        let configurations: ConfigurationsModule = null;
        try {
            configurations = ConfigurationsModuleHelper.fromObject(JSON.parse(json));
        } catch (e) {
            console.error('Invalid configurations JSON.', e);
        }
        return configurations;
    }

    static fromObject(obj: Object = {}): ConfigurationsModule {
        const configurations = ConfigurationsModuleHelper.create();
        return Object.assign(configurations, obj);
    }
}
