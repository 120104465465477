import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomRequest } from '../helpers/custom-request';
import { Catalog } from '../models/catalog';


@Injectable({ providedIn: 'root' })
export class CatalogsService {
    constructor(
        private request: CustomRequest
    ) { }

    getFilters() {
        return this.request.get(`${environment.apiUrl}/catalog`);
        // return this.request.get(`${environment.apiUrl}/glossaries`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/catalog`, data.payload);
        // return this.request.get(`${environment.apiUrl}/glossaries`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/catalog/` + id);
        // return this.request.get(`${environment.apiUrl}/glossaries/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/catalog/` + data._id, data);
        // return this.request.post(`${environment.apiUrl}/glossaries/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/catalog/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/catalog/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/catalog/` + id + `/enabled/` + status, null);
        // return this.request.post(`${environment.apiUrl}/glossaries/` + id + `/enabled/` + status, null);
    }

    order(data) {
        return this.request.post(`${environment.apiUrl}/catalog/order`, data);
    }

    transformDataFromApi(data: any) {
        const catalog = new Catalog();

        catalog._id = data._id;
        catalog.enabled = data.enabled;
        catalog.name = data.name;
        catalog.file = data.file;
        catalog.image = data.image;

        catalog.created_at = data.created_at;
        catalog.updated_at = data.updated_at;


        catalog.sites = data.sites;

        return catalog;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}