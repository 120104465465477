import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as blogCategoriesActions from './blog-categories.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { BlogCategoriesService } from '../../shared/services/blog-categories.service';



@Injectable()
export class BlogCategoriesEffects {

    constructor(
        private blogCategoriesService: BlogCategoriesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoBlogCategories$: Observable<Action> = this.actions$.pipe(
        ofType(blogCategoriesActions.DO_GET_INFO_BLOG_CATEGORIES),
        switchMap((action: blogCategoriesActions.DoGetInfoBlogCategories) => {
            return this.blogCategoriesService.get(action).pipe(
                map( response => new blogCategoriesActions.DoGetInfoBlogCategoriesOK(response)),
                catchError(error => of(new blogCategoriesActions.DoGetInfoBlogCategoriesKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedFiltersCategories$: Observable<Action> = this.actions$.pipe(
        ofType(blogCategoriesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: blogCategoriesActions.DoSetSelectedFilters) => {
            return this.blogCategoriesService.get(action).pipe(
                map( response => new blogCategoriesActions.DoGetInfoBlogCategoriesOK(response)),
                catchError(error => of(new blogCategoriesActions.DoGetInfoBlogCategoriesKO(error)))
            );
        }));
}
