import { Action } from '@ngrx/store';

// Publications
export const DO_GET_INFO_PUBLICATIONS = '[Publications] Get Info Publications';
export class DoGetInfoPublications implements Action {
    type = DO_GET_INFO_PUBLICATIONS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_PUBLICATIONS_OK = '[Publications] Get Info Publications OK';
export class DoGetInfoPublicationsOK implements Action {
    type = DO_GET_INFO_PUBLICATIONS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_PUBLICATIONS_KO = '[Publications] Get Info Publications KO';
export class DoGetInfoPublicationsKO implements Action {
    type = DO_GET_INFO_PUBLICATIONS_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Publications] Get Info Filters';
export class DoGetInfoPublicationsFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Publications] Get Info Filters OK';
export class DoGetInfoPublicationsFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Publications] Get Info Filters KO';
export class DoGetInfoPublicationsFiltersKO implements Action {
    type = DO_GET_INFO_PUBLICATIONS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Publications] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoPublications | DoGetInfoPublicationsOK | DoGetInfoPublicationsKO |
    DoGetInfoPublicationsFilters | DoGetInfoPublicationsFiltersOK | DoGetInfoPublicationsFiltersKO 
    | DoSetSelectedFilters
    ;
