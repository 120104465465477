export class Testimonial {
    _id: string;
    enabled: boolean;
    checked: boolean;
    user_location: any;
    user_name: any;
    user_age: any;
    message: any;
    code: string;
    order: number;
    created_at: string;
    updated_at: string;
    sites: Array<string>;
}