import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Lead } from '../models/lead';

import { DatePipe } from '@angular/common';
import { LeadsTypesService } from './leads-types.service';
import { CustomRequest } from '../helpers/custom-request';


@Injectable({ providedIn: 'root' })
export class LeadsService {
    constructor(
        private http: HttpClient,
        private leadsTypesService: LeadsTypesService,
        private request: CustomRequest
    ) { }

    getFilters() {
        return this.request.get(`${environment.apiUrl}/form-data-type/`);
    }

    get(data) {
        let idType = '';
        if (data.payload.filters.type) {
            idType = data.payload.filters.type;
        } else if (data.payload.types.length) {
            idType = data.payload.types[0]._id;
        }

        return this.request.get(`${environment.apiUrl}/form-data/type/` + idType, data.payload);
    }

    getFiltersParamsToObject(data: any) {
        const paramFilters: any = {};
        const params = new HttpParams();
        if (data.filters) {
            if (data.filters.start_at) {
                const datePipe = new DatePipe('en-US');
                const dateFormat = datePipe.transform(data.filters.start_at, 'yyy-MM-dd');
                paramFilters.start_at = dateFormat;
            }

            if (data.filters.end_at) {
                const datePipe = new DatePipe('en-US');
                const dateFormat = datePipe.transform(data.filters.end_at, 'yyy-MM-dd');
                paramFilters.end_at = dateFormat;
            }
        }

        if (data.paginator) {
            paramFilters.page = data.paginator.currentPage;
            paramFilters.limit = data.paginator.perPage;
        }

        return paramFilters;
    }

    getCsv(data) {
        const idType = data.filters.type;

        const httpParams = new HttpParams({
            fromObject : this.getFiltersParamsToObject(data)
        });
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        const options = { params: httpParams, headers, responseType: 'text' as 'json' };

        return this.http.get<any>(`${environment.apiUrl}/form-data/type/` + idType + `/csv`, options);
    }

    getExcel(data) {
        const idType = data.filters.type;

        const httpParams = new HttpParams({
            fromObject : this.getFiltersParamsToObject(data)
        });
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        const options = { params: httpParams, headers, responseType: 'blob' as 'json' };
        return this.http.get<any>(`${environment.apiUrl}/form-data/type/` + idType + `/xls`, options);
    }

    transformDataFromApi(dataLead: any) {
        const lead = new Lead();

        lead._id = dataLead._id;
        lead.data = dataLead.data;
        lead.email = dataLead.email;
        lead.message = dataLead.message;
        lead.name = dataLead.name;
        lead.phone = dataLead.phone;
        lead.referer = dataLead.referer;
        lead.sent = dataLead.sent;
        lead.type = dataLead.type ? this.leadsTypesService.transformDataFromApi(dataLead.type) : null;
        lead.user_agent = dataLead.user_agent;
        lead.ip = dataLead.ip;
        lead.location = dataLead.location;

        lead.accepted_terms_at = dataLead.created_at;
        lead.created_at = dataLead.created_at;
        lead.updated_at = dataLead.updated_at;
        // lead.accepted_terms_at = dataLead.created_at.slice(0, -1);
        // lead.created_at = dataLead.created_at.slice(0, -1);
        // lead.updated_at = dataLead.updated_at.slice(0, -1);

        return lead;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}