import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomRequest } from '../helpers/custom-request';
import { Module } from '../models/module';
import { ModulesTypesService } from './modules-types.service';


@Injectable({ providedIn: 'root' })
export class ModulesService {
    constructor(
        private request: CustomRequest,
        private modulesTypesService: ModulesTypesService
    ) { }

    getFilters() {
        const data = { paginator: { perPage: 500, currentPage: 1}, filters: {} };
        return this.request.get(`${environment.apiUrl}/module-type`, data);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/module`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/module/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/module/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/module/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/module/` + id);
    }

    order(data) {
        return this.request.post(`${environment.apiUrl}/module/order`, data);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/module/` + id + `/enabled/` + status, null);
    }

    transformDataFromApi(data: any) {
        const module = new Module();

        module._id = data._id;
        module.enabled = data.enabled;

        module.classes = data.classes;
        module.name = data.name;
        module.title = data.title;
        module.subtitle = data.subtitle;
        module.antetile = data.user_location;
        module.description = data.description;
        module.ctas = data.ctas;
        module.items = data.items;
        module.image = data.image;
        module.image_alt = data.image_alt;
        module.background = data.background;
        module.mode = data.mode;
        module.mode_bg_color = data.mode_bg_color;
        module.mode_bg_decoration = data.mode_bg_decoration;
        module.mode_sloped = data.mode_sloped;
        module.config = data.config;
        module.type = this.modulesTypesService.transformDataFromApi(data.type);
        module.icon = data.icon;
        module.title_tag = data.title_tag;

        module.created_at = data.created_at;
        module.updated_at = data.updated_at;

        return module;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}