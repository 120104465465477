// import { BlogCategory } from './blog-category';
import { Page } from './page';

export class PublicationPost {
    _id: string;
    title: any;
    subtitle: any;
    image: any;
    image_name: any;
    image_highlight: any;
    ctas: any;
    order: any;
    
    sites: Array<string>;
    enabled: boolean;
    date_at: string;

    created_at: string; // no se usa    
    updated_at: string; // no se usa
}