import { PolicyCategory } from './policy-category';

export class Policy {
    _id: string;
    title: any;
    description: any;
    category: any;
    enabled: boolean;
    order: number;
    created_at: string;
    updated_at: string;
    // sites: Array<string>;
    
}