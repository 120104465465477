import { LeadType } from './lead-type';

export class Lead {
    _id: string;

    data: any;

    email: string;
    message: any;
    name: any;
    phone: string;

    referer: string;
    sent: any;
    type: LeadType;

    user_agent: string;
    ip: string;

    location: any;

    accepted_terms_at: string;
    created_at: string;
    updated_at: string;
    
}