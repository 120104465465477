import { Action } from '@ngrx/store';

// CATALOG
export const DO_GET_INFO_CATALOG_CATEGORIES = '[Catalog Categories] Get Info Categories';
export class DoGetInfoCatalogCategories implements Action {
    type = DO_GET_INFO_CATALOG_CATEGORIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_CATALOG_CATEGORIES_OK = '[Catalog Categories] Get Info Categories OK';
export class DoGetInfoCatalogCategoriesOK implements Action {
    type = DO_GET_INFO_CATALOG_CATEGORIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_CATALOG_CATEGORIES_KO = '[Catalog Categories] Get Info Categories KO';
export class DoGetInfoCatalogCategoriesKO implements Action {
    type = DO_GET_INFO_CATALOG_CATEGORIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Catalog Categories] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoCatalogCategories | DoGetInfoCatalogCategoriesOK | DoGetInfoCatalogCategoriesKO 
    | DoSetSelectedFilters
    ;
