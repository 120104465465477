import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as typesActions from './pages-types.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { PagesTypesService } from '../../shared/services/pages-types.service';



@Injectable()
export class PagesTypesEffects {

    constructor(
        private pagesTypesService: PagesTypesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoTypes$: Observable<Action> = this.actions$.pipe(
        ofType(typesActions.DO_GET_INFO_TYPES),
        switchMap((action: typesActions.DoGetInfoTypes) => {
            return this.pagesTypesService.get(action).pipe(
                map( response => new typesActions.DoGetInfoTypesOK(response)),
                catchError(error => of(new typesActions.DoGetInfoTypesKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedFiltersCategories$: Observable<Action> = this.actions$.pipe(
        ofType(typesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: typesActions.DoSetSelectedFilters) => {
            return this.pagesTypesService.get(action).pipe(
                map( response => new typesActions.DoGetInfoTypesOK(response)),
                catchError(error => of(new typesActions.DoGetInfoTypesKO(error)))
            );
        }));
}
