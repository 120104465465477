import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as pressCategoriesActions from './press-categories.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { PressCategoriesService } from '../../shared/services/press-categories.service';



@Injectable()
export class PressCategoriesEffects {

    constructor(
        private pressCategoriesService: PressCategoriesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoPressCategories$: Observable<Action> = this.actions$.pipe(
        ofType(pressCategoriesActions.DO_GET_INFO_PRESS_CATEGORIES),
        switchMap((action: pressCategoriesActions.DoGetInfoPressCategories) => {
            return this.pressCategoriesService.get(action).pipe(
                map( response => new pressCategoriesActions.DoGetInfoPressCategoriesOK(response)),
                catchError(error => of(new pressCategoriesActions.DoGetInfoPressCategoriesKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedFiltersCategories$: Observable<Action> = this.actions$.pipe(
        ofType(pressCategoriesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: pressCategoriesActions.DoSetSelectedFilters) => {
            return this.pressCategoriesService.get(action).pipe(
                map( response => new pressCategoriesActions.DoGetInfoPressCategoriesOK(response)),
                catchError(error => of(new pressCategoriesActions.DoGetInfoPressCategoriesKO(error)))
            );
        }));
}
