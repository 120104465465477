import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ItemsModule, ItemsModuleHelper } from '@app/shared/models/itemsModule';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { AppState } from '../../../../store/index';
import { Store } from '@ngrx/store';
import * as AsideItemActions from '@app/store/aside-item/aside-item.actions';
import * as AsideActions from '@app/store/aside/aside.actions';
import * as LayoutActions from '@app/store/layout/layout.actions';
import { Subscription } from 'rxjs';
import { ModulesUtils } from '../../../helpers/modules-utils';
import { I18nService } from '../../../services/18n.service';
import { environment } from '../../../../../environments/environment';
import { I18n } from '../../../models/i18n';
import { AppConfigService } from '../../../services/app-config.service';



@Component({
    selector: 'app-items-info',
    templateUrl: './items-info.component.html',
    providers: []
})
export class ItemsInfoComponent implements OnChanges, OnDestroy {

    @Input() currentLanguage: I18n;
    @Input() dataLoaded: boolean;
    @Input() data: any;
    @Input() type: string;
    @Output() sendItems = new EventEmitter<any>();

    moduleConfig: any;
    configItems: any;
    configTexts: any;

    items = [];

    newItem = {
        title: '',
        errorTitle: false,
        description: '',
        errorDescription: false
    };
    itemsVisibility = [];
    formItemVisibility = false;
    editingItem = null;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private i18nService: I18nService,
        private store: Store<AppState>,
        public appConfig: AppConfigService
    ) {
        this.initSuscriptions();

        // const currentLanguage = this.i18nService.getCurrentLanguage();
        // this.Editor.defaultConfig = ModulesUtils.chkeditorCommonConfig(currentLanguage.iso);
    }

    initSuscriptions() {

        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.result).subscribe(res => {
            if (res) {
                this.saveItem(res);
            }
        }));
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    setConfigs() {
        this.moduleConfig = this.appConfig.webConfig.modulesData[this.type];
        this.configItems = this.moduleConfig.items;
        this.configTexts = this.configItems.texts;

        // console.log('moduleConfig', this.moduleConfig);
    }

    initItems() {
        this.items = this.data;
        this.setConfigs();
    }

    setValue() {
        this.sendItems.emit(this.items);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataLoaded && this.dataLoaded) {
            this.initItems();
        }
    }

    drop(event: CdkDragDrop<object[]>) {
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
        this.setValue();
    }

    removeItem(ev, index: number) {
        this.items.splice(index, 1);
        this.itemsVisibility.splice(index, 1);
        this.setValue();
    }

    addItem(ev) {
        this.editingItem = null;

        const payload = { mode: this.type, data: null };
        this.store.dispatch(new AsideItemActions.SetInfo(payload));
        this.store.dispatch(new AsideActions.ChangeHide(true));
        this.store.dispatch(new LayoutActions.OpenAsideItems());
    }

    editItem(ev, i: number) {
        this.editingItem = i;
        const itemToEdit = this.items[i];

        const payload = { mode: this.type, data: itemToEdit };
        this.store.dispatch(new AsideActions.ChangeHide(true));
        this.store.dispatch(new AsideItemActions.SetInfo(payload));
        this.store.dispatch(new LayoutActions.OpenAsideItems());
    }

    saveItem(item) {
        if (item) {
            if (this.editingItem !== null) {
                this.items[this.editingItem] = item;
            } else {
                if (!(this.items instanceof Array)) {
                    this.items = [];
                }
                this.items.push(item);
            }
        }

        this.setValue();


        this.store.dispatch(new AsideItemActions.ResetInfo());
        this.store.dispatch(new AsideActions.ChangeHide(false));
        this.store.dispatch(new LayoutActions.CloseAsideItems());
    }

    getFieldInConfig(field) {
        let find = null;
        this.configItems.fields.forEach(element => {

            element.forEach(item => {
                if (item.field === field) {
                    find = item;
                }
            });
        });

        return find;
    }

    getTitleToList(itemData, type) {
        if (!this.dataLoaded) {
            return;
        }

        // const field = this.getFieldInConfig(this.configItems.configurations.list_field_main);
        const field = this.configItems.configurations[type];

        if (itemData[field] && typeof itemData[field] !== 'string') {
            const value = itemData[field][this.currentLanguage.locale];
            return value ? value : '';
        } else {
            return itemData[field] ? itemData[field] : '';
        }
    }
}
