import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '../../services/routes.service';
import { SitesService } from '../../services/sites.service';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sites-form-item',
  templateUrl: './sites-form-item.component.html',
})
export class SitesFormItemComponent implements OnInit, OnChanges, OnDestroy {

  @Input() currentSites;
  @Output() sendSites = new EventEmitter<any>();

  hasSites: boolean;
  sites: Array<any>;

  currentSite = null;
  currentSiteCode = null;

  loadSites = false;

  private suscriptionsToManage$: Subscription[] = [];

  constructor(
    private sitesService: SitesService
  ) {

  }

  ngOnInit() {
    this.initSuscriptions();
  }

  ngOnDestroy() {
    this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
  }

  initSuscriptions() {
    this.suscriptionsToManage$.push(this.sitesService.sitesData$.subscribe(
      sites => {
        // console.log('sites init...', sites);
        this.sites = sites;
        this.loadSites = true;
        this.hasSites = this.sites.length > 1;

        this.initSites();
      }
    ));

    this.suscriptionsToManage$.push(this.sitesService.currentSite$.subscribe(
      site => {
          this.currentSite = site;
          this.currentSiteCode = site.code;
      }
    ));
  }

  initSites() {
    this.sites.forEach(element => {
      element.checked = false;
    });

    if (this.currentSites && this.currentSites.length) {
      this.fillSites();
    }
  }

  fillSites() {

    this.sites.forEach(element => {
      element.checked = this.currentSites.indexOf(element._id) > -1;
    });
  }

  get selectedOptions() { 
    return this.sites
      .filter(opt => opt.checked)
      .map(opt => opt._id)
  }

  changeSite(site) {
    if (site._id === this.currentSite._id && !site.checked) {
      site.checked = true;
    }
    
    const sites = this.selectedOptions;
    this.sendSites.emit(sites);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentSites && this.loadSites) {
      this.fillSites();
    }
  }

  
}
