import { Office } from './office';
import { City } from './city';

export class Province {
    _id: string;
    name: string;
    code: string;

    events: Array<any>;
    offices: Array<Office>;
    cities: Array<City>;
    postal_codes: Array<any>;

    created_at: string;
    updated_at: string;
}