import { ActionReducer, Action } from '@ngrx/store';
import * as fromActions from './leads.actions';
import { LeadsState } from './leads.state';
import { DO_GET_INFO_LEADS_FILTERS, DO_SET_SELECTED_FILTERS, DO_SET_CURRENT_LEAD } from './leads.actions';


const defaultState: LeadsState = {
    loading: true,
    error: '',
    leads: [],
    ordersby: [],
    paginator: {
        total: 0,
        perPage: 100,
        currentPage: 1,
        lastPage: 0
    },
    filtersTypes: [],
    filtersStatus: [
        { value: 0 , name: 'Inactivo' },
        { value: 1 , name: 'Activo' }
    ],
    // search: '',
    currentFilters: {
        textSearch: '',
        status: -1,
        start_at: '',
        end_at: '',
        type: ''
    },
    currentLead: null,
    refresh: false
};

export type Action = fromActions.All;

export function LeadsReducer(state = defaultState, action: any) {
    switch (action.type) {

        case fromActions.DO_GET_INFO_LEADS:
            // console.log('pidiendo...');
            return {
                ...state,
                loading: true,
                error: '',
                leads: [],
                refresh: false
            };

        case fromActions.DO_GET_INFO_LEADS_OK:
            return {
                ...state,
                loading: false,
                leads: action.payload.list,
                paginator: {
                    total: action.payload.pagination.total,
                    perPage: action.payload.pagination.limit,
                    currentPage: action.payload.pagination.page,
                    lastPage: action.payload.pagination.pages
                },
                currentLead: null
            };

        case fromActions.DO_GET_INFO_LEADS_KO:
            return {
                ...state,
                loading: false,
                error: action.payload,
                leads: [],
                paginator: null,
                currentLead: null,
            };

        case fromActions.DO_GET_INFO_LEADS_FILTERS:
            // console.log('pidiendo...');
            return {
                ...state,
                loading: true,
                error: ''
            };

        case fromActions.DO_GET_INFO_LEADS_FILTERS_OK:
            return {
                ...state,
                // loading: false,
                filtersTypes: action.payload.list

            };

        case fromActions.DO_GET_INFO_LEADS_FILTERS_KO:
            return {
                ...state,
                loading: false,
                error: action.payload,
                // filtersTypes: []
            };

        case fromActions.DO_SET_SELECTED_FILTERS:
            return {
                ...state,
                loading: false,
                currentFilters: action.payload.filters,
                paginator: action.payload.paginator,
                refresh: true
            };

        case fromActions.DO_SET_SELECTED_TYPE_FILTERS:
            return {
                ...state,
                loading: false,
                currentFilters: action.payload.filters
            };

        case fromActions.DO_SET_CURRENT_LEAD:
            return {
                ...state,
                loading: false,
                currentLead: action.payload
            };

        default:
            return state;
    }
}
