import { PageType } from './page-type';
export class Page {
    _id: string;
    code: string;
    modules: any;
    boxes: any;
    enabled: boolean;
    type_header: string;
    type_footer: string;
    hide_menu: boolean;
    deleteable: boolean;
    sites: any;
    urls: any;
    seo: any;
    title: any;
    type: PageType;
    
    menu_fixed_option: any;
    menu_fixed_option_link: any;

    classes: any;

    created_at: string;
    updated_at: string;

    related_id: string;
    related_table: string;
}