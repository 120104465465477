import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ItemsModule, ItemsModuleHelper } from '@app/shared/models/itemsModule';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { AppState } from '@app/store/index';
import { Store } from '@ngrx/store';
import * as AsideItemActions from '@app/store/aside-item/aside-item.actions';
import * as AsideActions from '@app/store/aside/aside.actions';
import * as LayoutActions from '@app/store/layout/layout.actions';
import { Subscription } from 'rxjs';
import { ModulesUtils } from '@app/shared/helpers/modules-utils';
import { I18nService } from '@app/shared/services/18n.service';
import { environment } from '../../../../../../environments/environment';
import { I18n } from '@app/shared/models/i18n';
import { AppConfigService } from '@app/shared/services/app-config.service';



@Component({
    selector: 'app-field-items',
    templateUrl: './field-items.component.html',
    providers: []
})
export class FieldItemsComponent implements OnChanges, OnDestroy {

    @Input() currentLanguage: I18n;
    @Input() dataLoaded: boolean;
    @Input() data: any;
    @Input() type: string;
    @Input() moduleItems: any;
    @Input() hasError: boolean;
    @Input() submitted: boolean;
    @Output() sendItems = new EventEmitter<any>();


    showForm = false;

    configItems: any;
    configTexts: any;

    items = [];

    newItem = {
        title: '',
        errorTitle: false,
        description: '',
        errorDescription: false
    };
    itemsVisibility = [];
    formItemVisibility = false;
    editingItem = null;
    itemToEdit = null;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private i18nService: I18nService,
        private store: Store<AppState>,
        public appConfig: AppConfigService
    ) {
        // const currentLanguage = this.i18nService.getCurrentLanguage();
        // this.Editor.defaultConfig = ModulesUtils.chkeditorCommonConfig(currentLanguage.iso);
    }

    ngOnDestroy() {

    }

    setConfigs() {
        // console.log('1setConfigs this.moduleItems', this.moduleItems);
        this.configItems = this.moduleItems.items ? this.moduleItems.items : null;
        this.configTexts = this.moduleItems.texts ? this.moduleItems.texts : null;
    }

    initItems() {
        this.items = this.data;
        this.setConfigs();
    }

    setValue() {
        this.sendItems.emit(this.items);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataLoaded && this.dataLoaded) {
            this.initItems();
        }
    }

    drop(event: CdkDragDrop<object[]>) {
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
        this.setValue();
    }

    removeItem(ev, index: number) {
        this.items.splice(index, 1);
        this.itemsVisibility.splice(index, 1);
        this.setValue();
    }

    addItem(ev) {
        this.store.dispatch(new AsideItemActions.ChangeHide(true));
        this.store.dispatch(new AsideItemActions.ResetCloseForm());

        this.itemToEdit = null;
        this.showForm = true;
    }

    editItem(ev, i: number) {
        this.store.dispatch(new AsideItemActions.ChangeHide(true));
        this.store.dispatch(new AsideItemActions.ResetCloseForm());

        this.editingItem = i;
        this.itemToEdit = this.items[i];

        this.showForm = true;
    }

    saveItem(item) {
        if (item) {
            if (this.editingItem !== null) {
                this.items[this.editingItem] = item;
            } else {
                if (!(this.items instanceof Array)) {
                    this.items = [];
                }
                this.items.push(item);
            }
        }

        // console.log('SAVE ITEMS...', this.items);
        this.setValue();

        this.store.dispatch(new AsideItemActions.ChangeHide(false));
        this.showForm = false;
    }

    closeForm() {
        this.itemToEdit = null;
        this.editingItem = null;
        this.showForm = false;

        this.store.dispatch(new AsideItemActions.ChangeHide(false));

        const payload = { error: null };
        this.store.dispatch(new AsideItemActions.SetErrorChild(payload));

    }

    getFieldInConfig(field) {
        let find = null;
        this.configItems.fields.forEach(element => {

            element.forEach(item => {
                if (item.field === field) {
                    find = item;
                }
            });
        });

        return find;
    }

    getTitleToList(itemData, type) {
        if (!this.dataLoaded) {
            return;
        }

        // const field = this.getFieldInConfig(this.moduleItems.configurations.list_field_main);
        const field = this.moduleItems.configurations[type];

        if (itemData[field] && typeof itemData[field] !== 'string') {
            const value = itemData[field][this.currentLanguage.locale];
            return value ? value : '';
        } else {
            return itemData[field] ? itemData[field] : '';
        }
    }
}
