import { Action } from '@ngrx/store';

// CITIES
export const DO_GET_INFO_CITIES = '[Cities] Get Info Cities';
export class DoGetInfoCities implements Action {
    type = DO_GET_INFO_CITIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_CITIES_OK = '[Cities] Get Info Cities OK';
export class DoGetInfoCitiesOK implements Action {
    type = DO_GET_INFO_CITIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_CITIES_KO = '[Cities] Get Info Cities KO';
export class DoGetInfoCitiesKO implements Action {
    type = DO_GET_INFO_CITIES_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Cities] Get Info Filters';
export class DoGetInfoCitiesFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Cities] Get Info Filters OK';
export class DoGetInfoCitiesFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Cities] Get Info Filters KO';
export class DoGetInfoCitiesFiltersKO implements Action {
    type = DO_GET_INFO_CITIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Cities] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoCities | DoGetInfoCitiesOK | DoGetInfoCitiesKO |
    DoGetInfoCitiesFilters | DoGetInfoCitiesFiltersOK | DoGetInfoCitiesFiltersKO 
    | DoSetSelectedFilters
    ;
