import { Action } from '@ngrx/store';

// BLOG
export const DO_GET_INFO_LEADS_TYPES = '[Leads Types] Get Info Tipos';
export class DoGetInfoLeadsTypes implements Action {
    type = DO_GET_INFO_LEADS_TYPES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_LEADS_TYPES_OK = '[Leads Types] Get Info Tipos OK';
export class DoGetInfoLeadsTypesOK implements Action {
    type = DO_GET_INFO_LEADS_TYPES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_LEADS_TYPES_KO = '[Leads Types] Get Info Tipos KO';
export class DoGetInfoLeadsTypesKO implements Action {
    type = DO_GET_INFO_LEADS_TYPES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Leads Types] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoLeadsTypes | DoGetInfoLeadsTypesOK | DoGetInfoLeadsTypesKO 
    | DoSetSelectedFilters
    ;
