import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { DatePipe } from '@angular/common';

import * as _ from 'lodash';
import { AppState } from '../../store/index';
import { Store } from '@ngrx/store';
import * as RouterActions from '@app/store/router/router.actions';
import { RoutesService } from '../services/routes.service';



@Injectable({ providedIn: 'root' })
export class UrlHelper {

    constructor(
        private store: Store<AppState>,
        public routesService: RoutesService,
    ) { }

    sendParams(section, currentFilters, paginator, currentOrderList, currentOrderTypeList) {
        // console.log('SEND PARAMS');
        const aux = _.cloneDeep(currentFilters);

        const params: any = {
            page: paginator.currentPage,
            limit: paginator.perPage,
            order: currentOrderList,
            order_type: currentOrderTypeList
        };

        if (aux.status && Number(aux.status) !== -1) {
            params.enabled = aux.status;
        }

        if (aux.checked && Number(aux.checked) !== -1) {
            params.checked = aux.checked;
            
        }

        if (aux.date && aux.date !== '') {
            // const datePipe = new DatePipe('en-US');
            const datePipe = new DatePipe('es-ES');
            const dateFormat = datePipe.transform(aux.date, 'yyy-MM-dd');

            params.start_at = dateFormat;
        }

        if (aux.idsSearch && aux.idsSearch !== '') {
            params.search_ids = aux.idsSearch;
        }

        if (aux.textSearch && aux.textSearch !== '') {
            params.search = aux.textSearch;
        }

        if (aux.type && aux.type !== '') {
            params.type = aux.type;
        }

        if (aux.category && aux.category !== '') {
            params.category = aux.category;
        }

        if (aux.group && aux.group !== '') {
            params.group = aux.group;
        }

        if (aux.global && Number(aux.global) !== -1) {
            params.global = aux.global;
        }

        if (aux.collection && aux.collection !== '') {
            params.collection = aux.collection;
        }

        if (aux.location && aux.location !== '') {
            params.location = aux.location;
        }

        
        this.store.dispatch(
            new RouterActions.Go({
                path: [this.routesService.getRoute(section)],
                query: params
            })
        );
    }
}