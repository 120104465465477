import { Action } from '@ngrx/store';

// EVENTS
export const DO_GET_INFO_EVENTS_CATEGORIES = '[Events Categories] Get Info Categories';
export class DoGetInfoEventsCategories implements Action {
    type = DO_GET_INFO_EVENTS_CATEGORIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_EVENTS_CATEGORIES_OK = '[Events Categories] Get Info Categories OK';
export class DoGetInfoEventsCategoriesOK implements Action {
    type = DO_GET_INFO_EVENTS_CATEGORIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_EVENTS_CATEGORIES_KO = '[Events Categories] Get Info Categories KO';
export class DoGetInfoEventsCategoriesKO implements Action {
    type = DO_GET_INFO_EVENTS_CATEGORIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Events Categories] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoEventsCategories | DoGetInfoEventsCategoriesOK | DoGetInfoEventsCategoriesKO 
    | DoSetSelectedFilters
    ;
