import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomRequest } from '../helpers/custom-request';
import { Province } from '../models/province';

@Injectable({ providedIn: 'root' })
export class ProvincesService {
    constructor(
        private request: CustomRequest

    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/province`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/province/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/province/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/province/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/province/` + id);
    }

    transformDataFromApi(data: any) {
        const province = new Province();

        province._id = data._id;
        province.code = data.code;
        province.name = data.name;

        province.created_at = data.created_at;
        province.updated_at = data.updated_at;

        province.cities = data.cities;
        province.events = data.events;
        province.offices = data.offices;

        return province;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}