import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomRequest } from '../helpers/custom-request';
import { I18n } from '../models';
import { Testimonial } from '../models/testimonial';
import { PagesService } from './pages.service';


@Injectable({ providedIn: 'root' })
export class TestimonialsService {
    constructor(
        private request: CustomRequest,
        private pagesService: PagesService
    ) { }

    getFilters() {
        // return this.request.get(`${environment.apiUrl}/testimonial`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/testimonial`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/testimonial/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/testimonial/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/testimonial/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/testimonial/` + id);
    }

    order(data) {
        return this.request.post(`${environment.apiUrl}/testimonial/order`, data);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/testimonial/` + id + `/enabled/` + status, null);
    }

    exportToJson(data: any) {
        return this.request.post(`${environment.apiUrl}/testimonial/export-json`, data);
    }

    transformDataFromApi(data: any) {
        const testimonial = new Testimonial();

        testimonial.sites = data.sites;
        testimonial._id = data._id;
        testimonial.enabled = data.enabled;
        testimonial.checked = data.checked !== undefined ? data.checked : false;

        testimonial.code = data.code;
        testimonial.user_name = data.user_name;
        testimonial.user_location = data.user_location;
        testimonial.user_age = data.user_age;
        testimonial.message = data.message;
        testimonial.order = data.order;



        testimonial.created_at = data.created_at;
        testimonial.updated_at = data.updated_at;

        return testimonial;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }

    prepareToJsonLangs(dataJson: any, langs: Array<I18n>) {     
        const newJsonData = {
            testimonials: this.pagesService.setNewJsonElements(dataJson, langs)
        };   

        return newJsonData;
    }
}