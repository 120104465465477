import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { CatalogSubcategory } from '../models/catalog-subcategory';
import { CustomRequest } from '../helpers/custom-request';
import { CatalogCategoriesService } from './catalog-categories.service';
import { PagesService } from './pages.service';

@Injectable({ providedIn: 'root' })
export class CatalogSubcategoriesService {
    constructor(
        private request: CustomRequest,
        private categoriesService: CatalogCategoriesService,
        private pagesService: PagesService,
    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/catalog-subcategory`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/catalog-subcategory/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/catalog-subcategory/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/catalog-subcategory/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/catalog-subcategory/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/catalog-subcategory/` + id + `/enabled/` + status, null);
    }

    transformCategoriesFromApi(data: any) {
        const subcategories = [];

        data.forEach(element => {
            subcategories.push(this.transformDataFromApi(element));
            
        });

        return subcategories;
    }

    transformDataFromApi(data: any) {
        const subcategory = new CatalogSubcategory();

        subcategory._id = data._id;
        subcategory.code = data.code;
        subcategory.name = data.name;
        subcategory.description = data.description;
        subcategory.text_link_subcategory = data.text_link_subcategory;
        subcategory.image = data.image;
        subcategory.icon_hero = data.icon_hero;
        subcategory.icons = data.icons;
        subcategory.title_list_products = data.title_list_products;
        subcategory.description_list_products = data.description_list_products;
        subcategory.order = data.order;
        subcategory.enabled = data.enabled;
        
        subcategory.category = data.category ? this.categoriesService.transformDataFromApi(data.category) : null;
        subcategory.page = data.page ? this.pagesService.transformDataFromApi(data.page) : null;
        
        subcategory.sites = data.sites;
        subcategory.catalogs = data.posts;

        return subcategory;
    }
}