import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as catalogSubcategoriesActions from './catalog-subcategories.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { CatalogSubcategoriesService } from '../../shared/services/catalog-subcategories.service';



@Injectable()
export class CatalogSubcategoriesEffects {

    constructor(
        private catalogSubcategoriesService: CatalogSubcategoriesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoCatalogSubcategories$: Observable<Action> = this.actions$.pipe(
        ofType(catalogSubcategoriesActions.DO_GET_INFO_CATALOG_SUBCATEGORIES),
        switchMap((action: catalogSubcategoriesActions.DoGetInfoCatalogSubcategories) => {
            return this.catalogSubcategoriesService.get(action).pipe(
                map( response => new catalogSubcategoriesActions.DoGetInfoCatalogSubcategoriesOK(response)),
                catchError(error => of(new catalogSubcategoriesActions.DoGetInfoCatalogSubcategoriesKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedFiltersCategories$: Observable<Action> = this.actions$.pipe(
        ofType(catalogSubcategoriesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: catalogSubcategoriesActions.DoSetSelectedFilters) => {
            return this.catalogSubcategoriesService.get(action).pipe(
                map( response => new catalogSubcategoriesActions.DoGetInfoCatalogSubcategoriesOK(response)),
                catchError(error => of(new catalogSubcategoriesActions.DoGetInfoCatalogSubcategoriesKO(error)))
            );
        }));
}
