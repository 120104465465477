import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '@app/shared/services';
import { RoutesService } from '../../shared/services/routes.service';
import { AdminService } from '../../shared/services/admin.service';

import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({ templateUrl: 'password.component.html' })

export class PasswordComponent implements OnInit {
    passwordForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';

    hash: string;

    passwordChange = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private routesService: RoutesService,
        private authenticationService: AuthenticationService,
        private adminService: AdminService
    ) {
        // redirect to home if already logged in
        // if (this.authenticationService.currentUserValue) {
        //     this.router.navigate([this.routesService.getRoute('dashboard')]);
        // }

        this.route.params.subscribe( params => {
            if (params.hash) {
                this.hash = params.hash;
            }
        });
    }

    ngOnInit() {

        this.passwordForm = this.formBuilder.group({
            password: ['', RxwebValidators.compose({validators: [RxwebValidators.required(), RxwebValidators.minLength({value: 6 })] })],
            password_repeat: ['', RxwebValidators.compare({fieldName: 'password'})]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.passwordForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.passwordForm.invalid) {
            return;
        }

        this.loading = true;
        this.adminService.changePassword(this.hash, this.getDataToForm())
            .pipe(first())
            .subscribe(
                data => {
                    if (data && data.id) {
                        this.passwordChange = true;
                    } else {
                        this.submitted = false;
                        this.error = 'Ha caducado el proceso';
                    }
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }

    getDataToForm() {
        const formData = new FormData();

        formData.append('password', this.f.password.value);
        formData.append('password_repeat', this.f.password_repeat.value);

        return formData;
    }
}
