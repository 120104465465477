export class ItemsModule {
    // ctas: any;
    items: any;
    // icon: any;

    constructor(obj: Object) {
        Object.assign(this, obj);
    }
}

export class ItemsModuleHelper {

    static create(): ItemsModule {
        return { items: null };
        // return {
        //     // ctas: null,
        //     items: null,
        //     icon: null
        // };
    }

    static newFromJSON(json: string): ItemsModule {
        let items: ItemsModule = null;
        try {
            items = ItemsModuleHelper.fromObject(JSON.parse(json));
        } catch (e) {
            console.error('Invalid items JSON.', e);
        }
        return items;
    }

    static fromObject(obj: Object = {}): ItemsModule {
        const items = ItemsModuleHelper.create();
        return Object.assign(items, obj);
    }
}
