import { Action } from '@ngrx/store';

// PRESS
export const DO_GET_INFO_PRESS = '[Press] Get Info Press';
export class DoGetInfoPress implements Action {
    type = DO_GET_INFO_PRESS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_PRESS_OK = '[Press] Get Info Press OK';
export class DoGetInfoPressOK implements Action {
    type = DO_GET_INFO_PRESS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_PRESS_KO = '[Press] Get Info Press KO';
export class DoGetInfoPressKO implements Action {
    type = DO_GET_INFO_PRESS_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Press] Get Info Filters';
export class DoGetInfoPressFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Press] Get Info Filters OK';
export class DoGetInfoPressFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Press] Get Info Filters KO';
export class DoGetInfoPressFiltersKO implements Action {
    type = DO_GET_INFO_PRESS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Press] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoPress | DoGetInfoPressOK | DoGetInfoPressKO |
    DoGetInfoPressFilters | DoGetInfoPressFiltersOK | DoGetInfoPressFiltersKO 
    | DoSetSelectedFilters
    ;
