import { ModuleType } from './module-type';
export class Module {
    _id: string;
    enabled: boolean;

    classes: any;
    title: any;
    subtitle: any;
    antetile: any;
    ctas: any;
    description: any;
    image: any;
    image_alt: any;
    background: any;
    background_mobile: any;
    background_desktop: any;
    mode: any;
    mode_bg_decoration: any;
    mode_bg_color: any;
    name: string;
    items: Array<any>;
    type: ModuleType;
    config: any;
    title_tag: string;
    video: any;
    icon: any;

    image_mobile: any;
    mode_sloped: any;

    created_at: string;
    updated_at: string;
}