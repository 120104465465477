import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomRequest } from '../helpers/custom-request';
import { PageType } from '../models/page-type';

@Injectable({ providedIn: 'root' })
export class PagesTypesService {
    constructor(
        private request: CustomRequest
    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/page-type`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/page-type/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/page-type/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/page-type/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/page-type/` + id);
    }

    transformDataFromApi(data: any) {
        const pageType = new PageType();

        if (data) {
            pageType.name = data.name ? data.name : '';
            pageType._id = data._id;
            pageType.view = data.view;
            pageType.config = data.config;
            pageType.image = data.image;
            pageType.enabled = data.enabled;
            pageType.created_at = data.created_at;
            pageType.updated_at = data.updated_at;
        }

        return pageType;

    }
}