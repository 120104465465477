import { PressCategory } from './press-category';
import { Page } from './page';

export class PressPost {
    _id: string;
    enabled: boolean;
    title: any;
    category: PressCategory;
    file: any;
    file_name: any;

    published_at: string;

    sites: Array<string>;

    // created_at: string;
    // updated_at: string;
    
}