import { Component, Input } from '@angular/core';
import { AppState } from '@app/store/index';
import { Store } from '@ngrx/store';
import * as AsideActions from '@app/store/aside/aside.actions';

@Component({
  selector: 'app-module-undefined',
  templateUrl: './undefined.component.html',
  providers: []
})
export class UndefinedComponent  {

  @Input() data: any;
  @Input() modeView: string;
  @Input() index: number;
  @Input() box: number;
  @Input() section: string;


  constructor(private store: Store<AppState>) {
    // console.log('items...');
  }

  edit() {
    const payload = this.index;
    this.store.dispatch(new AsideActions.SetToEdit(payload));
  }

  delete() {
    const payload = this.index;
    this.store.dispatch(new AsideActions.SetToDelete(payload));
  }
}
