import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LocaleUtils } from '../../../helpers/locale-utils';
import { TextI18nPipe } from '../../../pipes/text-i18n.pipe';
import { I18nService } from '../../../services/18n.service';
import { lang } from 'moment';

@Component({
    selector: 'app-tags-translate',
    templateUrl: './tags-translate.component.html',
    providers: [TextI18nPipe]
})
export class TagsTranslateComponent implements OnChanges {

    @Input() dataLoaded: boolean;
    @Input() tags: any;
    @Input() locale: any;

    @Output() sendTags = new EventEmitter<any>();

    constructor(
        private textI18n: TextI18nPipe
    ) {

    }

    setValue() {
        this.sendTags.emit(this.tags);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tags) {
        }
    }

    getTagLocale(locale, i) {
        const tag = this.tags[i];

        return tag[locale] ? tag[locale] : '';
    }

    translateField(locale, i) {
        const tag = this.tags[i];
    }
}
