import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SitesService } from '../services/sites.service';
import { I18nService } from '../services/18n.service';


@Pipe({
    name: 'texti18ndefault',
})
export class TextI18nWithDefaultPipe implements PipeTransform {

    currentIso = null;

    constructor(private i18nService: I18nService) {}

    transform(txt, iso?: string): any {
        if (!txt || !(txt instanceof  Object)) {
            return (typeof txt === 'string') ? txt : null;
        }

        let valueTxt;

        if (iso) {
            valueTxt = txt[iso];
            if (valueTxt) {
                return valueTxt;
            }

            if (!Object.values(txt)[0]) {
                return '';
            }
            return '<span class="text-site-not-found">[' + Object.keys(txt)[0] + '] ' + Object.values(txt)[0] + '</span>';
        }

        valueTxt = txt[this.i18nService.getCurrentLanguage().locale];
        if (valueTxt) {
            return valueTxt;
        }

        if (!Object.values(txt)[0]) {
            return '';
        }
        return '<span class="text-site-not-found">[' + Object.keys(txt)[0] + '] ' + Object.values(txt)[0] + '</span>';
    }
}
