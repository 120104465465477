import { Injectable } from '@angular/core';
import { CustomRequest } from '../helpers/custom-request';
import { environment } from '@environments/environment';


@Injectable()
export class MenuService {
    // OPEN_CLOSE_SEARCH: string = 'OPEN_CLOSE_SEARCH';

    constructor(
        private request: CustomRequest
    ) { }

    getMenus() {
        return this.request.get(`${environment.apiUrl}/menu`);
    }

    getMenu(idMenu) {
        return this.request.get(`${environment.apiUrl}/menu/${idMenu}`);
    }
    

    changeItemStatus(id, status) {
        // menu-item/:_id/enabled/:enabled
        return this.request.post(`${environment.apiUrl}/menu-item/` + id + `/enabled/` + status, null);
    }

    createItem(data) {
        return this.request.post(`${environment.apiUrl}/menu-item`, data);
    }

    storeItem(idMenu, data) {
        return this.request.post(`${environment.apiUrl}/menu-item/${idMenu}`, data);
    }

    orderItems(data) {
        return this.request.post(`${environment.apiUrl}/menu-item/order`, data);
    }

    detailItem(idItem){
        return this.request.get(`${environment.apiUrl}/menu-item/${idItem}`);
    }
    deleteItem(id) {
        return this.request.delete(`${environment.apiUrl}/menu-item/` + id);
    }



}
