import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ItemsModule, ItemsModuleHelper } from '@app/shared/models/itemsModule';
import { I18n } from '@app/shared/models/i18n';
import { SitesService } from '../../../services/sites.service';
import { I18nService } from '@app/shared/services/18n.service';
import { AppConfigService } from '../../../services/app-config.service';

@Component({
    selector: 'app-module-common-items-form',
    templateUrl: './common-items.component.html',
    providers: []
})
export class CommonItemsComponent implements OnChanges {

    @Input() dataLoaded: boolean;
    @Input() data: any;
    @Input() section: string;
    @Input() type: string;
    @Input() currentLanguage: I18n;
    @Input() hasError: boolean;
    @Input() submitted: boolean;
    @Output() sendItems = new EventEmitter<ItemsModule>();

    moduleConfig: any;
    configTexts: any;
    configurations: any;

    currentLang: any;

    items: ItemsModule;
    module: any;

    // languageEnabled = false;

    // ctasEnabled = false;
    // iconsEnabled = false;
    itemsEnabled = false;

    errorItems: string = null;


    constructor(
        private sitesService: SitesService,
        private i18nService: I18nService,
        private appConfig: AppConfigService
    ) {
        const currentLanguage = this.i18nService.getCurrentLanguage();
    }

    initData() {
        if (this.section === 'modules') {
            this.module = this.data ? this.data : null;
        } else {
            const isEdit = (this.data && this.data.action && this.data.action === 'edit');
            this.module = isEdit ? this.data.modules[this.data.index].module : null;
        }
    }

    getAllLocalesInArray() {
        const sites = this.sitesService.getSitesStorageObject();
        const locales = [];
        sites.forEach(currentSite => {
            currentSite.i18n.forEach(locale => {
                if (locales.indexOf(locale.iso) === -1) {
                    locales.push(locale.iso);
                }
            });
        });

        return locales;
    }

    setConfigs() {
        this.moduleConfig = this.appConfig.webConfig.modulesData[this.type];
        this.itemsEnabled = this.moduleConfig.items;
        if (this.itemsEnabled) {
            this.configTexts = this.moduleConfig.items.texts;
        }
        
        this.configurations = this.moduleConfig.items && this.moduleConfig.items.configurations ? this.moduleConfig.items.configurations : null;
    }

    initItems() {
        this.setConfigs();
        this.initData();

        const locale = this.currentLanguage.iso;
        const locales = this.getAllLocalesInArray();

        const items = this.module && this.module.items ? this.module.items : [];
        const commonData = { items };
        this.items = ItemsModuleHelper.fromObject(commonData);

        this.setValue();
    }

    setValue() {
        this.valid();
        this.sendItems.emit(this.items);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataLoaded && this.dataLoaded) {
            this.initItems();
        }

        if (changes.currentLanguage) {
            this.currentLang = this.currentLanguage;
        }

        if (changes.hasError) {
            this.valid();
        }
    }

    setItemsInfo(items) {
        // console.log('ITEMS', items);
        this.items.items = items;
        this.valid();
    }

    valid() {
        let validItems = true;
        this.errorItems = null;

        const items = this.items.items;
        // console.log('items', items);
        // console.log('configurations', this.configurations);

        if (this.configurations && this.configurations.required) {

            if (!items.length) {
                validItems = false;
                this.errorItems = 'length';
            }

            if (this.configurations) {
                if (this.configurations.min && items.length < this.configurations.min) {
                    validItems = false;
                    this.errorItems = 'min';
                }
            }
        }

        // console.log('ERROR ITEMS', this.errorItems);

        return validItems;
    }


}
