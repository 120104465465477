import { Action } from '@ngrx/store';

// POLICIES
export const DO_GET_INFO_POLICIES = '[Policies] Get Info Policies';
export class DoGetInfoPolicies implements Action {
    type = DO_GET_INFO_POLICIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_POLICIES_OK = '[Policies] Get Info Policies OK';
export class DoGetInfoPoliciesOK implements Action {
    type = DO_GET_INFO_POLICIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_POLICIES_KO = '[Policies] Get Info Policies KO';
export class DoGetInfoPoliciesKO implements Action {
    type = DO_GET_INFO_POLICIES_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Policies] Get Info Filters';
export class DoGetInfoPoliciesFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Policies] Get Info Filters OK';
export class DoGetInfoPoliciesFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Policies] Get Info Filters KO';
export class DoGetInfoPoliciesFiltersKO implements Action {
    type = DO_GET_INFO_POLICIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Policies] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoPolicies | DoGetInfoPoliciesOK | DoGetInfoPoliciesKO |
    DoGetInfoPoliciesFilters | DoGetInfoPoliciesFiltersOK | DoGetInfoPoliciesFiltersKO 
    | DoSetSelectedFilters
    ;
