import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { OfficeType } from '../models/office-type';
import { CustomRequest } from '../helpers/custom-request';

@Injectable({ providedIn: 'root' })
export class OfficesTypesService {
    constructor(
        private request: CustomRequest
    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/office-type`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/office-type/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/office-type/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/office-type/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/office-type/` + id);
    }

    transformDataFromApi(dataType: any) {
        const type = new OfficeType();

        type._id = dataType._id;
        type.name = dataType.name;
        type.main = dataType.main;
        type.code = dataType.code;
        type.config = dataType.config;

        type.created_at = dataType.created_at;
        type.updated_at = dataType.updated_at;

        return type;

    }
}