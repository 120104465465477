import { Action } from '@ngrx/store';

// OFFICINAS
export const DO_GET_INFO_OFFICES = '[Offices] Get Info Offices';
export class DoGetInfoOffices implements Action {
    type = DO_GET_INFO_OFFICES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_OFFICES_OK = '[Offices] Get Info Offices OK';
export class DoGetInfoOfficesOK implements Action {
    type = DO_GET_INFO_OFFICES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_OFFICES_KO = '[Offices] Get Info Offices KO';
export class DoGetInfoOfficesKO implements Action {
    type = DO_GET_INFO_OFFICES_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Offices] Get Info Filters';
export class DoGetInfoOfficesFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Offices] Get Info Filters OK';
export class DoGetInfoOfficesFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Offices] Get Info Filters KO';
export class DoGetInfoOfficesFiltersKO implements Action {
    type = DO_GET_INFO_OFFICES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Offices] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoOffices | DoGetInfoOfficesOK | DoGetInfoOfficesKO 
    | DoSetSelectedFilters
    ;
