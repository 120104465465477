import { Action } from '@ngrx/store';


export const OPEN_ASIDE_CONFIG = '[Toggle] Open Aside Config';
export class OpenAsideConfig implements Action {
    type = OPEN_ASIDE_CONFIG;
    constructor(public payload: string) { }
}

export const CLOSE_ASIDE_CONFIG = '[Toggle] Close Aside Config';
export class CloseAsideConfig implements Action {
    type = CLOSE_ASIDE_CONFIG;
    constructor() { }
}

export const OPEN_ASIDE_SECONDARY = '[Toggle] Open Aside Items';
export class OpenAsideSecondary implements Action {
    type = OPEN_ASIDE_SECONDARY;
    constructor() { }
}

export const CLOSE_ASIDE_SECONDARY = '[Toggle] Close Aside Secondary';
export class CloseAsideSecondary implements Action {
    type = CLOSE_ASIDE_SECONDARY;
    constructor() { }
}

export const OPEN_ASIDE_ITEMS = '[Toggle] Open Aside Secondary';
export class OpenAsideItems implements Action {
    type = OPEN_ASIDE_ITEMS;
    constructor() { }
}

export const CLOSE_ASIDE_ITEMS = '[Toggle] Close Aside Items';
export class CloseAsideItems implements Action {
    type = CLOSE_ASIDE_ITEMS;
    constructor() { }
}

export const OPEN_MENU_MOBILE = '[Toggle] Open Menu Mobile';
export class OpenMenuMobile implements Action {
    type = OPEN_MENU_MOBILE;
    constructor() { }
}

export const CLOSE_MENU_MOBILE = '[Toggle] Close Menu Mobile';
export class CloseMenuMobile implements Action {
    type = CLOSE_MENU_MOBILE;
    constructor() { }
}

export const CLOSE_BLACKDROP = '[Toggle] Close Blackdrop';
export class CloseBackdrop implements Action {
    type = CLOSE_BLACKDROP;
    constructor() { }
}

export const TOGGLE_COLLAPSED = '[Toggle] Toggle Collapse';
export class ToggleCollapsed implements Action {
    type = TOGGLE_COLLAPSED;
    constructor() { }
}

export const SET_MAIN_SECTION = 'Set Main Section';
export class SetMainSection implements Action {
    type = SET_MAIN_SECTION;
    constructor(public payload: string) { }
}

export const SET_CURRENT_SECTION = 'Set Current Section';
export class SetCurrentSection implements Action {
    type = SET_CURRENT_SECTION;
    constructor(public payload: string) { }
}

export const SET_RELOAD = 'Set Reload';
export class SetReload implements Action {
    type = SET_RELOAD;
    constructor(public payload: boolean) { }
}

export type All = ToggleCollapsed |
    OpenAsideConfig | CloseAsideConfig |
    OpenMenuMobile | CloseMenuMobile |
    CloseBackdrop |
    SetMainSection | SetCurrentSection |
    OpenAsideSecondary | CloseAsideSecondary |
    OpenAsideItems | CloseAsideItems |
    SetReload;