import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError, filter } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { BlogCategory } from '../models/blog-category';
import { AnyFn } from '@ngrx/store/src/selector';
import { Site } from '../models/site';
import { BehaviorSubject, Observable } from 'rxjs';
import { I18n } from '../models/i18n';
import { RoutesService } from './routes.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/index';
import * as LayoutActions from '@app/store/layout/layout.actions';
import { I18nService } from './18n.service';


@Injectable({ providedIn: 'root' })
export class SitesService {
    private sitesData: BehaviorSubject<Site[]>;
    public sitesData$: Observable<Site[]>;

    private currentSite: BehaviorSubject<Site>;
    public currentSite$: Observable<Site>;

    currentSection$: Observable<string>;
    currentSection: string;

    sitesStorageObject: any;
    currentSiteStorageObject: any;
    currentLangStorageObject: any;


    constructor(
        private store: Store<AppState>,
        private http: HttpClient,
        private routesService: RoutesService,
        private router: Router,
        private i18nService: I18nService
    ) {
        this.initSuscriptions();
        this.initSites();
     }

     initSites() {
        let site = null;

        let currentSite = this.getCurrentSiteStorage();

        if (currentSite && currentSite !== 'undefined') {
            currentSite = JSON.parse(currentSite);

            site = this.transformDataFromApi(currentSite);
            this.setCurrentSiteBySite(site);
        }
     }

     hasSiteAndLanguage() {
        // return true;

        const site = this.getCurrentSiteStorage();
        const lang = this.i18nService.getCurrentLanguage();

        return site && lang  ? true : false;
     }

     initSuscriptions() {
        this.currentSection$ = this.store.select(state => state.layout.currentSection);
        this.currentSection$.subscribe(res => {
            this.currentSection = res;
        });

        this.currentSite = new BehaviorSubject<Site>(new Site());
        this.currentSite$ = this.currentSite.asObservable();

        this.sitesData = new BehaviorSubject<Site[]>([]);
        this.sitesData$ = this.sitesData.asObservable();
     }


    public get sites(): Array<Site> {
        return this.sitesData.value;
    }

    public get currentSiteData(): Site {
        return this.currentSite.value;
    }

    getSitesFromApi(redirect) {

        return this.http.get<any>(`${environment.apiUrl}/site`).subscribe(
            data => {
                const sites = this.transformSitesFromApi(data);

                this.sitesData.next(sites);

                this.saveSitesStorage(sites);
                if (!this.currentSiteData || !this.currentSiteData._id || redirect) {
                    this.setCurrentSite(sites[0]);
                }

                if (redirect) {
                    this.router.navigate([this.routesService.getRoute('dashboard')], {});
                }
            },
            error => console.log('oops', error)
        );
    }

    getSiteByCode(code): Site {
        return this.sites.filter(x => x.code === code)[0];
    }

    getSiteById(id): Site {
        return this.sites.filter(x => x._id === id)[0];
    }

    setCurrentSite(site: Site) {
        this.currentSite.next(site);
        this.saveSiteStorage(site);

        this.i18nService.setCurrentLanguage(site.i18n[0]);
    }

    setCurrentSiteBySite(site: Site) {
        this.currentSite.next(site);
        this.saveSiteStorage(site);

        this.currentSiteStorageObject = site;

        this.i18nService.setCurrentLanguage(site.i18n[0]);
    }

    saveSiteStorage(site): void {
        sessionStorage.setItem('site', JSON.stringify(site));
        this.currentSiteStorageObject = site;
    }

    saveSitesStorage(sites): void {
        sessionStorage.setItem('sites', JSON.stringify(sites));
    }

    getSitesStorage(): string {
        return sessionStorage.getItem('sites');
    }
    getSitesStorageObject() {
        if (!this.sitesStorageObject) {
            this.sitesStorageObject = JSON.parse(this.getSitesStorage());
        }
        return this.sitesStorageObject;
    }

    getCurrentSiteStorage(): string {
        return sessionStorage.getItem('site');
    }
    getCurrentSiteStorageObject() {
        if (!this.currentSiteStorageObject) {
            this.currentSiteStorageObject = JSON.parse(this.getCurrentSiteStorage());
        }
        return this.currentSiteStorageObject;
    }

    transformSitesFromApi(data: any) {
        const sites = [];

        data.forEach(element => {
            sites.push(this.transformDataFromApi(element));
        });

        return sites;
    }

    transformDataFromApi(data: any) {
        const site = new Site();

        site.name = data.name;
        site._id = data._id;
        site.code = data.code;
        site.enabled = data.enabled;

        let i18nData = [];

        if (data.i18n && data.i18n.length) {
            data.i18n.forEach(element => {
                i18nData.push(this.i18nService.transformDataFromApi(element));
            });
        } else {
            // i18nData = this.i18nService.setI18nTemp();
        }
        site.i18n = i18nData;

        return site;
    }

    deleteSiteAndLanguageData(): void {
        sessionStorage.removeItem('site');
        sessionStorage.removeItem('site');
        this.i18nService.deleteLanguageData();
    }
}
