import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ModulesUtils {

    static getLanguagesFromArrayObjects(data) {
        if (!(data instanceof Object)) {
            return null;
        }

        Object.keys(data).forEach((key) => {
            if (data[key] instanceof Object) {

            }
        });
    }

    static getDataInLocale(data, locales) {
        let newElement;
        const newData = {};

        locales.forEach(i18n => {
            newData[i18n] = [];

            data.forEach(element => {
                newElement = {};
                Object.keys(element).forEach((key) => {
                    if (element[key] instanceof Object
                        && element[key].hasOwnProperty(i18n)) {

                        newElement[key] = element[key][i18n];
                    }

                    if (element[key] instanceof String || element[key] instanceof Number) {
                        newElement[key] = element[key];
                    }
                });

                if (newElement && Object.keys(newElement).length) {
                    newData[i18n].push(newElement);
                }
            });
        });

        return newData;
    }

    static prepareDataToObject(data, locales) {
        if (data instanceof Object) {
            let isInLocale = false;
            locales.forEach(i18n => {
                if (data.hasOwnProperty(i18n)) {
                    isInLocale = true;
                }
            });

            return (isInLocale ? data : this.getDataInLocale(data, locales));
        }

        if (data instanceof Array) {
            return this.getDataInLocale(data, locales);
        }

        return null;
    }

    static completeDataEmpty(module, locale) {

        const fields = {
            name: '',
            mode: '',
            mode_sloped: '',
            mode_bg: '',
            mode_bg_decoration: '',
            mode_bg_color: '',
            classes: { utility: '', utility_internal: '' },
            id_anchor: '',
            title: { [locale]: '' },
            title_tag: '',
            subtitle: { [locale]: '' },
            antetitle: { [locale]: '' },
            description: {},
            image: { [locale]: '' },
            image_mobile: { [locale]: '' },
            image_alt: { [locale]: '' },
            video: { [locale]: '' },
            //icon: { [locale]: '' },
            background: { [locale]: '' },
            background_mobile: { [locale]: '' },
            background_desktop: { [locale]: '' },
            quote: { [locale]: '' },
            // ctas: { [locale]: '' },
            ctas: [],
            // items: { [locale]: '' },
            message: { [locale]: '' },
            config: { [locale]: '' },
            // enabled: false
        };

        const fieldsArray = ['ctas', 'items'];

        Object.keys(fields).forEach((key) => {
            
            if (!module[key]) {
                module[key] = fields[key];
            }

            if (Array.isArray(module[key]) && fieldsArray.indexOf(key) < 0) {
                module[key] = null;
            }
        });

        return module;
    }

    static joditBasic(locale) {
        return { 
            buttons: 'source,|,bold,strikethrough,underline,italic,link,ul,ol',
            buttonsMD: 'source,|,bold,strikethrough,underline,italic,link,ul,ol',
            buttonsSM: 'source,|,bold,strikethrough,underline,italic,link,ul,ol',
            buttonsXS: 'source,|,bold,strikethrough,underline,italic,link,ul,ol',
            // allowTags: 'strong,em,s,a[href]',
            // cleanHTML: {
            //     allowTags: 'strong,em,s,a[href]'
            // }
        };
    }

    static joditFull(locale) {
        return { 
            buttons: 'source,|,bold,strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,file,video,table,link,|,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr,symbol,fullsize',
            buttonsMD: 'source,|,bold,strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,file,video,table,link,|,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr,symbol,fullsize',
            buttonsSM: 'source,|,bold,strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,file,video,table,link,|,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr,symbol,fullsize',
            buttonsXS: 'source,|,bold,strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,file,video,table,link,|,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr,symbol,fullsize',
            uploader: { 
                insertImageAsBase64URI: true
            },
            // uploader: { 
            //     insertImageAsBase64URI: false,
            //     url: environment.apiUrl + 'upload/image',
            //     imagesExtensions: [ "jpg", "png", "jpeg", "gif"],
            //     // headers: null,
            //     // data: null,
            //     // filesVariableName: "function(e){return\"files[\"+e+\"]\"}",
            //     // withCredentials: false,
            //     // pathVariableName: "path",
            //     // format: "json",
            //     // method: "POST",
            //     // prepareData: "function(e){return e}",
            //     // isSuccess: "function(e){return e.success}",
            //     // getMessage: "function(e){return void 0!==e.data.messages&&s.isArray(e.data.messages)?e.data.messages.join(\" \"):\"\"}",
            //     // process: "function(e){return e.data}",
            //     // error: "function(e){this.j.e.fire(\"errorMessage\",e.message,\"error\",4e3)}",
            //     // defaultHandlerSuccess: "function(e){var t=this,o=this.j;s.isJoditObject(o)&&e.files&&e.files.length&&e.files.forEach((function(n,r){var i=e.isImages&&e.isImages[r]?[\"img\",\"src\"]:[\"a\",\"href\"],a=i[0],l=i[1],c=o.createInside.element(a);c.setAttribute(l,e.baseurl+n),\"a\"===a&&(c.textContent=e.baseurl+n),s.isJoditObject(t.j)&&(\"img\"===a?t.j.s.insertImage(c,null,t.j.o.imageDefaultWidth):t.j.s.insertNode(c))}))}",
            //     // defaultHandlerError: "function(e){this.j.e.fire(\"errorMessage\",e.message)}",
            //     // contentType: "function(e){return(void 0===this.j.ow.FormData||\"string\"==typeof e)&&\"application/x-www-form-urlencoded; charset=UTF-8\"}"

            // }
        };
    }
}
