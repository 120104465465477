import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { PublicationPost } from '../models/publication';

// import { BlogCategoriesService } from './blog-categories.service';
import { PagesService } from './pages.service';
import { CustomRequest } from '../helpers/custom-request';


@Injectable({ providedIn: 'root' })
export class PublicationsService {
    constructor(
        // private categoriesService: BlogCategoriesService,
        private pagesService: PagesService,
        private request: CustomRequest
    ) { }

    // NO HAY CATEGORIAS
    getFilters() {
        // console.log("PROMO CATEGORIES");
        // return this.request.get(`${environment.apiUrl}/publication-category`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/publication`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/publication/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/publication/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/publication/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/publication/` + id);
    }

    setContent(data: any) {
        return this.request.post(`${environment.apiUrl}/publication/` + data._id + `/content/`, data.content);
    }

    order(data) {
        return this.request.post(`${environment.apiUrl}/publication/order`, data);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/publication/` + id + `/enabled/` + status, null);
    }

    transformDataFromApi(dataPost: any) {
        const post = new PublicationPost();

        post._id = dataPost._id;
        post.title = dataPost.title;
        post.subtitle = dataPost.subtitle;
        post.image = dataPost.image;
        post.image_highlight = dataPost.image_highlight;
        post.ctas = dataPost.ctas;

        post.sites = dataPost.sites;
        post.enabled = dataPost.enabled;


        post.created_at = dataPost.created_at;
        post.created_at = dataPost.created_at.slice(0, -1);
        post.updated_at = dataPost.updated_at;
        post.updated_at = dataPost.updated_at.slice(0, -1);
        post.date_at = dataPost.date_at;
        if (dataPost.date_at) {
            post.date_at = dataPost.date_at.slice(0, -1);
        }


        return post;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}