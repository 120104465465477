import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as glossariesActions from './glossaries.actions';

import { GlossariesService } from '../../shared/services/glossaries.service';



@Injectable()
export class GlossariesEffects {

    constructor(
        private glossariesService: GlossariesService,
        private actions$: Actions
    ) { }

    @Effect()
    DoGetInfoGlossaries$: Observable<Action> = this.actions$.pipe(
        ofType(glossariesActions.DO_GET_INFO_GLOSSARIES),
        switchMap((action: glossariesActions.DoGetInfoGlossaries) => {
            return this.glossariesService.get(action).pipe(
                map( response => {
                    response.list = this.glossariesService.transformListFromApi(response.list);
                    return new glossariesActions.DoGetInfoGlossariesOK(response);
                }),
                catchError(error => of(new glossariesActions.DoGetInfoGlossariesKO(error)))
            );
        }));

    @Effect()
    DoGetInfoGlossariesFilters$: Observable<Action> = this.actions$.pipe(
        ofType(glossariesActions.DO_GET_INFO_FILTERS),
        switchMap((action: glossariesActions.DoGetInfoGlossariesFilters) => {
            return this.glossariesService.getFilters().pipe(
                map( response => new glossariesActions.DoGetInfoGlossariesFiltersOK(response)),
                catchError(error => of(new glossariesActions.DoGetInfoGlossariesFiltersKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedFiltersGlossaries$: Observable<Action> = this.actions$.pipe(
        ofType(glossariesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: glossariesActions.DoSetSelectedFilters) => {
            return this.glossariesService.get(action).pipe(
                map( response => new glossariesActions.DoGetInfoGlossariesOK(response)),
                catchError(error => of(new glossariesActions.DoGetInfoGlossariesKO(error)))
            );
        }));
}
