import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as experiencesActions from './experiences.actions';

import { ExperiencesService } from '../../shared/services/experiences.service';



@Injectable()
export class ExperiencesEffects {

    constructor(
        private experiencesService: ExperiencesService,
        private actions$: Actions
    ) { }

    @Effect()
    DoGetInfoExperiences$: Observable<Action> = this.actions$.pipe(
        ofType(experiencesActions.DO_GET_INFO_EXPERIENCES),
        switchMap((action: experiencesActions.DoGetInfoExperiences) => {
            return this.experiencesService.get(action).pipe(
                map( response => {
                    response.list = this.experiencesService.transformListFromApi(response.list);
                    return new experiencesActions.DoGetInfoExperiencesOK(response);
                }),
                catchError(error => of(new experiencesActions.DoGetInfoExperiencesKO(error)))
            );
        }));

    // @Effect()
    // DoGetInfoExperiencesFilters$: Observable<Action> = this.actions$.pipe(
    //     ofType(experiencesActions.DO_GET_INFO_FILTERS),
    //     switchMap((action: experiencesActions.DoGetInfoExperiencesFilters) => {
    //         return this.experiencesService.getFilters().pipe(
    //             map( response => new experiencesActions.DoGetInfoExperiencesFiltersOK(response)),
    //             catchError(error => of(new experiencesActions.DoGetInfoExperiencesFiltersKO(error)))
    //         );
    //     }));

    @Effect()
    DoSetSelectedFiltersExperiences$: Observable<Action> = this.actions$.pipe(
        ofType(experiencesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: experiencesActions.DoSetSelectedFilters) => {
            return this.experiencesService.get(action).pipe(
                map( response => new experiencesActions.DoGetInfoExperiencesOK(response)),
                catchError(error => of(new experiencesActions.DoGetInfoExperiencesKO(error)))
            );
        }));
}
