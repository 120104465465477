import { EventCategory } from './event-category';
import { Page } from './page';
import { City } from './city';

export class EventPost {

    /**
    address: object
    category: Tipo event_category
    city: Tipo City
    content: object
    enabled: boolean
    image: string
    page: Tipo pagina
    phone: string
    preview: object
    schedule: object
    sites
    title: object
    published_at: datetime
    start_at datetime
    end_at: datetime
    created_at
    update_at
    */
   
   _id: string;
    address: any;
    category: EventCategory;
    city: City;
    content: any;
    enabled: boolean;
    image: any;
    image_name: any;
    page: Page;
    phone: string;
    preview: any;
    schedule: any;
    sites: Array<string>;
    title: any;
    published_at: string;
    start_at: string;
    end_at: string;
    created_at: string;
    updated_at: string;

}