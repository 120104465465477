import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as galleriesActions from './galleries.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { GalleriesService } from '../../shared/services/galleries.service';
import { DO_GET_INFO_FILTERS, DO_GET_INFO_GALLERIES } from './galleries.actions';





@Injectable()
export class GalleriesEffects {

    constructor(
        private galleriesService: GalleriesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoGalleries$: Observable<Action> = this.actions$.pipe(
        ofType(galleriesActions.DO_GET_INFO_GALLERIES),
        switchMap((action: galleriesActions.DoGetInfoGalleries) => {
            return this.galleriesService.get(action).pipe(
                map( response => {
                    response.list = this.galleriesService.transformListFromApi(response.list);
                    return new galleriesActions.DoGetInfoGalleriesOK(response);
                }),
                catchError(error => of(new galleriesActions.DoGetInfoGalleriesKO(error)))
            );
        }));

    @Effect()
    DoGetInfoGalleriesFilters$: Observable<Action> = this.actions$.pipe(
        ofType(galleriesActions.DO_GET_INFO_FILTERS),
        switchMap((action: galleriesActions.DoGetInfoGalleriesFilters) => {
            return this.galleriesService.getFilters().pipe(
                map( response => new galleriesActions.DoGetInfoGalleriesFiltersOK(response)),
                catchError(error => of(new galleriesActions.DoGetInfoGalleriesFiltersKO(error)))
            );
        }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(galleriesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: galleriesActions.DoSetSelectedFilters) => {
            return this.galleriesService.get(action).pipe(
                map( response => new galleriesActions.DoGetInfoGalleriesOK(response)),
                catchError(error => of(new galleriesActions.DoGetInfoGalleriesKO(error)))
            );
        }));
}
