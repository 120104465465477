import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { PolicyCategory } from '../models/policy-category';
import { CustomRequest } from '../helpers/custom-request';
import { I18n } from '../models';
import { PagesService } from './pages.service';

@Injectable({ providedIn: 'root' })
export class PoliciesCategoriesService {
    constructor(
        private request: CustomRequest,
        private pagesService: PagesService
    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/policy-category`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/policy-category/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/policy-category/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/policy-category/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/policy-category/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/policy-category/` + id + `/enabled/` + status, null);
    }

    transformCategoriesFromApi(data: any) {
        const categories = [];

        data.forEach(element => {
            categories.push(this.transformDataFromApi(element));
        });

        return categories;
    }

    transformDataFromApi(dataCategory: any) {
        const category = new PolicyCategory();

        /**
            _id: string;
            name: any;
            code: string;
            enabled: boolean;
            image: string;
            sites: Array<string>;
            created_at: string;
            updated_at: string;
        */
        category._id = dataCategory._id;
        category.name = dataCategory.name;
        category.code = dataCategory.code;
        category.enabled = dataCategory.enabled;
        category.image = dataCategory.image;
        category.sites = dataCategory.sites;
        category.created_at = dataCategory.created_at;
        category.updated_at = dataCategory.updated_at;

        // category.policy = dataCategory.policy;

        return category;
    }

    prepareToJsonLangs(dataJson: any, langs: Array<I18n>) {     
        const newJsonData = {
            politicascategorias: this.pagesService.setNewJsonElements(dataJson, langs)
        };   

        return newJsonData;
    }
}