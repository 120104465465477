import { Province } from './province';
import { Office } from './office';
export class City {
    _id: string;
    name: string;
    code: string;
    province: Province;

    events: Array<any>;
    offices: Array<Office>;

    created_at: string;
    updated_at: string;
}