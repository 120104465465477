import { Action } from '@ngrx/store';

// GLOSSARIES
export const DO_GET_INFO_GLOSSARIES = '[Glossaries] Get Info Glossaries';
export class DoGetInfoGlossaries implements Action {
    type = DO_GET_INFO_GLOSSARIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_GLOSSARIES_OK = '[Glossaries] Get Info Glossaries OK';
export class DoGetInfoGlossariesOK implements Action {
    type = DO_GET_INFO_GLOSSARIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_GLOSSARIES_KO = '[Glossaries] Get Info Glossaries KO';
export class DoGetInfoGlossariesKO implements Action {
    type = DO_GET_INFO_GLOSSARIES_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Glossaries] Get Info Filters';
export class DoGetInfoGlossariesFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Glossaries] Get Info Filters OK';
export class DoGetInfoGlossariesFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Glossaries] Get Info Filters KO';
export class DoGetInfoGlossariesFiltersKO implements Action {
    type = DO_GET_INFO_GLOSSARIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Glossaries] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}

export type All = DoGetInfoGlossaries | DoGetInfoGlossariesOK | DoGetInfoGlossariesKO | 
    DoGetInfoGlossariesFilters | DoGetInfoGlossariesFiltersOK | DoGetInfoGlossariesFiltersKO |
    DoSetSelectedFilters;
