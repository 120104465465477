import { Action } from '@ngrx/store';

// BLOG
export const DO_GET_INFO_BLOG = '[Blog] Get Info Blog';
export class DoGetInfoBlog implements Action {
    type = DO_GET_INFO_BLOG;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_BLOG_OK = '[Blog] Get Info Blog OK';
export class DoGetInfoBlogOK implements Action {
    type = DO_GET_INFO_BLOG_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_BLOG_KO = '[Blog] Get Info Blog KO';
export class DoGetInfoBlogKO implements Action {
    type = DO_GET_INFO_BLOG_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Blog] Get Info Filters';
export class DoGetInfoBlogFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Blog] Get Info Filters OK';
export class DoGetInfoBlogFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Blog] Get Info Filters KO';
export class DoGetInfoBlogFiltersKO implements Action {
    type = DO_GET_INFO_BLOG_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Blog] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoBlog | DoGetInfoBlogOK | DoGetInfoBlogKO |
    DoGetInfoBlogFilters | DoGetInfoBlogFiltersOK | DoGetInfoBlogFiltersKO 
    | DoSetSelectedFilters
    ;
