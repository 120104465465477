import { Injectable } from '@angular/core';
import { I18nService } from '../services/18n.service';

@Injectable({ providedIn: 'root' })
export class LocaleUtils {

    currentIso = null;

    constructor(private i18nService: I18nService) {}


    getDataLocaleToForm(field: string, data: any, value: any, locale: string) {
        if (!locale) {
            const currentLanguage = this.i18nService.getCurrentLanguage();
            locale = currentLanguage.locale;
        }

        if (data && typeof data !== 'string') {
            data[locale] = value ? value : '';
        } else {
            data = {};
            data[locale] = value ? value : '';
        }

        return data;
    }
}