import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../services/18n.service';

@Pipe({
    name: 'texti18n',
})
export class TextI18nPipe implements PipeTransform {

    currentIso = null;

    constructor(private i18nService: I18nService,) {
    }

    transform(txt, iso?: string): any {
        if (!txt || !(txt instanceof  Object)) {
            return (typeof txt === 'string') ? txt : null;
        }

        if (iso) {
            return txt[iso];
        }

        return txt[this.i18nService.getCurrentLanguage().locale];
    }
}
