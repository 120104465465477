import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as policiesActions from './policies.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { PoliciesService } from '../../shared/services/policies.service';
import { DO_GET_INFO_FILTERS, DO_GET_INFO_POLICIES } from './policies.actions';





@Injectable()
export class PoliciesEffects {

    constructor(
        private policiesService: PoliciesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoPolicies$: Observable<Action> = this.actions$.pipe(
        ofType(policiesActions.DO_GET_INFO_POLICIES),
        switchMap((action: policiesActions.DoGetInfoPolicies) => {
            return this.policiesService.get(action).pipe(
                map( response => {
                    response.list = this.policiesService.transformListFromApi(response.list);
                    return new policiesActions.DoGetInfoPoliciesOK(response);
                }),
                catchError(error => of(new policiesActions.DoGetInfoPoliciesKO(error)))
            );
        }));

    @Effect()
    DoGetInfoPoliciesFilters$: Observable<Action> = this.actions$.pipe(
        ofType(policiesActions.DO_GET_INFO_FILTERS),
        switchMap((action: policiesActions.DoGetInfoPoliciesFilters) => {
            return this.policiesService.getFilters().pipe(
                map( response => new policiesActions.DoGetInfoPoliciesFiltersOK(response)),
                catchError(error => of(new policiesActions.DoGetInfoPoliciesFiltersKO(error)))
            );
        }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(policiesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: policiesActions.DoSetSelectedFilters) => {
            return this.policiesService.get(action).pipe(
                map( response => new policiesActions.DoGetInfoPoliciesOK(response)),
                catchError(error => of(new policiesActions.DoGetInfoPoliciesKO(error)))
            );
        }));
}
