import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LocaleUtils } from '../../../helpers/locale-utils';
import { TextI18nPipe } from '../../../pipes/text-i18n.pipe';
import { I18nService } from '../../../services/18n.service';
import { lang } from 'moment';

@Component({
  selector: 'app-items-ctas-translate',
  templateUrl: './items-ctas-translate.component.html',
  providers: [TextI18nPipe]
})
export class ItemsCtasTranslateComponent implements OnChanges {

  @Input() dataLoaded: boolean;
  @Input() dataChange: any;
  @Input() data: any;
  @Input() type: string;
  @Input() hide: boolean;
  @Input() lang: any;
  @Input() field: any;
  @Input() tabChange: any;

  @Output() sendItems = new EventEmitter<any>();
  @Output() indexErased = new EventEmitter<number>();

  errorCtas: string = null;
  hasLanguages = true;
  items = [];

  itemsLocale = [];

  newCta = {
    name: '',
    errorName: false,
    text: '',
    errorText: false,
    url: '',
    errorUrl: false
  };

  constructor(
    private textI18n: TextI18nPipe
  ) {

  }

  initItemsLanguage() {
    this.itemsLocale = [];

    let text;
    let url;

    this.items.forEach(element => {
      text = this.textI18n.transform(element.text, this.lang.locale);
      url = this.textI18n.transform(element.url, this.lang.locale);
      this.itemsLocale.push({
        name: this.textI18n.transform(element.name, this.lang.locale),
        text: text ? text : '',
        url: url ? url : ''
      });
    });
  }

  initItems() {
    if (this.data) {
      this.items = this.data;
      this.initItemsLanguage();
    }
  }

  setValue() {
    this.sendItems.emit(this.items);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('cambian....', changes);
    if (changes.data) {
      this.items = this.data;
      this.initItems();
    }

    if (changes.tabChange) {
      console.log('reload.. por cambiar tab');
      this.initItems();
    }

    // if (changes.dataChange) {
    //     console.log('ctas translate this.data', this.data);
    //     this.items = this.data;
    //     this.initItems();
    // }

    if (changes.hasError) {
      // this.validCtas();
    }
  }

  saveLanguage() {
    let idx = 0;

    this.itemsLocale.forEach(element => {
      // this.items[idx].name[this.lang.locale] = element.name;
      this.items[idx].text[this.lang.locale] = element.text;
      this.items[idx].url[this.lang.locale] = element.url;

      idx++;
    });

    this.setValue();
  }
}
