import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Faq } from '../models/faq';

import { FaqsCategoriesService } from './faqs-categories.service';
import { PagesService } from './pages.service';
import { CustomRequest } from '../helpers/custom-request';
import { I18n } from '../models';


@Injectable({ providedIn: 'root' })
export class FaqsService {
    constructor(
        private categoriesService: FaqsCategoriesService,
        private request: CustomRequest,
        private pagesService: PagesService
    ) { }

    getFilters() {
        return this.request.get(`${environment.apiUrl}/faq-category`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/faq`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/faq/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/faq/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/faq/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/faq/` + id);
    }

    order(data) {
        return this.request.post(`${environment.apiUrl}/faq/order`, data);
    }
    
    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/faq/` + id + `/enabled/` + status, null);
    }

    transformDataFromApi(data: any) {
        const faq = new Faq();

        faq.sites = data.sites;
        faq._id = data._id;
        faq.category = data.category ? this.categoriesService.transformDataFromApi(data.category) : null;
        faq.enabled = data.enabled;
        faq.order = data.order;

        faq.title = data.title;
        faq.description = data.description;
        faq.created_at = data.created_at;
        faq.updated_at = data.updated_at;

        return faq;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }

    prepareToJsonLangs(dataJson: any, langs: Array<I18n>) {     
        const newJsonData = {
            faqs: this.pagesService.setNewJsonElements(dataJson, langs)
        };   

        return newJsonData;
    }
}