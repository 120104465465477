import { ActionReducer, Action } from '@ngrx/store';
import * as fromActions from './documents.actions';
import { DocumentsState } from './documents.state';
import { DO_GET_INFO_FILTERS, DO_SET_SELECTED_FILTERS } from './documents.actions';


const defaultState: DocumentsState = {
    loading: true,
    error: '',
    posts: [],
    ordersby: [],
    paginator: {
        total: 0,
        perPage: 100,
        currentPage: 1,
        lastPage: 0
    },
    filtersCategories: [],
    filtersStatus: [
        { value: 0 , name: 'Inactivo' },
        { value: 1 , name: 'Activo' }
    ],
    // search: '',
    currentFilters: {
        textSearch: '',
        status: -1,
        date: '',
        category: ''
    },
    refresh: false
};

export type Action = fromActions.All;

export function DocumentsReducer(state = defaultState, action: any) {
    switch (action.type) {

        case fromActions.DO_GET_INFO_DOCUMENTS:
            // console.log('pidiendo...');
            return {
                ...state,
                loading: true,
                error: '',
                posts: [],
                refresh: false
            };

        case fromActions.DO_GET_INFO_DOCUMENTS_OK:
            return {
                ...state,
                loading: false,
                posts: action.payload.list,
                paginator: {
                    total: action.payload.pagination.total,
                    perPage: action.payload.pagination.limit,
                    currentPage: action.payload.pagination.page,
                    lastPage: action.payload.pagination.pages
                }
            };

        case fromActions.DO_GET_INFO_DOCUMENTS_KO:
            return {
                ...state,
                loading: false,
                error: action.payload,
                posts: [],
                paginator: null
            };

        case fromActions.DO_GET_INFO_FILTERS:
            // console.log('pidiendo...');
            return {
                ...state,
                loading: true,
                error: ''
            };

        case fromActions.DO_GET_INFO_FILTERS_OK:
            return {
                ...state,
                // loading: false,
                filtersCategories: action.payload.list

            };

        case fromActions.DO_GET_INFO_FILTERS_KO:
            return {
                ...state,
                loading: false,
                error: action.payload,
                // filtersCategories: []
            };

        case fromActions.DO_SET_SELECTED_FILTERS:
            return {
                ...state,
                loading: false,
                currentFilters: action.payload.filters,
                paginator: action.payload.paginator,
                refresh: true
            };

        default:
            return state;
    }
}
