import { NgModule } from '@angular/core';
import { UrlImagePipe } from './url-image.pipe';
import { TextI18nPipe } from './text-i18n.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { TextI18nWithDefaultPipe } from './text-i18n-with-default.pipe';
import { SafePipe } from './safe.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { StripTagsPipe } from './strip-tags.pipe';


const PIPES = [
  UrlImagePipe,
  TextI18nPipe,
  TextI18nWithDefaultPipe,
  StripHtmlPipe,
  SafePipe,
  SafeHtmlPipe,
  StripTagsPipe
];

@NgModule({
  declarations: PIPES,
  exports: PIPES
})
export class PipesModule {
}
