import { Action } from '@ngrx/store';

// CATALOGS
export const DO_GET_INFO_CATALOGS = '[Catalogs] Get Info Catalogs';
export class DoGetInfoCatalogs implements Action {
    type = DO_GET_INFO_CATALOGS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_CATALOGS_OK = '[Catalogs] Get Info Catalogs OK';
export class DoGetInfoCatalogsOK implements Action {
    type = DO_GET_INFO_CATALOGS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_CATALOGS_KO = '[Catalogs] Get Info Catalogs KO';
export class DoGetInfoCatalogsKO implements Action {
    type = DO_GET_INFO_CATALOGS_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Catalogs] Get Info Filters';
export class DoGetInfoCatalogsFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Catalogs] Get Info Filters OK';
export class DoGetInfoCatalogsFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Catalogs] Get Info Filters KO';
export class DoGetInfoCatalogsFiltersKO implements Action {
    type = DO_GET_INFO_CATALOGS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Catalogs] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}

export type All = DoGetInfoCatalogs | DoGetInfoCatalogsOK | DoGetInfoCatalogsKO | 
    DoGetInfoCatalogsFilters | DoGetInfoCatalogsFiltersOK | DoGetInfoCatalogsFiltersKO |
    DoSetSelectedFilters;
