import { ActionReducer, Action } from '@ngrx/store';
import * as fromActions from './experiences.actions';
import { ExperiencesState } from './experiences.state';
import { DO_GET_INFO_FILTERS, DO_SET_SELECTED_FILTERS } from './experiences.actions';


const defaultState: ExperiencesState = {
    loading: true,
    error: '',
    items: [],
    ordersby: [],
    paginator: {
        total: 0,
        perPage: 100,
        currentPage: 1,
        lastPage: 0
    },
    filtersStatus: [
        { value: 0 , name: 'Inactivo' },
        { value: 1 , name: 'Activo' }
    ],
    filtersChecked: [
        { value: 0 , name: 'Pendiente' },
        { value: 1 , name: 'Revisado' }
    ],
    // search: '',
    currentFilters: {
        textSearch: '',
        status: -1
    },
    refresh: false
};

export type Action = fromActions.All;

export function ExperiencesReducer(state = defaultState, action: any) {
    switch (action.type) {

        case fromActions.DO_GET_INFO_EXPERIENCES:
            // console.log('pidiendo...');
            return {
                ...state,
                loading: true,
                error: '',
                items: [],
                refresh: false
            };

        case fromActions.DO_GET_INFO_EXPERIENCES_OK:
            return {
                ...state,
                loading: false,
                items: action.payload.list,
                paginator: {
                    total: action.payload.pagination.total,
                    perPage: action.payload.pagination.limit,
                    currentPage: action.payload.pagination.page,
                    lastPage: action.payload.pagination.pages
                }
            };

        case fromActions.DO_GET_INFO_EXPERIENCES_KO:
            return {
                ...state,
                loading: false,
                error: action.payload,
                items: [],
                paginator: null
            };

        case fromActions.DO_SET_SELECTED_FILTERS:
            return {
                ...state,
                // loading: false,
                currentFilters: action.payload.filters,
                paginator: action.payload.paginator,
                refresh: true
            };

        default:
            return state;
    }
}
