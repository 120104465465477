import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as documentsActions from './documents.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { DocumentsService } from '../../shared/services/documents.service';
import { DO_GET_INFO_FILTERS, DO_GET_INFO_DOCUMENTS } from './documents.actions';





@Injectable()
export class DocumentsEffects {

    constructor(
        private documentsService: DocumentsService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoDocuments$: Observable<Action> = this.actions$.pipe(
        ofType(documentsActions.DO_GET_INFO_DOCUMENTS),
        switchMap((action: documentsActions.DoGetInfoDocuments) => {
            return this.documentsService.get(action).pipe(
                map( response => {
                    response.list = this.documentsService.transformListFromApi(response.list);
                    return new documentsActions.DoGetInfoDocumentsOK(response);
                }),
                catchError(error => of(new documentsActions.DoGetInfoDocumentsKO(error)))
            );
        }));

    @Effect()
    DoGetInfoDocumentsFilters$: Observable<Action> = this.actions$.pipe(
        ofType(documentsActions.DO_GET_INFO_FILTERS),
        switchMap((action: documentsActions.DoGetInfoDocumentsFilters) => {
            return this.documentsService.getFilters().pipe(
                map( response => new documentsActions.DoGetInfoDocumentsFiltersOK(response)),
                catchError(error => of(new documentsActions.DoGetInfoDocumentsFiltersKO(error)))
            );
        }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(documentsActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: documentsActions.DoSetSelectedFilters) => {
            return this.documentsService.get(action).pipe(
                map( response => new documentsActions.DoGetInfoDocumentsOK(response)),
                catchError(error => of(new documentsActions.DoGetInfoDocumentsKO(error)))
            );
        }));
}
