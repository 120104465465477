import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomRequest } from '../helpers/custom-request';
import { Glossary } from '../models/glossary';


@Injectable({ providedIn: 'root' })
export class GlossariesService {
    constructor(
        private request: CustomRequest
    ) { }

    getFilters() {
        return this.request.get(`${environment.apiUrl}/glossary`);
    }

    get(data) {        
        return this.request.get(`${environment.apiUrl}/glossary`, data.payload);
    }

    detail(id) {        
        return this.request.get(`${environment.apiUrl}/glossary/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/glossary/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/glossary/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/glossary/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/glossary/` + id + `/enabled/` + status, null);
    }

    transformDataFromApi(data: any) {
        const glossary = new Glossary();

        glossary.sites = data.sites;
        glossary._id = data._id;
        glossary.enabled = data.enabled;

        glossary.name = data.name;
        glossary.description = data.description;

        glossary.created_at = data.created_at;
        glossary.updated_at = data.updated_at;

        return glossary;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}