import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '@app/shared/services';
import { RoutesService } from '../../shared/services/routes.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/index';
import { Credentials } from '../../shared/models/credentials';

import * as loggedActions from '../../store/auth/auth.actions';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppConfigService } from '../../shared/services/app-config.service';


@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;

    errorLogin$: Observable<string>;
    loadingLogin$: Observable<boolean>;
    errorLoginMessage: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private routesService: RoutesService,
        private authenticationService: AuthenticationService,
        private store: Store<AppState>,
        public appConfig: AppConfigService
    ) {
        if (this.authenticationService.isLogged()) {
            this.router.navigate([this.routesService.getRoute('dashboard')]);
        }


        this.loadingLogin$ = this.store.select(state => state.auth.loading);
        this.loadingLogin$.subscribe(res => {
            // console.log('res loading...', res);
            this.loading = res;
        });

        this.errorLogin$ = this.store.select(state => state.auth.error);
        this.errorLogin$.subscribe(res => {
            this.errorLoginMessage = res;
        });
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            user: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit(): void {
        this.submitted = true;

        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.store.dispatch(
            new loggedActions.DoLogin(
                new Credentials({
                    user: this.f.user.value,
                    password: this.f.password.value
                })
            )
        );
    }

    getLogo() {
        if (this.appConfig.webConfig) {
            return '/assets/images/' + this.appConfig.webConfig.logo + '.svg';
        } 
        return null;
    }
    getLogoName() {
        if (this.appConfig.webConfig) {
            return this.appConfig.webConfig.logoName;
        }
        return null;
    }

    hasClassLogo() {
        return environment.webCode === 'servicios-funerarios';
    }
}
