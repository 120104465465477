import { Component, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as AsideActions from '@app/store/aside/aside.actions';
import { ConfigurationsModule } from '@app/shared/models/configurationsModule';
import { LocaleUtils } from '@app/shared/helpers/locale-utils';
import { SitesService } from '@app/shared/services/sites.service';
import { I18n } from '@app/shared/models/i18n';
import { TextI18nPipe } from '@app/shared/pipes/text-i18n.pipe';
import { FilesManager } from '@app/shared/helpers/files-manager';

import { ItemsModule } from '@app/shared/models/itemsModule';
import { ModulesUtils } from '../../../../helpers/modules-utils';
import { environment } from '../../../../../../environments/environment';
import { I18nService } from '../../../../services/18n.service';
import { Site } from '../../../../models/site';
import { ModuleType } from '../../../../models/module-type';
import { AppConfigService } from '../../../../services/app-config.service';

import * as _ from 'lodash';

@Component({
    selector: 'app-module-common-form',
    templateUrl: './edit-common.component.html',
    providers: [TextI18nPipe]
})
export class EditCommonComponent implements OnChanges, OnDestroy {
    moduleConfig: any;
    @Input() type: string;
    @Input() itemsView: boolean;


    viewFields: any;

    elementsForm = [];
    loadedForms = false;

    configBasic: any;
    configFull: any;

    environement = environment;

    public filesManager: FilesManager;
    editingItem: boolean;

    data: any;
    typeModule: ModuleType;
    dataLoaded = false;

    section: string;

    configurationsValid = false;
    configurations: ConfigurationsModule;
    commonItems: ItemsModule;

    modules: Array<any>;

    itemForm: FormGroup = null;
    submitted = false;

    currentSite: Site = null;
    editintLanguage: I18n;
    currentLanguage: I18n;
    languagesToTranslate: Array<I18n> = null;
    visibilityLanguagesToTranslate: any;


    ctasChange = 0;
    ctas: Array<any> = [];
    icons: any;
    items: Array<any> = [];

    itemsError = false;

    filesNames: Array<any>;

    hasItemsToTranslate = false;

    excludeItems = false;

    tabChange: number;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        private localeUtils: LocaleUtils,
        private sitesService: SitesService,
        private i18nService: I18nService,
        private textI18n: TextI18nPipe,
        private appConfig: AppConfigService
    ) {
        this.data = null;

        this.initSuscriptions();

        const currentLanguage = this.i18nService.getCurrentLanguage();
        this.configBasic = ModulesUtils.joditBasic(currentLanguage.iso);
        this.configFull = ModulesUtils.joditFull(currentLanguage.iso);
    }

    initConfigModule() {
        this.moduleConfig = this.appConfig.webConfig.modulesData[this.type];

        if (this.moduleConfig) {
            this.moduleConfig.fields.forEach(elements => {
                elements.forEach(element => {
                    this.elementsForm.push(element);
                });
            });
        }
    }

    getEditorMode(config) {
        switch (config) {
            case 'full': return this.configFull;
            default: return this.configBasic;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.type) {
            this.reset();
            this.initConfigModule();
            this.initGlobal();
        }
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    setConfig(conf: ConfigurationsModule) {
        this.configurations = conf;
        this.checkRequiredFields();
    }

    getModuleInfoBySection() {
        return this.section === 'modules' ? this.data : this.data.modules[this.data.index].module;
    }

    setCommonItems(conf: ItemsModule) {
        this.commonItems = conf;
    }

    initSuscriptions() {
        this.suscriptionsToManage$.push(this.i18nService.currentLanguage$.subscribe(
            i18n => {
                this.currentLanguage = i18n;
            }
        ));

        this.suscriptionsToManage$.push(this.sitesService.currentSite$.subscribe(
            site => {
                this.currentSite = site;
                this.initLanguagesToTranslate();
                this.initGlobal();
            }
        ));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.section).subscribe(res => {
            this.section = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.data).subscribe(res => {
            if (res) {
                this.data = res;
                this.dataLoaded = true;
                if (this.data) {
                    if ((this.section === 'modules' && this.data) || (this.data && this.data.modules && this.data.index)) {
                        this.fillForm();
                    }

                    this.editingItem = true;
                }
            } else {
                this.editingItem = false;
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.typeModule).subscribe(res => {
            this.typeModule = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.toSave).subscribe(res => {
            if (res) {
                this.onSubmit();
            }
        }));
    }

    initLanguagesToTranslate() {
        this.visibilityLanguagesToTranslate = {};
        this.languagesToTranslate = [];

        let init = false;

        this.currentSite.i18n.forEach(lang => {
            if (lang.locale !== this.currentLanguage.locale) {
                this.languagesToTranslate.push(lang);
                this.visibilityLanguagesToTranslate[lang.locale] = init ? false : true;

                if (this.visibilityLanguagesToTranslate[lang.locale]) {
                    this.editintLanguage = lang;
                }

                init = true;
            }
        });
    }

    setI18nLocaleTranslate(i18n) {
        this.languagesToTranslate.forEach(lang => {
            this.visibilityLanguagesToTranslate[lang.locale] = i18n._id === lang._id ? true : false;
        });
        this.editintLanguage = i18n;
    }

    get f() { return this.itemForm.controls; }

    getGroupField(groupName, field) {
        const group = this.itemForm.get(groupName);
        const fieldOnGroup = group.get(field);

        return fieldOnGroup;
    }

    calculateClasses(item) {
        return item.cols ? item.cols : null;
    }

    reset() {
        this.submitted = false;
        this.itemForm = null;
        this.elementsForm = [];
        this.ctas = [];
        this.icons = null;
        this.items = [];

        this.ctasChange++;
    }

    initGlobal() {
        if (!this.moduleConfig || !this.currentSite) {
            return;
        }

        this.initLanguagesToTranslate();
        this.initFormImages();
        this.initForm();

        if ((this.section === 'modules' && this.data) || (this.data && this.data.modules && this.data.index)) {
            this.fillForm();
        }

        this.initSelects();
    }

    isFilesManagerField(element) {
        return (element.type === 'image' || element.type === 'file' || element.type === 'video');
    }

    getModeData(modes, currentMode) {
        let mode = null;
        modes.forEach(element => {
            if (element.value === currentMode) {
                mode = element;
            }
        });

        return mode;
    }

    setVisibility(field, value) {
        this.moduleConfig.fields.forEach(elements => {
            elements.forEach(element => {
                if (element.field === field) {
                    element['hide'] = value;
                }
            });
        });
    }

    isExcludeItems(mode) {
        const modesModule = this.moduleConfig.configurations &&
            this.moduleConfig.configurations.modes ? this.moduleConfig.configurations.modes.cases : null;

        if (!modesModule) {
            return false;
        }

        let currentMode = this.getModeData(modesModule, mode);
        if (!currentMode) {
            currentMode = {};
        }

        const isExclude = (currentMode.exclude && currentMode.exclude.indexOf('items') > -1);

        return isExclude;
    }

    setFieldValidators(mode) {
        //TODO.
        const modesModule = this.moduleConfig.configurations &&
            this.moduleConfig.configurations.modes ? this.moduleConfig.configurations.modes.cases : null;

        if (!modesModule) {
            return;
        }

        let currentMode = this.getModeData(modesModule, mode);
        if (!currentMode) {
            currentMode = {};
        }

        let isExclude;
        this.elementsForm.forEach(element => {
            // console.log('element', element);
            isExclude = (currentMode.exclude && currentMode.exclude.indexOf(element.field) > -1);

            if (element.type === 'category-relation') {
                if (isExclude) {
                    this.setVisibility(element.field, true);
                } else {
                    this.setVisibility(element.field, this.getVisibilityFromFieldParent(element));
                }
            } else {
                this.setVisibility(element.field, isExclude);
            }
            if (this.isFilesManagerField(element)) {
                // fieldsControl[element.field] = element.required ? new FormControl('', Validators.required) : new FormControl('');
                this.filesManager.setProperties(element.field, { required: (isExclude ? false : element.required) });
            } else if (element.type === 'ctas') {

            } else if (element.type === 'icons') {

            } else if (element.type === 'category-relation') {

            } else if (element.type === 'social-video') {
                if (!element.required || isExclude) {
                    this.f[element.field + '_src'].clearValidators();
                    this.f[element.field + '_text'].clearValidators();
                } else {
                    this.f[element.field + '_src'].setValidators([Validators.required]);
                    this.f[element.field + '_text'].setValidators([Validators.required]);
                }
            } else {
                if (!element.required || isExclude) {
                    this.f[element.field].clearValidators();
                } else {
                    this.f[element.field].setValidators([Validators.required]);
                }

                this.f[element.field].updateValueAndValidity();
            }
        });

        this.excludeItems = this.isExcludeItems(mode);

    }

    checkRequiredFields() {
        const modesModule = this.moduleConfig.configurations && this.moduleConfig.configurations.modes ? this.moduleConfig.configurations.modes.cases : null;
        if (!modesModule) {
            return;
        }

        const mode = this.configurations.mode;
        if (!mode) {
            this.setFieldValidators('default');
        }
        this.setFieldValidators(mode);
    }

    initFormImages() {
        let conf;
        let maxSize;
        let allowedTypes;
        let allowedTypesTxt;

        const formImages = [];

        this.elementsForm.forEach(element => {
            if (this.isFilesManagerField(element)) {
                conf = element.configurations;
                maxSize = conf.max_size ? conf.max_size : 20971520;
                allowedTypesTxt = conf.allowed_types_txt ? conf.allowed_types_txt : '';
                allowedTypes = conf.allowed_types ? conf.allowed_types : null;
                if (!allowedTypes) {
                    switch (element.type) {
                        case 'image':
                            allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
                            allowedTypesTxt = 'PNG | JPG';
                            break;

                        case 'file':
                            allowedTypes = ['application/pdf'];
                            allowedTypesTxt = 'PDF';
                            break;

                        case 'video':
                            allowedTypes = ['video/mp4'];
                            allowedTypesTxt = 'MP4';
                            break;
                    }
                }


                formImages.push(
                    {
                        field: element.field,
                        initial_value: null,
                        validators: {
                            max_size: maxSize,
                            allowed_types: allowedTypes,
                            allowed_types_txt: allowedTypesTxt,
                            max_height: conf.max_height ? conf.max_height : 108000,
                            max_width: conf.max_width ? conf.max_width : 192000,
                            is_required: element.required
                        },
                        base64: null,
                        error: null,
                        saved: false,
                        file_name: ''
                    }
                );

                if (element.languages) {

                    this.hasItemsToTranslate = true;

                    const requiredFieldsLang = this.moduleConfig.requiredTraduction;

                    Object.entries(this.visibilityLanguagesToTranslate).forEach(([key, value]) => {
                        formImages.push(
                            {
                                field: key + '_' + element.field,
                                initial_value: null,
                                validators: {
                                    max_size: maxSize,
                                    allowed_types: allowedTypes,
                                    allowed_types_txt: allowedTypesTxt,
                                    max_height: conf.max_height ? conf.max_height : 108000,
                                    max_width: conf.max_width ? conf.max_width : 192000,
                                    is_required: (requiredFieldsLang && element.required)
                                },
                                base64: null,
                                error: null,
                                saved: false
                            }
                        );
                    });
                }
            }
        });

        this.filesManager = new FilesManager();
        this.filesManager.setConfiguration(formImages);
    }

    initForm() {
        const fieldsControl: any = {};

        
        this.elementsForm.forEach(element => {
            if (!this.isFilesManagerField(element) && element.type !== 'ctas' && element.type !== 'icons') {
                if (element.type === 'social-video') {
                    fieldsControl[element.field + '_src'] = element.required ? new FormControl('', Validators.required) : new FormControl('');
                    fieldsControl[element.field + '_text'] = element.required ? new FormControl('', Validators.required) : new FormControl('');
                } else {
                    fieldsControl[element.field] = element.required ? new FormControl('', Validators.required) : new FormControl('');

                    if (element.level && element.level) {
                        fieldsControl[element.field + '_level'] = new FormControl('');
                    }
                }
            } else if (this.isFilesManagerField(element) && element.hasTitle) {
                fieldsControl[element.field + '_title'] = new FormControl('');
            }
        });

        this.itemForm = this.formBuilder.group(fieldsControl);

        this.completeFormLanguages();

        this.loadedForms = true;
    }

    completeFormLanguages() {

        if (this.currentSite.i18n.length > 1) {
            let locale;
            let fieldsControl;

            const requiredFieldsLang = this.moduleConfig.requiredTraduction;

            this.languagesToTranslate.forEach(lang => {
                locale = lang.locale;

                fieldsControl = {};
                this.elementsForm.forEach(element => {
                    if (element.languages) {
                        if (element.type === 'social-video') {
                            fieldsControl[element.field + '_src'] = (requiredFieldsLang && element.required) ? new FormControl('', Validators.required) : new FormControl('');
                            fieldsControl[element.field + '_text'] = (requiredFieldsLang && element.required) ? new FormControl('', Validators.required) : new FormControl('');
                        } else {
                            fieldsControl[element.field] = (requiredFieldsLang && element.required) ? new FormControl('', Validators.required) : new FormControl('');
                        }

                        this.hasItemsToTranslate = true;
                    }
                });

                this.itemForm.addControl(locale + '_info', this.formBuilder.group(fieldsControl));
            });
        }

    }

    fillForm() {
        if (!this.moduleConfig || !this.currentSite || !this.data || (!this.data.modules && this.section === 'pages')) {
            return;
        }

        const module = this.getModuleInfoBySection();
        const locale = this.i18nService.getCurrentLanguage().locale;

        let finalField;

        this.elementsForm.forEach(element => {
            switch (element.type) {
                case 'image':
                case 'file':
                case 'video':
                    if (module && module[element.field] instanceof Object) {
                        this.filesManager.setProperties(element.field, { initial_value: module[element.field][locale] });
                    } else {
                        this.filesManager.setProperties(element.field, { initial_value: module[element.field] });
                    }

                    if (element.hasTitle && module.titles_images && module.titles_images[element.field]) {
                        this.f[element.field + '_title'].setValue(module.titles_images[element.field]);
                    }
                    break;

                case 'ctas':
                    this.ctas = module[element.field] instanceof Array ? module[element.field] : [];
                    break;

                case 'icons':
                    // this.icons = module[element.field] instanceof Array ? module[element.field] : [];
                    this.icons = module[element.field];
                    break;

                case 'category-relation':
                    finalField = element.field_final ? element.field_final : element.field;
                    // if (element.configurations.inConfigField && module.hasOwnProperty('config')) {
                    if (element.configurations.inConfigField) {
                        if (!module.hasOwnProperty('config')) {
                            module.config = [];
                        }
                        if (module.config && module.config.hasOwnProperty(finalField)) {
                            this.f[element.field].setValue(module.config[finalField]);
                        }
                    } else {
                        if (module.hasOwnProperty(finalField)) {
                            this.f[element.field].setValue(module[finalField]);
                        }
                    }
                    break;

                case 'social-video':
                    if (element.languages) {
                        const video = (module[element.field] instanceof Array) ? module[element.field][0] : module[element.field];
                        if (video) {
                            const videoSrc = video.src ? this.textI18n.transform(video.src) : '';
                            const videoTxt = video.src ? this.textI18n.transform(video.text) : '';
                            this.f[element.field + '_src'].setValue(videoSrc);
                            this.f[element.field + '_text'].setValue(videoTxt);
                        }
                    } else {
                        const video = (module[element.field] instanceof Array) ? module[element.field][0] : module[element.field];
                        const videoSrc = video.src ? video.src : '';
                        const videoTxt = video.src ? video.text : '';
                        this.f[element.field + '_src'].setValue(videoSrc);
                        this.f[element.field + '_text'].setValue(videoTxt);
                    }
                    break;

                default:
                    if (element.languages) {
                        if (element.configurations && element.configurations.inConfigField) {
                            if (!module.hasOwnProperty('config')) {
                                module.config = [];
                            }
                            this.f[element.field].setValue(this.textI18n.transform(module.config[element.field]));
                        } else {
                            this.f[element.field].setValue(this.textI18n.transform(module[element.field]));
                        }
                    } else {
                        if (!module.hasOwnProperty('config')) {
                            module.config = [];
                        }
                        if (element.configurations && element.configurations.inConfigField) {
                            this.f[element.field].setValue(module.config[element.field]);
                        } else {
                            this.f[element.field].setValue(module[element.field]);
                        }
                    }

                    if (element.level && module.levels && module.levels[element.field]) {
                        this.f[element.field + '_level'].setValue(module.levels[element.field]);
                    }
                    break;
            }
        });

        this.fillFormLanguages();
    }

    fillFormLanguages() {
        if (this.currentSite.i18n.length > 1) {

            let locale: string;
            let group;
            const module = this.getModuleInfoBySection();

            this.languagesToTranslate.forEach(lang => {
                locale = lang.locale;
                group = this.itemForm.get(locale + '_info');

                this.elementsForm.forEach(element => {
                    if (element.languages) {

                        switch (element.type) {
                            case 'image':
                            case 'file':
                            case 'video':
                                if (module && module[element.field] instanceof Object) {
                                    this.filesManager.setProperties(locale + '_' + element.field, { initial_value: module[element.field][locale] });
                                } else {
                                    this.filesManager.setProperties(locale + '_' + element.field, { initial_value: module[element.field] });
                                }
                                break;

                            case 'social-video':
                                const video = (module[element.field] instanceof Array) ? module[element.field][0] : module[element.field];
                                if (video) {
                                    const videoSrc = video.src ? this.textI18n.transform(video.src, locale) : '';
                                    const videoTxt = video.text ? this.textI18n.transform(video.text, locale) : '';
                                    group.get(element.field + '_src').setValue(videoSrc);
                                    group.get(element.field + '_text').setValue(videoTxt);
                                }
                                break;

                            default:
                                if (element.configurations && element.configurations.inConfigField) {
                                    if (module.config) {
                                        group.get(element.field).setValue(this.textI18n.transform(module.config[element.field], locale));
                                    }
                                } else {
                                    group.get(element.field).setValue(this.textI18n.transform(module[element.field], locale));
                                }
                                break;
                        }
                    }
                });
            });
        }

    }

    cancelSave() {
        this.store.dispatch(new AsideActions.CancelToSave());
    }

    validFiles() {
        let tmpValid;
        let validFiles = true;

        this.elementsForm.forEach(element => {
            if (this.isFilesManagerField(element)) {
                tmpValid = this.filesManager.isValidFile(element.field, !this.editingItem ? 'add' : 'edit');
                if (!tmpValid) {
                    validFiles = false;
                }
            }
        });

        return validFiles;
    }

    validItems() {
        let validItems = true;

        if (this.excludeItems) {
            return true;
        }

        const items = this.commonItems.items;

        if (this.moduleConfig.items && this.moduleConfig.items.configurations) {
            if (this.moduleConfig.items.configurations.required && !items.length) {
                validItems = false;
            }

            if (this.moduleConfig.items.configurations.min && (this.moduleConfig.items.configurations.min > items.length) ) {
                validItems = false;
            }
        }

        this.itemsError = validItems;

        return validItems;
    }

    validCtas() {
        let validCtas = true;

        this.elementsForm.forEach(element => {
            if (element.type === 'ctas' && element.required) {
                element.hasError = false;

                if (!element.hide) {
                    if (!this.ctas || !this.ctas.length) {
                        validCtas = false;
                        element.hasError = true;
                    }

                    if (element.configurations) {
                        if (element.configurations.min && (!this.ctas || this.ctas.length < element.configurations.min)) {
                            validCtas = false;
                            element.hasError = true;
                        }
                    }
                }
            }
        });

        return validCtas;
    }

    validIcons() {
        let validIcons = true;

        this.elementsForm.forEach(element => {
            if (element.type === 'icons' && element.required) {
                element.hasError = false;

                if (!element.hide) {
                    if (!this.icons || !this.icons.length) {
                        validIcons = false;
                        element.hasError = true;
                    }

                    if (element.configurations) {
                        if (element.configurations.min && (!this.icons || this.icons.length < element.configurations.min)) {
                            validIcons = false;
                            element.hasError = true;
                        }
                    }
                }
            }
        });

        return validIcons;
    }

    getErrorMessage(validFiles) {
        let error = '';

        if (!this.configurationsValid && this.itemForm.invalid && !validFiles) {
            error += 'Revisa las configuraciones y campos oblitatorios';
        } else if (!this.configurationsValid) {
            error += 'Revisa las configuraciones';
        } else if (this.itemForm.invalid) {
            error += 'Revisa los campos obligatorios';
        } else if (!this.itemForm.invalid && !validFiles) {
            error += 'Revisa los archivos';
        } else {
            error += 'Revisa los campos obligatorios';
        }

        return error;
    }

    onSubmit() {
        this.submitted = true;

        const payload = { error: null };
        this.store.dispatch(new AsideActions.SetError(payload));

        const validFiles = this.validFiles();
        const validCtas = this.validCtas();
        const validIcons = this.validIcons();
        const validItems = this.validItems();

        if (!this.configurationsValid || this.itemForm.invalid || !validFiles || !validCtas || !validIcons || !validItems) {
            // console.log('NO SUBMIT');
            // console.log(this.configurationsValid, this.itemForm.invalid, validFiles, validCtas, validIcons, validItems);
            // console.log(this.itemForm);

            payload.error = this.getErrorMessage(validFiles);
            // console.log('payload', payload);
            this.store.dispatch(new AsideActions.SetError(payload));
            return this.cancelSave();
        }

        this.save();
    }

    initModule() {
        const obj = {
            type: this.typeModule._id,
            mode: '',
            config: {},
            levels: {},
            titles_images: {}
        };

        return Object.assign(obj, this.configurations);
    }

    setConfigurations(module) {
        return Object.assign(module, this.configurations);
    }

    validConfigurations(value: boolean) {
        return this.configurationsValid = value;
    }

    setItems(module) {
        return Object.assign(module, this.commonItems);
    }

    create(module) {
        const payload = { result: true, module };
        this.store.dispatch(new AsideActions.SetResult(payload));
    }

    update(module) {
        if (this.section === 'modules') {
            const payload = { result: true, module: this.data };
            this.store.dispatch(new AsideActions.SetResult(payload));
        } else {
            const modules = this.data.modules;
            // modules[this.data.index].module = module;
            modules[this.data.index].module = Object.assign(modules[this.data.index].module, module);

            const payload = { result: true, modules };
            this.store.dispatch(new AsideActions.SetResult(payload));
        }
    }

    getDataToFormCommonLanguages(module) {
        let locale;
        let group;
        let valueField;


        this.languagesToTranslate.forEach(lang => {
            locale = lang.locale;
            group = this.itemForm.get(locale + '_info');

            this.elementsForm.forEach(element => {

                if (element.languages) {
                    if (this.isFilesManagerField(element)) {
                        valueField = this.filesManager.getBase64Image(locale + '_' + element.field);
                        if (!valueField) {
                            valueField = this.filesManager.getInitialImage(locale + '_' + element.field);
                        }
                    } else if (element.type === 'social-video') {
                        module[element.field].src[locale] = group.get(element.field + '_src').value;
                        module[element.field].text[locale] = group.get(element.field + '_text').value;
                    } else {
                        valueField = group.get(element.field).value;
                    }

                    if (element.type !== 'social-video' && element.type !== 'icons') {
                        module[element.field] = this.localeUtils.getDataLocaleToForm(element.field, module[element.field], valueField, locale);
                    }
                }
            });
        });

        return module;
    }

    getDataCommon() {
        const locale = this.currentLanguage.locale;

        let module;
        if (this.section === 'modules') {
            module = this.data.action === 'add' ? this.initModule() : this.data;
        } else {
            module = this.data.action === 'add' ? this.initModule() : this.data.modules[this.data.index].module;
        }

        // reseteo el config para evitar que vengan idiomas vacios que hace que en front ya no devuelva
        // valores buenos
        module.config = {};
        
        let valueFieldTitles;
        let valueFieldLevel;
        let valueField;
        let valueFieldFileName;
        
        this.elementsForm.forEach(element => {

            if (this.isFilesManagerField(element)) {
                valueField = this.filesManager.getBase64Image(element.field);
                if (!valueField) {
                    valueField = this.filesManager.getInitialImage(element.field);
                } else {
                    valueFieldFileName = this.filesManager.getFileName(element.field);
                    module[element.field + '_name'] = valueFieldFileName;
                }

                if (element.hasTitle) {
                    valueFieldTitles = this.f[element.field + '_title'].value;

                    if (!module.titles_images) {
                        module.titles_images = {};
                    }
                    module.titles_images[element.field] = valueFieldTitles;
                }

            } else if (element.type === 'ctas') {
                valueField = this.ctas;
            } else if (element.type === 'icons') {
                valueField = this.icons;
            } else if (element.type === 'social-video') {
                if (element.languages) {
                    valueField = {
                        src: {},
                        text: {}
                    };
                    valueField.src[this.currentLanguage.locale] = this.f[element.field + '_src'].value;
                    valueField.text[this.currentLanguage.locale] = this.f[element.field + '_text'].value;
                } else {
                    valueField = {
                        src: this.f[element.field + '_src'].value,
                        text: this.f[element.field + '_text'].value
                    };
                }
            } else {
                valueField = this.f[element.field].value;

                if (element.level) {
                    valueFieldLevel = this.f[element.field + '_level'].value;

                    if (!module.levels) {
                        module.levels = {};
                    }
                    module.levels[element.field] = valueFieldLevel;
                }
            }
            if (this.isFilesManagerField(element) || (element.languages && element.type !== 'category-relation' && element.type !== 'icons' && element.type !== 'social-video')) {
                if (element.configurations && element.configurations.inConfigField) {                    
                    if (!this.data.config) {
                        this.data.config = {};
                    }                    
                    module.config[element.field] = this.localeUtils.getDataLocaleToForm(element.field, module.config[element.field], valueField, locale);
                } else {                    
                    module[element.field] = this.localeUtils.getDataLocaleToForm(element.field, module[element.field], valueField, locale);
                }
            } else if (element.type === 'icons') {                
                if (element.languages && (!element.configurations || !element.configurations.has_languages)) {                    
                    if (element.configurations && element.configurations.inConfigField) {                        
                        module.config[element.field] = this.localeUtils.getDataLocaleToForm(element.field, module.config[element.field], valueField, locale);
                    } else {
                        module[element.field] = this.localeUtils.getDataLocaleToForm(element.field, module[element.field], valueField, locale);
                    }
                } else {
                    if (element.configurations && element.configurations.inConfigField) {
                        module.config[element.field] = valueField;
                    } else {
                        module[element.field] = valueField;
                    }
                }
            } else if (element.type === 'category-relation') {
                if (!element.hide) {
                    const fieldFinal = element.field_final ? element.field_final : element.field;
                    if (element.configurations && element.configurations.inConfigField) {
                        if (!module.config) {
                            module.config = {};
                        }
                        module.config[fieldFinal] = valueField;
                    } else {
                        module[fieldFinal] = valueField;
                    }
                }
            } else if (element.type === 'social-video') {
                module[element.field] = valueField;
            } else {
                if (element.configurations && element.configurations.inConfigField) {
                    if (!module.config) {
                        module.config = {};
                    }
                    module.config[element.field] = valueField;
                } else {
                    module[element.field] = valueField;
                }

            }
            // console.log('modul222e', module);       

        });

        console.log('module', module);        

        return module;
    }

    save() {
        const locale = this.currentLanguage.locale;

        let module = this.getDataCommon();
        module = this.getDataToFormCommonLanguages(module);
        module = this.setItems(module);
        module = this.setConfigurations(module);
        module = ModulesUtils.completeDataEmpty(module, locale);

        console.log('MODULE', module);

        this.data.action === 'add' ? this.create(module) : this.update(module);
    }


    setCtasInfo(items) {
        this.ctas = items;
        this.ctasChange++;
    }

    setCategoryRelation(data) {
        this.f[data.item.field].setValue(data.values);
    }

    setIconsInfo(items) {
        this.icons = items;
    }

    initSelects() {
        this.elementsForm.forEach(element => {
            if (element.type === 'select') {
                this.changeSelect(element);
            }
        });
    }
    changeSelect(item) {
        if (!item.configurations.is_option_relation) {
            return;
        }

        const valueActive = this.f[item.field].value;

        let fieldReferenceActive = null;
        const fields = [];
        item.configurations.options.forEach(element => {
            if (element.field_reference) {
                fields.push(element.field_reference);
                if (element.value === valueActive) {
                    fieldReferenceActive = element.field_reference;
                }
            }
        });

        this.elementsForm.forEach(element => {
            if (fields.indexOf(element.field) > -1) {
                this.setVisibility(element.field, (element.field !== fieldReferenceActive));
            }
        });


    }

    getVisibilityFromFieldParent(element) {
        if (!element.configurations || !element.configurations.parent_reference) {
            return false;
        }

        let valueActiveField;
        const valueActive = this.f[element.configurations.parent_reference].value;
        this.elementsForm.forEach(item => {
            if (item.field === element.configurations.parent_reference) {

                item.configurations.options.forEach(option => {
                    if (option.value === valueActive) {
                        valueActiveField = option.field_reference;
                    }
                });
            }
        });

        return valueActiveField !== element.field;
    }

    changingTab(ev) {
      this.tabChange++;
  }
}
