import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { BasicAuthInterceptor } from './shared/helpers/basic-auth.interceptor';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';

import { environment } from '@environments/environment';

import { StoreModule } from '@ngrx/store';
import * as Reducers from './store/index';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';


import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { EventsService } from './shared/services/events.service';
import { RoutesService } from './shared/services/routes.service';

import { LoginComponent } from './cms-access/login/login.component';
import { RememberComponent } from './cms-access/remember/remember.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AndresComponent } from './cms/pages/andres/andres.component';
import { TestComponent } from './cms/pages/test/test.component';

import { UtilsService } from './shared/services/utils.service';

import { PasswordComponent } from './cms-access/password';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { AppConfigService } from './shared/services/app-config.service';


registerLocaleData(localeEs, 'es-Es', localeEsExtra);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RememberComponent,
    PasswordComponent,
    NotFoundComponent,
    AndresComponent,
    TestComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,

    HttpClientModule,
    // CustomRequest,

    SharedModule.forRoot(),

    // Redux
    StoreModule.forRoot(Reducers.reducers),
    EffectsModule.forRoot(Reducers.effects),
    StoreRouterConnectingModule.forRoot({
        stateKey: 'router'
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    { provide: APP_INITIALIZER, useFactory: (config: AppConfigService) => () => config.load(), deps: [AppConfigService], multi: true },
    EventsService,
    RoutesService,
    UtilsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
