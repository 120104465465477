import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomRequest } from '../helpers/custom-request';
import { I18n } from '../models';
import { Experience } from '../models/experience';
import { PagesService } from './pages.service';


@Injectable({ providedIn: 'root' })
export class ExperiencesService {
    constructor(
        private request: CustomRequest,
        private pagesService: PagesService
    ) { }

    getFilters() {
        // return this.request.get(`${environment.apiUrl}/experience`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/experience`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/experience/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/experience/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/experience/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/experience/` + id);
    }

    order(data) {
        return this.request.post(`${environment.apiUrl}/experience/order`, data);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/experience/` + id + `/enabled/` + status, null);
    }

    transformDataFromApi(data: any) {
        const experience = new Experience();

        /**
            _id: string;
            enabled: boolean;
            name: string;
            experience: any;
            response: any;
            sites: Array<string>;
            created_at: string;
            updated_at: string;
            published_at: string;
        */

        experience._id = data._id;
        experience.checked = data.checked;
        experience.enabled = data.enabled;
        experience.name = data.name;
        experience.experience = data.experience;
        experience.response = data.response;
        experience.sites = data.sites;
        experience.created_at = data.created_at;
        experience.updated_at = data.updated_at;
        experience.published_at = data.published_at;
        
        return experience;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }

    prepareToJsonLangs(dataJson: any, langs: Array<I18n>) {     
        const newJsonData = {
            experiences: this.pagesService.setNewJsonElements(dataJson, langs)
        };   

        return newJsonData;
    }
}