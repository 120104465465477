import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomRequest } from '../helpers/custom-request';
import { Office } from '../models/office';
import { ProvincesService } from './provinces.service';
import { City } from '../models/city';
import { OfficesService } from './offices.service';

@Injectable({ providedIn: 'root' })
export class CitiesService {
    [x: string]: any;
    constructor(
        private request: CustomRequest,
        private provincesService: ProvincesService,
        // private officesService: OfficesService

    ) { }

    getFilters() {
        const data = { paginator: { perPage: 500, currentPage: 1}, filters: {} };
        return this.request.get(`${environment.apiUrl}/province`, data);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/city`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/city/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/city/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/city/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/city/` + id);
    }

    transformDataFromApi(data: any) {
        const city = new City();

        city._id = data._id;
        city.name = data.name;
        city.code = data.code;

        city.province = data.province;
        // if(city.province && typeof(city.province) === "string") {
        //     this.provincesService.detail(city.province).subscribe(data => {
        //         city.province = data;
        //     });
        // }
        
        city.events = data.events;
        // city.offices = this.officesService.transformListFromApi(data.offices);
        city.offices = data.offeices;

        city.created_at = data.created_at;
        city.updated_at = data.updated_at;

        return city;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}