import { Action } from '@ngrx/store';

// EXPERIENCES
export const DO_GET_INFO_EXPERIENCES = '[Experiences] Get Info Experiences';
export class DoGetInfoExperiences implements Action {
    type = DO_GET_INFO_EXPERIENCES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_EXPERIENCES_OK = '[Experiences] Get Info Experiences OK';
export class DoGetInfoExperiencesOK implements Action {
    type = DO_GET_INFO_EXPERIENCES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_EXPERIENCES_KO = '[Experiences] Get Info Experiences KO';
export class DoGetInfoExperiencesKO implements Action {
    type = DO_GET_INFO_EXPERIENCES_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Experiences] Get Info Filters';
export class DoGetInfoExperiencesFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Experiences] Get Info Filters OK';
export class DoGetInfoExperiencesFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Experiences] Get Info Filters KO';
export class DoGetInfoExperiencesFiltersKO implements Action {
    type = DO_GET_INFO_EXPERIENCES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Experiences] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}

export type All = DoGetInfoExperiences | DoGetInfoExperiencesOK | DoGetInfoExperiencesKO | 
    DoGetInfoExperiencesFilters | DoGetInfoExperiencesFiltersOK | DoGetInfoExperiencesFiltersKO |
    DoSetSelectedFilters;
