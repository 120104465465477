import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Obituary } from '../models/obituary';

import { PagesService } from './pages.service';
import { CustomRequest } from '../helpers/custom-request';
import { Page } from '../models';


@Injectable({ providedIn: 'root' })
export class ObituariesService {
    constructor(
        private pagesService: PagesService,
        private request: CustomRequest
    ) { }

    // getFilters() {
    //     return this.request.get(`${environment.apiUrl}/obituary-category`);
    // }

    get(data) {
        return this.request.get(`${environment.apiUrl}/obituary`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/obituary/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/obituary/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/obituary/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/obituary/` + id);
    }

    setContent(data: any) {
        return this.request.post(`${environment.apiUrl}/obituary/` + data._id + `/content/`, data.content);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/obituary/` + id + `/enabled/` + status, null);
    }

    transformDataFromApi(dataPost: any) {
        const post = new Obituary();

        post._id = dataPost._id;
        post.content = dataPost.content;
        post.created_at = dataPost.created_at;
        post.design = dataPost.design;
        post.email = dataPost.email ? dataPost.email : "";
        post.enabled = dataPost.enabled;
        post.image = dataPost.image;
        post.mailing = dataPost.mailing;
        post.name_claimant = dataPost.name_claimant;
        post.surname_claimant = dataPost.surname_claimant;
        post.dni = dataPost.dni;
        post.name = dataPost.name;
        post.page = dataPost.page ? this.pagesService.transformDataFromApi(dataPost.page) : new Page();
        post.preview = dataPost.preview;
        post.published_at = dataPost.published_at;
        post.type = dataPost.type;
        post.updated_at = dataPost.updated_at;

        post.sites = dataPost.page && dataPost.page.sites ? dataPost.page.sites : null;
        post.checked = dataPost.checked ? dataPost.checked : false;

        // post.published_at = dataPost.published_at.slice(0, -1);
        // post.created_at = dataPost.created_at.slice(0, -1);
        // post.updated_at = dataPost.updated_at.slice(0, -1);

        return post;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}