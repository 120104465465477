import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as policiesCategoriesActions from './policies-categories.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { PoliciesCategoriesService } from '../../shared/services/policies-categories.service';



@Injectable()
export class PoliciesCategoriesEffects {

    constructor(
        private policiesCategoriesService: PoliciesCategoriesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoPoliciesCategories$: Observable<Action> = this.actions$.pipe(
        ofType(policiesCategoriesActions.DO_GET_INFO_POLICIES_CATEGORIES),
        switchMap((action: policiesCategoriesActions.DoGetInfoPoliciesCategories) => {
            return this.policiesCategoriesService.get(action).pipe(
                map( response => new policiesCategoriesActions.DoGetInfoPoliciesCategoriesOK(response)),
                catchError(error => of(new policiesCategoriesActions.DoGetInfoPoliciesCategoriesKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedFiltersCategories$: Observable<Action> = this.actions$.pipe(
        ofType(policiesCategoriesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: policiesCategoriesActions.DoSetSelectedFilters) => {
            return this.policiesCategoriesService.get(action).pipe(
                map( response => new policiesCategoriesActions.DoGetInfoPoliciesCategoriesOK(response)),
                catchError(error => of(new policiesCategoriesActions.DoGetInfoPoliciesCategoriesKO(error)))
            );
        }));
}
