import { newFromJson } from '../lib/helpers/utils';

export class User {
    id: number;
    user: string;
    password: string;
    name: string;
    token?: string;

    public static newFromJson(json: string): User | User[] {
        return newFromJson<User>(json, User);
    }
}