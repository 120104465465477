import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as eventsActions from './events.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { EventsPostService } from '../../shared/services/events-post.service';
import { DO_GET_INFO_FILTERS, DO_GET_INFO_EVENTS } from './events.actions';





@Injectable()
export class EventsEffects {

    constructor(
        private eventsService: EventsPostService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoEvents$: Observable<Action> = this.actions$.pipe(
        ofType(eventsActions.DO_GET_INFO_EVENTS),
        switchMap((action: eventsActions.DoGetInfoEvents) => {
            return this.eventsService.get(action).pipe(
                map( response => {
                    response.list = this.eventsService.transformListFromApi(response.list);
                    return new eventsActions.DoGetInfoEventsOK(response);
                }),
                catchError(error => of(new eventsActions.DoGetInfoEventsKO(error)))
            );
        }));

    @Effect()
    DoGetInfoEventsFilters$: Observable<Action> = this.actions$.pipe(
        ofType(eventsActions.DO_GET_INFO_FILTERS),
        switchMap((action: eventsActions.DoGetInfoEventsFilters) => {
            return this.eventsService.getFilters().pipe(
                map( response => new eventsActions.DoGetInfoEventsFiltersOK(response)),
                catchError(error => of(new eventsActions.DoGetInfoEventsFiltersKO(error)))
            );
        }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(eventsActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: eventsActions.DoSetSelectedFilters) => {
            return this.eventsService.get(action).pipe(
                map( response => new eventsActions.DoGetInfoEventsOK(response)),
                catchError(error => of(new eventsActions.DoGetInfoEventsKO(error)))
            );
        }));
}
