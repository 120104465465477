import { newFromJson } from '../lib/helpers/utils';

export class Admin {
    _id: number;
    name: string;
    email: string;
    password: string;
    enabled: boolean;

    public static newFromJson(json: string): Admin | Admin[] {
        return newFromJson<Admin>(json, Admin);
    }
}