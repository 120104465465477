import { Component, Input, EventEmitter, Output, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '../../services/routes.service';

import * as LayoutActions from '@app/store/layout/layout.actions';
import * as AsideActions from '@app/store/aside/aside.actions';
import { first } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { AppState } from '../../../store/index';
import { Observable, Subscription } from 'rxjs';
import { I18n } from '../../models/i18n';
import { SitesService } from '../../services/sites.service';

import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '@app/shared/components/dialog/dialog-confirm/dialog-confirm.component';
import { DialogErrorComponent } from '@app/shared/components/dialog/dialog-error/dialog-error.component';


import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PagesService } from '../../services/pages.service';
import { I18nService } from '../../services/18n.service';

declare var noScroll: any;

@Component({
    selector: 'app-page-content',
    templateUrl: './page-content.component.html',
})
export class PageContentComponent implements OnDestroy, OnChanges {

    @Input() boxes: any;
    @Input() idSection: string;
    @Input() idPage: string;
    @Input() routing: string;

    @Output() refresh = new EventEmitter<any>();

    currentLanguage: I18n;

    currentSite = null;

    confirmActive = false;
    currentIdxModule: number;
    currentBoxEditing: number;
    isChangingStatus: boolean;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private router: Router,
        public routesService: RoutesService,
        private sitesService: SitesService,
        private i18nService: I18nService,
        private pagesService: PagesService,
        public dialog: MatDialog
    ) {
        this.initSuscriptions();
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }


    initSuscriptions() {

        this.suscriptionsToManage$.push(this.i18nService.currentLanguage$.subscribe(
            i18n => {
                this.currentLanguage = i18n;
            }
        ));

        this.suscriptionsToManage$.push(this.sitesService.currentSite$.subscribe(
            site => {
                this.currentSite = site;
            }
        ));


        this.suscriptionsToManage$.push(this.store.select(state => state.aside.result).subscribe(res => {
            if (res && res.result) {
                this.saveModules(res);
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.index).subscribe(res => {
            if (!this.confirmActive) {
                this.currentIdxModule = res;
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.box).subscribe(res => {
            this.currentBoxEditing = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.delete).subscribe(res => {
            if (res && !this.confirmActive) {
                // this.deleteModule();
                this.confirmDeleteModule();
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.edit).subscribe(res => {
            if (res && !this.isChangingStatus) {
                this.editModule();
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.active).subscribe(res => {
            if (res) {
                this.changeStatusModule();
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.add).subscribe(res => {
            if (res) {
                this.addModuleCommon(res);
            }
        }));

    }

    showError(error) {
        const dialogRef = this.dialog.open(DialogErrorComponent, {
            // width: '300px',
            panelClass: 'modal-custom',
            data: { error }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.router.navigate([this.routesService.getRoute(this.routing, this.idSection)], {});
        });
    }

    refreshPage() {
        this.refresh.emit();
        this.confirmActive = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.boxes) {
            this.initBoxes();
        }
    }

    initBoxes() {
        if (!this.boxes) {
            this.boxes = [];
            return;
        }

        Object.keys(this.boxes).map(key => {
            this.boxes[key] = Object.keys(this.boxes[key]).map(key2 => this.boxes[key][key2]);
        });

        this.boxes = this.boxes;
    }


    addModule(indexBox: number) {
        noScroll.on();

        const content = this.boxes[indexBox];
        const data = {
            modules: content ? content : null,
            action: 'add',
            _id: this.idPage,
            box: indexBox
        };

        const payload = { mode: 'newModule', data, section: 'pages', box: indexBox };
        this.store.dispatch(new AsideActions.SetInfo(payload));
        this.store.dispatch(new LayoutActions.OpenAsideSecondary());
    }

    addModuleCommon(module) {
        this.vinculateModuleToBox(module);
    }

    editModule() {
        const index = this.currentIdxModule;
        const content = this.boxes[this.currentBoxEditing];
        const module = content[index].module;

        noScroll.on();

        const data = {
            modules: content,
            action: 'edit',
            index,
            _id: this.idPage,
            box: this.currentBoxEditing
        };

        const mode = module.type.view;
        const payload = {
            mode,
            data,
            section: 'pages',
            box: this.currentBoxEditing,
            title: module.type.name,
            idType: module.type._id,
            type: module.type
        };
        this.store.dispatch(new AsideActions.SetInfo(payload));
        this.store.dispatch(new LayoutActions.OpenAsideSecondary());
    }

    saveModules(res) {
        if (res.modules) {
            this.doSave(res.modules);
        } else {
            this.createModule(res.module);
        }
    }

    createModule(module) {
        this.pagesService.createModule(module).pipe(
            first()).subscribe(data => {
                this.vinculateModuleToBox(data);
            },
                error => {
                    this.store.dispatch(new LayoutActions.CloseAsideSecondary());
                    this.showError(error);
                    this.store.dispatch(new AsideActions.ResetInfo());
                    this.confirmActive = false;
                }
            );
    }

    vinculateModuleToBox(module) {
        const currentBox = this.boxes[this.currentBoxEditing];
        const boxNumber = this.currentBoxEditing;

        console.log('CURRENT BOX', currentBox);

        let order = (currentBox && currentBox.length > 0) ? (currentBox[(currentBox.length - 1)].order + 1) : 1;

        console.log('order', order);

        const box = {
            box: boxNumber ? boxNumber : 1,
            // order: (currentBox ? (Object.keys(currentBox).length + 1) : 1),
            order: order,
            module: module._id,
            page: this.idPage,
            site: this.currentSite._id
        };
        this.pagesService.createBox(box).pipe(
            first()).subscribe(data => {
                this.refreshPage();
            },
                error => {
                    this.store.dispatch(new LayoutActions.CloseAsideSecondary());
                    this.showError(error);
                    this.store.dispatch(new AsideActions.ResetInfo());
                    this.confirmActive = false;
                }
            );
    }

    doSave(modules) {
        const currentModules = this.boxes[this.currentBoxEditing];
        const currentModule = currentModules[this.currentIdxModule].module;
        const type = currentModule.type._id;

        const moduleToSave = modules[this.currentIdxModule].module;
        moduleToSave.type = type;

        // console.log('moduleToSave', moduleToSave);

        this.pagesService.setModule(moduleToSave).pipe(
            first()).subscribe(data => {
                this.boxes[this.currentBoxEditing][this.currentIdxModule].module = data;

                this.store.dispatch(new LayoutActions.CloseAsideSecondary());
                this.store.dispatch(new AsideActions.ResetInfo());
                this.confirmActive = false;
            },
                error => {
                    this.store.dispatch(new LayoutActions.CloseAsideSecondary());
                    this.showError(error);
                    this.store.dispatch(new AsideActions.ResetInfo());
                    this.confirmActive = false;
                }
            );
    }

    deleteModule() {
        const index = this.currentIdxModule;
        const content = this.boxes[this.currentBoxEditing];
        // console.log("content", this.boxes, this.currentIdxModule, this.currentBoxEditing);
        const modulePage = content[index];

        this.pagesService.deleteModuleBox(modulePage._id).pipe(
            first()).subscribe(data => {
                this.refreshPage();
            },
                error => {
                    console.log('ERROR PETICION', error);
                    // return this.router.navigate([this.routesService.getRoute('blog')], {});
                    this.showError(error);
                }
            );
    }

    confirmDeleteModule() {

        if (this.confirmActive || this.currentIdxModule === null || this.currentBoxEditing === null) {
            return;
        }
        this.confirmActive = true;

        const dialogRef = this.dialog.open(DialogConfirmComponent, {
            // width: '300px',
            panelClass: 'modal-custom',
            data: { name: 'Eliminar el módulo', id: this.currentIdxModule }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === this.currentIdxModule) {
                this.deleteModule();
            } else {
                this.store.dispatch(new AsideActions.ResetInfo());
                this.confirmActive = false;
            }
        });
    }

    getBoxToUpdate(boxData) {
        const boxToSet = [];

        let index = 1;
        let element;
        Object.keys(boxData).forEach((key) => {
            element = boxData[key];
            boxToSet.push({
                _id: element._id,
                box: element.box,
                order: index
            });
            index++;
        });

        return boxToSet;

    }

    drop(event: CdkDragDrop<string[]>, index: number) {
        const boxData = this.boxes[index];
        moveItemInArray(boxData, event.previousIndex, event.currentIndex);

        const box = this.getBoxToUpdate(boxData);

        this.pagesService.orderBox(box).pipe(
            first()).subscribe(data => {
                // this.refreshPage();
            },
                error => {
                    this.store.dispatch(new LayoutActions.CloseAsideSecondary());
                    this.showError(error);
                    this.store.dispatch(new AsideActions.ResetInfo());
                    this.confirmActive = false;
                }
            );
    }

    changeStatusModule() {
        const module = this.boxes[this.currentBoxEditing][this.currentIdxModule].module;

        if (!module) {
            this.showError('No existe elemento con ese id');
        }

        this.pagesService.changeStatusModule(module._id, (module.enabled ? 0 : 1)).pipe(
            first()).subscribe(data => {
                this.boxes[this.currentBoxEditing][this.currentIdxModule].module.enabled = data.enabled;
                this.store.dispatch(new AsideActions.EndToActive());

                this.isChangingStatus = false;
            },
                error => {
                    console.log('ERROR PETICION', error);
                    // return this.router.navigate([this.routesService.getRoute('pages')], {});
                    this.showError(error);
                    this.isChangingStatus = false;
                }
            );
    }

    isBoxesEmpty() {
        if (!this.boxes
            || (this.boxes instanceof Object && !Object.keys(this.boxes).length)
            || (this.boxes instanceof Array && !this.boxes.length)) {
            return true;
        }

        return false;
    }

    keepOrder = (a, b) => {
        return a;
    }

    addBlock() {
        if (!this.boxes) {
            this.boxes = [];
            return;
        }

        let lastKey = 0;
        Object.keys(this.boxes).map(key => {
            // this.boxes[key] = Object.keys(this.boxes[key]).map(key2 => this.boxes[key][key2]);
            if (parseInt(key,10) == (lastKey +1)) {
                lastKey = parseInt(key,10);
            }
        });

        this.boxes[(lastKey + 1)] = [];
        this.boxes = this.boxes;
    }

}
