import { Action } from '@ngrx/store';

// VIDEOS
export const DO_GET_INFO_VIDEOS = '[Videos] Get Info Videos';
export class DoGetInfoVideos implements Action {
    type = DO_GET_INFO_VIDEOS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_VIDEOS_OK = '[Videos] Get Info Videos OK';
export class DoGetInfoVideosOK implements Action {
    type = DO_GET_INFO_VIDEOS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_VIDEOS_KO = '[Videos] Get Info Videos KO';
export class DoGetInfoVideosKO implements Action {
    type = DO_GET_INFO_VIDEOS_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Videos] Get Info Filters';
export class DoGetInfoVideosFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Videos] Get Info Filters OK';
export class DoGetInfoVideosFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Videos] Get Info Filters KO';
export class DoGetInfoVideosFiltersKO implements Action {
    type = DO_GET_INFO_VIDEOS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Videos] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoVideos | DoGetInfoVideosOK | DoGetInfoVideosKO |
    DoGetInfoVideosFilters | DoGetInfoVideosFiltersOK | DoGetInfoVideosFiltersKO 
    | DoSetSelectedFilters
    ;
