import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomRequest } from '../helpers/custom-request';

@Injectable({ providedIn: 'root' })
export class CategoryRelationService {
    constructor(
        private request: CustomRequest
    ) { }

    get(table) {
        const data = { paginator: { perPage: 500, currentPage: 1}, filters: {} };
        return this.request.get(`${environment.apiUrl}/`+ table, data);
    }

    
}