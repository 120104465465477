import { Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as AsideActions from '@app/store/aside/aside.actions';
import { PagesService } from '@app/shared/services/pages.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-new-module-form',
    templateUrl: './new-module.component.html',
    providers: []
})
export class NewModuleComponent implements OnDestroy {

    data: any;
    section: string;
    modules: Array<any>;

    breadcrumb = null;

    itemForm: FormGroup;
    submitted = false;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        private pagesService: PagesService
    ) {
        this.initSuscriptions();
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    initSuscriptions() {
        this.suscriptionsToManage$.push(this.store.select(state => state.aside.data).subscribe(res => {
            this.data = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.section).subscribe(res => {
            this.section = res;

            this.initModules();
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.toSave).subscribe(res => {
            if (res) {
                this.onSubmit();
            }
        }));
    }

    initModules() {
        this.initForm();

        switch (this.section) {
            case 'pages':
                this.getFromApi();
                break;

            default:
                this.modules = [
                    { name: 'Texto', view: 'text' },
                    { name: 'Texto + imagen izquierda', view: 'image_left' },
                    { name: 'Texto + imagen derecha', view: 'image_right' },
                    { name: 'Imagen ancho completo', view: 'image' },
                    { name: 'Frase destacada + texto', view: 'cite_left' },
                    { name: 'Texto + frase destacada', view: 'cite_right' },
                ];
                break;
        }
    }

    getName(item) {
        switch (this.section) {
            case 'pages': return ('[' + item.view + '] ' + item.name);
            default: return this.getNameByCode(item.view);
        }
    }

    getNameByCode(view) {
        const module = this.modules.filter(x => x.view === view)[0];
        return module.name;
    }

    getFromApi() {
        const dataRequest = { paginator: { perPage: 500, currentPage: 1 }, filters: {} };
        this.pagesService.getModuleTypes(dataRequest).pipe(
            first()).subscribe(data => {
                // this.modules = this.removeDuplicates(data.list);
                // console.log('modules', this.modules);
                // SI QUIERES UN SELECT DE 500 OPCIONES DESCOMENTA LO DE ABAJO
                this.modules = data.list;
            });
    }

    // CUSTOM FUNCTION: eliminar dupilicados para el select de modulos
    removeDuplicates(data: any) {
        let res: any[] = [];
        let flag: boolean = false;
        for (let i = 0; i < data.length; i++) {
            for (let z = 0; z < res.length; z++) {
                if (data[i]["name"] === res[z]["name"]) {
                    flag = true;
                }
            }
            if (!flag) {
                res[res.length] = data[i];
            }
            flag = false;
        }

        return res;
    }

    get f() { return this.itemForm.controls; }

    initForm() {
        this.itemForm = this.formBuilder.group({
            module: ['', Validators.required]
        });
    }

    cancelSave() {
        this.store.dispatch(new AsideActions.CancelToSave());
    }

    onSubmit() {
        this.submitted = true;
        if (this.itemForm.invalid) {
            return this.cancelSave();
        }

        this.create();
    }

    create() {
        const code = this.f.module.value;
        let itemType = null;
        let name = null;
        this.modules.forEach(item => {
            if (item.view === code) {
                name = item.name;
                itemType = item;
            }
        });

        let mode = this.f.module.value;
        mode = mode === 'HEADER' ? '' : mode;

        let payload;
        if (this.section === 'pages') {
            const type = this.findModuleByView(mode);
            payload = { mode, title: name, id_type: type._id, type: itemType };
        } else {
            payload = { mode, title: name };
        }

        this.store.dispatch(new AsideActions.ChangeMode(payload));
    }

    findModuleByView(view) {
        return this.modules.filter(x => x.view === view)[0];
    }
}