import { Page } from './page';

export class Obituary {
    _id: string;
    content: any;
    created_at: string;
    design: number;
    email: string;
    enabled: boolean;
    image: any;
    image_name: any;
    mailing: Array<any>;
    name_claimant: any;
    surname_claimant: any;
    dni: any;
    name: any;
    page: Page;
    preview: any;
    published_at: string;
    type: Array<string>;
    updated_at: string;

    sites: Array<string>;
    checked: boolean;

    // alt: any;
    // highlighted: boolean;
    // legend: any;
    // url: string;
    // tags: any;
    // show_image_header_detail: boolean;
    
}