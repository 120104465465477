import { PageType } from './page-type';
import { City } from './city';
import { Location } from './location';
import { Page } from './page';
import { OfficeType } from './office-type';
import { Province } from './province';

export class Office {
    _id: string;

    address: any;
    postal_code: any;
    business_link: string;
    city: any;
    province: Province;
    name: any;
    name_location: any;
    description: any;
    email1: string;
    email2: string;
    email_commercial: string;
    phone1: string;
    phone2: string;
    fax: string;
    image: string;
    image_name: string;
    location: Location;
    schedule: any;
    main: boolean;
    navision: string;

    enabled: boolean;

    type: OfficeType;

    page: Page;

    created_at: string;
    updated_at: string;
}