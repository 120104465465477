import { Action } from '@ngrx/store';

// BLOG
export const DO_GET_INFO_TYPES = '[Pages Types] Get Info Types';
export class DoGetInfoTypes implements Action {
    type = DO_GET_INFO_TYPES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_TYPES_OK = '[Pages Types] Get Info Types OK';
export class DoGetInfoTypesOK implements Action {
    type = DO_GET_INFO_TYPES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_TYPES_KO = '[Pages Types] Get Info Types KO';
export class DoGetInfoTypesKO implements Action {
    type = DO_GET_INFO_TYPES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Pages Types] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoTypes | DoGetInfoTypesOK | DoGetInfoTypesKO 
    | DoSetSelectedFilters
    ;
