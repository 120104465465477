export class Experience {
    _id: string;
    checked: boolean;
    enabled: boolean;
    name: string;
    experience: any;
    response: any;
    sites: Array<string>;
    created_at: string;
    updated_at: string;
    published_at: string;

}