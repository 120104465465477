import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Page } from '../models/page';
import { PagesTypesService } from './pages-types.service';
import { CustomRequest } from '../helpers/custom-request';
import { I18n } from '../models';

@Injectable({ providedIn: 'root' })
export class PagesService {
    constructor(
        private request: CustomRequest,
        private pagesTypesService: PagesTypesService
    ) { }

    getFilters() {
        const data = { paginator: { perPage: 500, currentPage: 1 }, filters: {} };
        return this.request.get(`${environment.apiUrl}/page-type`, data);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/page`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/page/` + id + '?boxes=1');
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/page/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/page/`, data);
    }

    duplicate(id) {
        return this.request.post(`${environment.apiUrl}/page/`+ id + `/duplicate`, null);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/page/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/page/` + id + `/enabled/` + status, null);
    }

    module(id) {
        return this.request.get(`${environment.apiUrl}/module/` + id, null);
    }

    createModule(data) {
        return this.request.post(`${environment.apiUrl}/module/`, data);
    }

    setModule(data) {
        return this.request.post(`${environment.apiUrl}/module/` + data._id, data);
    }

    changeStatusModule(id, status) {
        return this.request.post(`${environment.apiUrl}/module/` + id + `/enabled/` + status, null);
    }

    getModuleTypes(data) {
        return this.request.get(`${environment.apiUrl}/module-type`, data);
    }

    createBox(data: any) {
        return this.request.post(`${environment.apiUrl}/page-module/`, data);
    }

    setBox(data: any) {
        return this.request.post(`${environment.apiUrl}/page-module/` + data._id, data.box);
    }

    orderBox(data: any) {
        return this.request.post(`${environment.apiUrl}/page-module/order`, data);
    }

    deleteModuleBox(id: string) {
        return this.request.delete(`${environment.apiUrl}/page-module/` + id);
    }

    transformDataFromApi(data: any) {
        const page = new Page();

        page.boxes = this.transformBoxes(data);
        page.modules = data.modules;
        page.sites = data.sites;
        page.urls = data.urls;
        page._id = data._id;
        page.code = data.code;
        page.enabled = data.enabled;
        page.hide_menu = data.hide_menu;
        page.type_header = data.type_header;
        page.type_footer = data.type_footer;
        page.seo = data.seo;
        page.title = data.title;
        page.type = this.pagesTypesService.transformDataFromApi(data.type);
        page.created_at = data.created_at;
        page.updated_at = data.updated_at;
        page.related_id = data.related_id;
        page.related_table = data.related_table;
        page.deleteable = (data.hasOwnProperty("deleteable") && data.deleteable === false) ? false : true;

        page.classes = data.classes;

        page.menu_fixed_option = data.menu_fixed_option;
        page.menu_fixed_option_link = data.menu_fixed_option_link;

        return page;

    }

    transformBoxes(data) {
        if (!data.boxes) {
            return null;
        }

        return data.boxes;
    }

    getSeoInformationOnPage(page, locale) {
        return page.seo[locale];
    }

    getSeo(page, lang) {
        if (!page.seo) {
            return null;
        }

        return page.seo[lang];
    }

    exportToJson(data: any) {
        return this.request.post(`${environment.apiUrl}/page/export-json`, data);
    }

    prepareToJsonLangs(dataJson: any, langs: Array<I18n>) {        
        return this.setNewJson(dataJson, langs);
    }

    setNewJson(dataJson: any, langs: Array<I18n>) {
        
        const newJsonData = {
            pages: this.setNewJsonElements(dataJson, langs)
        };        

        return newJsonData;
    }

    setNewJsonElements(dataJson: any, langs: Array<I18n>) {
        
        const pages = [];

        dataJson.forEach(element => {
            const newJson = {};

            for (const key in element) {                
                if (key != 'urls') {
                    newJson[key] = this.prepareFieldInLangs(element[key], langs, key);
                } else {
                    newJson[key] = this.prepareUrlsInLangs(element[key], langs);
                }
            }

            pages.push(newJson);

        });

        return pages;
    }

    prepareUrlsInLangs(field, langs) {        
        let mainSlug = '';
        let urls = [];

        let langsWithUrl = [];

        if (field instanceof Array) {
            field.forEach(element => {
                urls.push(element);
                // langsWithUrl.push(element.i18n_code);
                langsWithUrl.push(element.i18n);

                if (mainSlug == '') {
                    const slugSplit = element.url.split('/');
                    mainSlug = slugSplit[slugSplit.length - 1];
                }
            });
        }


        langs.forEach(element => {
            // if ( langsWithUrl.indexOf(element.locale) < 0) {
            if ( langsWithUrl.indexOf(element._id) < 0) {
                urls.push({
                    "i18n_code": element.locale,
                    "url": "/" + (element.iso != 'es' ? (element.iso + '/') : '') + mainSlug,
                })
            }
        });

        return urls;
    }

    prepareFieldInLangs(field, langs, key) {
        if (typeof field == "string" ||
            typeof field == "number" ||
            typeof field == "boolean") {

            return field;
        }

        if (field instanceof Array) {
            if (key == 'urls') {
                return this.prepareUrlsInLangs(field, langs);
            }

            return this.prepareArray(field, langs, key);
        }

        if (typeof field == "object") {
            return this.prepareObject(field, langs, key);
        }

        return field;
    }

    prepareArray(field, langs, key) {
        const newField = [];

        field.forEach(element => {
            newField.push(this.prepareFieldInLangs(element, langs, key));
        });

        return newField;
    }

    prepareObject(field, langs, key) {
        const newField = {};

        for (const key in field) {            
            newField[key] = this.prepareFieldInLangs(field[key], langs, key);

            if (this.checkKeyLang(key, langs)) {
                langs.forEach(element => {
                    if (element.locale != key && !this.hasLangInField(field, element.locale) ) {
                        newField[element.locale] = newField[key];
                        // newField[element.locale] = newField["es"];           
                    }
                });
            }
        }

        return newField;
    }

    checkKeyLang(key, langs) {
        let exist = false;

        langs.forEach(element => {
            if (element.locale == key) {
                exist = true;
            }
        });

        return exist;
    }

    hasLangInField(field, lang) {
        let exist = false;

        for (const key in field) {
            if (key == lang) {
                exist = true;
            }            
        }

        return exist;
    }
}
