import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, fromEventPattern, Subscription } from 'rxjs';
import { AppState } from '../../../store/index';
import * as LayoutActions from '../../../store/layout/layout.actions';
import * as AsideActions from '@app/store/aside/aside.actions';

import { environment } from '@environments/environment';
import { SitesService } from '../../services/sites.service';
import { FormGroup, FormBuilder } from '@angular/forms';

declare var noScroll: any;

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  host: {'class': 'main-panel'}
})
export class WrapperComponent implements OnDestroy {

  isCollapseAside: boolean;
  isMobileMenuOpen: boolean;
  openAsideConfig: boolean;
  openAsideSecondary: boolean;
  openAsideItems: boolean;

  languages: Array<any>;

  hasSites: boolean;
  sites: Array<any>;
  currentSite = null;

  currentSiteForm: FormGroup;

  private suscriptionsToManage$: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private sitesService: SitesService,
    private formBuilder: FormBuilder,
  ) {

    this.suscriptionsToManage$.push(this.store.select(state => state.layout.isCollapseAside).subscribe(res => {
      this.isCollapseAside = res;
    }));

    this.suscriptionsToManage$.push(this.store.select(state => state.layout.isMobileMenuOpen).subscribe(res => {
      this.isMobileMenuOpen = res;
    }));

    this.suscriptionsToManage$.push(this.store.select(state => state.layout.openAsideConfig).subscribe(res => {
        this.openAsideConfig = res;
    }));

    this.suscriptionsToManage$.push(this.store.select(state => state.layout.openAsideSecondary).subscribe(res => {
        this.openAsideSecondary = res;
    }));

    this.suscriptionsToManage$.push(this.store.select(state => state.layout.openAsideItems).subscribe(res => {
        this.openAsideItems = res;
    }));
    
    this.sitesService.sitesData$.subscribe(
      sites => {
        this.sites = sites;
        this.hasSites = this.sites.length > 1;
      }
    );
    

    this.languages = [];

    // this.sites = environment.markets;    
    // this.sites = this.sitesService.getSites();
    // console.log('sites ...', this.sites);
    // this.hasSites = this.sites.length > 1;
  }

  ngOnDestroy() {
      this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
  }

  clickBackdrop() {
    // Lock body scroll
    
    if (!this.openAsideItems) {
      noScroll.off();

      this.store.dispatch(new LayoutActions.CloseBackdrop());
      this.store.dispatch(new AsideActions.ResetInfo());
    }
  }

  get f() { return this.currentSiteForm.controls; }

  setSite(value) {
    // console.log(value);
    // console.log('site nuevo', this.f.siteControl.value);
    // console.log('value', this.currentSiteForm.get('siteControl').value);
    // console.log('value', this.currentSiteForm.value.siteControl);
  }

}
