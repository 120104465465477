import { CatalogCategory } from './catalog-category';
import { CatalogSubcategory } from './catalog-subcategory';
import { Page } from './page';

export class CatalogCatalog {
    _id: string;

    code: string;
    name: any;
    description: any;
    details: any;
    download: any;
    icons: any;
    image: any;
    image_name: any;
    media: any;
    features: any;
    only_list: boolean;
    order: number;
    enabled: boolean;

    category: CatalogCategory;
    subcategory: CatalogSubcategory;

    page: Page;
    sites: Array<string>;

    created_at: string;
    updated_at: string;
}
