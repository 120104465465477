import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as blogActions from './blog.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { BlogService } from '../../shared/services/blog.service';
import { DO_GET_INFO_FILTERS, DO_GET_INFO_BLOG } from './blog.actions';





@Injectable()
export class BlogEffects {

    constructor(
        private blogService: BlogService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoBlog$: Observable<Action> = this.actions$.pipe(
        ofType(blogActions.DO_GET_INFO_BLOG),
        switchMap((action: blogActions.DoGetInfoBlog) => {
            return this.blogService.get(action).pipe(
                map( response => {
                    response.list = this.blogService.transformListFromApi(response.list);
                    return new blogActions.DoGetInfoBlogOK(response);
                }),
                catchError(error => of(new blogActions.DoGetInfoBlogKO(error)))
            );
        }));

    @Effect()
    DoGetInfoBlogFilters$: Observable<Action> = this.actions$.pipe(
        ofType(blogActions.DO_GET_INFO_FILTERS),
        switchMap((action: blogActions.DoGetInfoBlogFilters) => {
            return this.blogService.getFilters().pipe(
                map( response => new blogActions.DoGetInfoBlogFiltersOK(response)),
                catchError(error => of(new blogActions.DoGetInfoBlogFiltersKO(error)))
            );
        }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(blogActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: blogActions.DoSetSelectedFilters) => {
            return this.blogService.get(action).pipe(
                map( response => new blogActions.DoGetInfoBlogOK(response)),
                catchError(error => of(new blogActions.DoGetInfoBlogKO(error)))
            );
        }));
}
