import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';


// Router
import { LayoutState } from './layout/layout.state';
import { LayoutReducer } from './layout/layout.reducer';
import { LayoutEffects } from './layout/layout.effects';

import { RouterEffects } from './router/router.effects';
import { RouterState } from './router/router.state';

import { AuthEffects } from './auth/auth.effects';
import { AuthReducer } from './auth/auth.reducer';
import { AuthState } from './auth/auth.state';

import { PagesReducer } from './pages/pages.reducer';
import { PagesState } from './pages/pages.state';
import { PagesEffects } from './pages/pages.effects';

import { BlogEffects } from './blog/blog.effects';
import { BlogState } from './blog/blog.state';
import { BlogReducer } from './blog/blog.reducer';

import { BlogCategoriesReducer } from './blog-categories/blog-categories.reducer';
import { BlogCategoriesState } from './blog-categories/blog-categories.state';
import { BlogCategoriesEffects } from './blog-categories/blog-categories.effects';

import { LeadsEffects } from './leads/leads.effects';
import { LeadsReducer } from './leads/leads.reducer';
import { LeadsState } from './leads/leads.state';

import { LeadsTypesState } from './leads-types/leads-types.state';
import { LeadsTypesEffects } from './leads-types/leads-types.effects';
import { LeadsTypesReducer } from './leads-types/leads-types.reducer';
import { AsideEffects } from './aside/aside.effects';
import { AsideState } from './aside/aside.state';
import { AsideReducer } from './aside/aside.reducer';
import { PagesTypesEffects } from './pages-types/pages-types.effects';
import { PagesTypesState } from './pages-types/pages-types.state';
import { PagesTypesReducer } from './pages-types/pages-types.reducer';
import { AsideItemEffects } from './aside-item/aside-item.effects';
import { AsideItemState } from './aside-item/aside-item.state';
import { AsideItemReducer } from './aside-item/aside-item.reducer';
import { GlossariesEffects } from './glossaries/glossaries.effects';
import { GlossariesState } from './glossaries/glossaries.state';
import { GlossariesReducer } from './glossaries/glossaries.reducer';
import { CatalogsEffects } from './catalogs/catalogs.effects';
import { CatalogsReducer } from './catalogs/catalogs.reducer';
import { CatalogsState } from './catalogs/catalogs.state';
import { TestimonialsEffects } from './testimonials/testimonials.effects';
import { TestimonialsState } from './testimonials/testimonials.state';
import { TestimonialsReducer } from './testimonials/testimonials.reducer';
import { BlogSecondEffects } from './blog-second/blog-second.effects';
import { BlogSecondCategoriesEffects } from './blog-second-categories/blog-second-categories.effects';
import { BlogSecondState } from './blog-second/blog-second.state';
import { BlogSecondCategoriesState } from './blog-second-categories/blog-second-categories.state';
import { BlogSecondReducer } from './blog-second/blog-second.reducer';
import { BlogSecondCategoriesReducer } from './blog-second-categories/blog-second-categories.reducer';
import { FaqsEffects } from './faqs/faqs.effects';
import { FaqsCategoriesEffects } from './faqs-categories/faqs-categories.effects';
import { FaqsState } from './faqs/faqs.state';
import { FaqsCategoriesState } from './faqs-categories/faqs-categories.state';
import { FaqsReducer } from './faqs/faqs.reducer';
import { FaqsCategoriesReducer } from './faqs-categories/faqs-categories.reducer';
import { PromotionsState } from './promotions/promotions.state';
import { PromotionsReducer } from './promotions/promotions.reducer';
import { PromotionsEffects } from './promotions/promotions.effects';
import { PoliciesEffects } from './policies/policies.effects';
import { PoliciesCategoriesEffects } from './policies-categories/policies-categories.effects';
import { PoliciesState } from './policies/policies.state';
import { PoliciesCategoriesState } from './policies-categories/policies-categories.state';
import { PoliciesReducer } from './policies/policies.reducer';
import { PoliciesCategoriesReducer } from './policies-categories/policies-categories.reducer';
import { ExperiencesEffects } from './experiences/experiences.effects';
import { ExperiencesState } from './experiences/experiences.state';
import { ExperiencesReducer } from './experiences/experiences.reducer';
import { OfficesEffects } from './offices/offices.effects';
import { OfficesTypesEffects } from './offices-types/offices-types.effects';
import { OfficesState } from './offices/offices.state';
import { OfficesTypesState } from './offices-types/offices-types.state';
import { OfficesReducer } from './offices/offices.reducer';
import { OfficesTypesReducer } from './offices-types/offices-types.reducer';
import { VideosCategoriesEffects } from './videos-categories/videos-categories.effects';
import { VideosEffects } from './videos/videos.effects';
import { VideosState } from './videos/videos.state';
import { VideosCategoriesState } from './videos-categories/videos-categories.state';
import { VideosCategoriesReducer } from './videos-categories/videos-categories.reducer';
import { VideosReducer } from './videos/videos.reducer';
import { EventsEffects } from './events-post/events.effects';
import { EventsCategoriesEffects } from './events-categories/events-categories.effects';
import { EventsState } from './events-post/events.state';
import { EventsCategoriesState } from './events-categories/events-categories.state';
import { EventsReducer } from './events-post/events.reducer';
import { EventsCategoriesReducer } from './events-categories/events-categories.reducer';
import { ModulesTypesEffects } from './modules-types/modules-types.effects';
import { ModulesTypesState } from './modules-types/modules-types.state';
import { ModulesTypesReducer } from './modules-types/modules-types.reducer';
import { GalleriesEffects } from './galleries/galleries.effects';
import { GalleriesCategoriesEffects } from './galleries-categories/galleries-categories.effects';
import { GalleriesState } from './galleries/galleries.state';
import { GalleriesCategoriesState } from './galleries-categories/galleries-categories.state';
import { GalleriesReducer } from './galleries/galleries.reducer';
import { GalleriesCategoriesReducer } from './galleries-categories/galleries-categories.reducer';
import { PressEffects } from './press/press.effects';
import { PressCategoriesEffects } from './press-categories/press-categories.effects';
import { PressState } from './press/press.state';
import { PressCategoriesState } from './press-categories/press-categories.state';
import { PressReducer } from './press/press.reducer';
import { PressCategoriesReducer } from './press-categories/press-categories.reducer';
import { DocumentsEffects } from './documents/documents.effects';
import { DocumentsCategoriesEffects } from './documents-categories/documents-categories.effects';
import { DocumentsState } from './documents/documents.state';
import { DocumentsCategoriesState } from './documents-categories/documents-categories.state';
import { DocumentsReducer } from './documents/documents.reducer';
import { DocumentsCategoriesReducer } from './documents-categories/documents-categories.reducer';
import { ObituariesEffects } from './obituaries/obituaries.effects';
import { ObituariesState } from './obituaries/obituaries.state';
import { ObituariesReducer } from './obituaries/obituaries.reducer';
import { ModulesEffects } from './modules/modules.effects';
import { ModulesState } from './modules/modules.state';
import { ModulesReducer } from './modules/modules.reducer';
import { PublicationsEffects } from './publications/publications.effects';
import { PublicationsState } from './publications/publications.state';
import { PublicationsReducer } from './publications/publications.reducer';
import { CatalogCatalogState } from './catalog/catalog.state';
import { CatalogCatalogReducer } from './catalog/catalog.reducer';
import { CatalogCategoriesState } from './catalog-categories/catalog-categories.state';
import { CatalogCategoriesReducer } from './catalog-categories/catalog-categories.reducer';
import { CatalogCatalogEffects } from './catalog/catalog.effects';
import { CatalogCategoriesEffects } from './catalog-categories/catalog-categories.effects';
import { CatalogSubcategoriesEffects } from './catalog-subcategories/catalog-subcategories.effects';
import { CatalogSubcategoriesState } from './catalog-subcategories/catalog-subcategories.state';
import { CatalogSubcategoriesReducer } from './catalog-subcategories/catalog-subcategories.reducer';
import { AdminsEffects } from './admins/admins.effects';
import { AdminsState } from './admins/admins.state';
import { AdminsReducer } from './admins/admins.reducer';
import { CitiesEffects } from './cities/cities.effects';
import { CitiesState } from './cities/cities.state';
import { CitiesReducer } from './cities/cities.reducer';

export const effects = [
    RouterEffects,
    LayoutEffects,
    AuthEffects,

    AsideEffects,
    AsideItemEffects,

    PagesEffects,
    PagesTypesEffects,

    BlogEffects,
    BlogCategoriesEffects,

    BlogSecondEffects,
    BlogSecondCategoriesEffects,

    GalleriesEffects,
    GalleriesCategoriesEffects,

    EventsEffects,
    EventsCategoriesEffects,

    LeadsEffects,
    LeadsTypesEffects,

    GlossariesEffects,

    CatalogsEffects,

    TestimonialsEffects,

    FaqsEffects,
    FaqsCategoriesEffects,

    PromotionsEffects,

    PublicationsEffects,

    PoliciesEffects,
    PoliciesCategoriesEffects,

    VideosEffects,
    VideosCategoriesEffects,

    ObituariesEffects,

    PressEffects,
    PressCategoriesEffects,

    DocumentsEffects,
    DocumentsCategoriesEffects,

    ExperiencesEffects,

    OfficesEffects,
    OfficesTypesEffects,

    ModulesTypesEffects,
    ModulesEffects,

    CatalogCatalogEffects,
    CatalogCategoriesEffects,
    CatalogSubcategoriesEffects,

    AdminsEffects,

    CitiesEffects
];

export interface AppState {
    layout: LayoutState;
    router: RouterReducerState<RouterState>;

    aside: AsideState;
    asideItem: AsideItemState;

    auth: AuthState;

    pages: PagesState;
    pagesTypes: PagesTypesState;

    blog: BlogState;
    blogCategories: BlogCategoriesState;

    blogSecond: BlogSecondState;
    blogSecondCategories: BlogSecondCategoriesState;

    galleries: GalleriesState;
    galleriesCategories: GalleriesCategoriesState;

    events: EventsState;
    eventsCategories: EventsCategoriesState;

    leads: LeadsState;
    leadsTypes: LeadsTypesState;

    glossaries: GlossariesState;

    catalogs: CatalogsState;

    testimonials: TestimonialsState;

    faqs: FaqsState;
    faqsCategories: FaqsCategoriesState;
    
    promotions: PromotionsState;

    publications: PublicationsState;

    policies: PoliciesState;
    policiesCategories: PoliciesCategoriesState;

    videos: VideosState;
    videosCategories: VideosCategoriesState;

    obituaries: ObituariesState;

    press: PressState;
    pressCategories: PressCategoriesState;

    documents: DocumentsState;
    documentsCategories: DocumentsCategoriesState;

    experiences: ExperiencesState;

    offices: OfficesState;
    officesTypes: OfficesTypesState;

    modulesTypes: ModulesTypesState;
    modules: ModulesState;

    catalog: CatalogCatalogState;
    catalogCategories: CatalogCategoriesState;
    catalogSubcategories: CatalogSubcategoriesState;

    admins: AdminsState;

    cities: CitiesState;

}


export const reducers: ActionReducerMap<AppState> = {
    layout: LayoutReducer,
    router: routerReducer,

    aside: AsideReducer,
    asideItem: AsideItemReducer,

    auth: AuthReducer,

    pages: PagesReducer,
    pagesTypes: PagesTypesReducer,

    blog: BlogReducer,
    blogCategories: BlogCategoriesReducer,

    blogSecond: BlogSecondReducer,
    blogSecondCategories: BlogSecondCategoriesReducer,

    galleries: GalleriesReducer,
    galleriesCategories: GalleriesCategoriesReducer,

    events: EventsReducer,
    eventsCategories: EventsCategoriesReducer,

    leads: LeadsReducer,
    leadsTypes: LeadsTypesReducer,

    glossaries: GlossariesReducer,

    catalogs: CatalogsReducer,

    testimonials: TestimonialsReducer,

    faqs: FaqsReducer,
    faqsCategories: FaqsCategoriesReducer,

    promotions: PromotionsReducer,

    publications: PublicationsReducer,

    policies: PoliciesReducer,
    policiesCategories: PoliciesCategoriesReducer,

    videos: VideosReducer,
    videosCategories: VideosCategoriesReducer,

    obituaries: ObituariesReducer,

    press: PressReducer,
    pressCategories: PressCategoriesReducer,

    documents : DocumentsReducer,
    documentsCategories: DocumentsCategoriesReducer,

    experiences: ExperiencesReducer,

    offices: OfficesReducer,
    officesTypes: OfficesTypesReducer,

    modulesTypes: ModulesTypesReducer,
    modules: ModulesReducer,

    catalog: CatalogCatalogReducer,
    catalogCategories: CatalogCategoriesReducer,
    catalogSubcategories: CatalogSubcategoriesReducer,

    admins: AdminsReducer,

    cities: CitiesReducer

};

