import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as catalogsActions from './catalogs.actions';

import { CatalogsService } from '../../shared/services/catalogs.service';



@Injectable()
export class CatalogsEffects {

    constructor(
        private catalogsService: CatalogsService,
        private actions$: Actions
    ) { }

    @Effect()
    DoGetInfoCatalogs$: Observable<Action> = this.actions$.pipe(
        ofType(catalogsActions.DO_GET_INFO_CATALOGS),
        switchMap((action: catalogsActions.DoGetInfoCatalogs) => {
            return this.catalogsService.get(action).pipe(
                map( response => {
                    response.list = this.catalogsService.transformListFromApi(response.list);
                    return new catalogsActions.DoGetInfoCatalogsOK(response);
                }),
                catchError(error => of(new catalogsActions.DoGetInfoCatalogsKO(error)))
            );
        }));

    @Effect()
    DoGetInfoCatalogsFilters$: Observable<Action> = this.actions$.pipe(
        ofType(catalogsActions.DO_GET_INFO_FILTERS),
        switchMap((action: catalogsActions.DoGetInfoCatalogsFilters) => {
            return this.catalogsService.getFilters().pipe(
                map( response => new catalogsActions.DoGetInfoCatalogsFiltersOK(response)),
                catchError(error => of(new catalogsActions.DoGetInfoCatalogsFiltersKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedFiltersCatalogs$: Observable<Action> = this.actions$.pipe(
        ofType(catalogsActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: catalogsActions.DoSetSelectedFilters) => {
            return this.catalogsService.get(action).pipe(
                map( response => new catalogsActions.DoGetInfoCatalogsOK(response)),
                catchError(error => of(new catalogsActions.DoGetInfoCatalogsKO(error)))
            );
        }));
}
