import { Action } from '@ngrx/store';

// BLOG
export const DO_GET_INFO_ADMINS = '[Admins] Get Info Admins';
export class DoGetInfoAdmins implements Action {
    type = DO_GET_INFO_ADMINS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_ADMINS_OK = '[Admins] Get Info Admins OK';
export class DoGetInfoAdminsOK implements Action {
    type = DO_GET_INFO_ADMINS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_ADMINS_KO = '[Admins] Get Info Admins KO';
export class DoGetInfoAdminsKO implements Action {
    type = DO_GET_INFO_ADMINS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Admins] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoAdmins | DoGetInfoAdminsOK | DoGetInfoAdminsKO
    | DoSetSelectedFilters
    ;
