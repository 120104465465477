import { Action } from '@ngrx/store';

// PRESS
export const DO_GET_INFO_PRESS_CATEGORIES = '[Press Categories] Get Info Categories';
export class DoGetInfoPressCategories implements Action {
    type = DO_GET_INFO_PRESS_CATEGORIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_PRESS_CATEGORIES_OK = '[Press Categories] Get Info Categories OK';
export class DoGetInfoPressCategoriesOK implements Action {
    type = DO_GET_INFO_PRESS_CATEGORIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_PRESS_CATEGORIES_KO = '[Press Categories] Get Info Categories KO';
export class DoGetInfoPressCategoriesKO implements Action {
    type = DO_GET_INFO_PRESS_CATEGORIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Press Categories] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoPressCategories | DoGetInfoPressCategoriesOK | DoGetInfoPressCategoriesKO 
    | DoSetSelectedFilters
    ;
