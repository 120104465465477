import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { BlogPost } from '../models/blog-post';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { BlogCategoriesService } from './blog-categories.service';
import { PagesService } from './pages.service';
import { CustomRequest } from '../helpers/custom-request';

import { DatePipe } from '@angular/common';
import { AppConfigService } from './app-config.service';


@Injectable({ providedIn: 'root' })
export class BlogService {
    constructor(
        private http: HttpClient,
        private categoriesService: BlogCategoriesService,
        private pagesService: PagesService,
        private request: CustomRequest,
        private appConfig: AppConfigService
    ) { }

    getFilters() {
        return this.request.get(`${environment.apiUrl}/post-category`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/post`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/post/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/post/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/post/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/post/` + id);
    }

    setContent(data: any) {
        return this.request.post(`${environment.apiUrl}/post/` + data._id + `/content/`, data.content);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/post/` + id + `/enabled/` + status, null);
    }

    getFiltersParamsToObject(data: any) {
        const paramFilters: any = {};
        const params = new HttpParams();
        if (data.filters) {
            if (data.filters.start_at) {
                const datePipe = new DatePipe('en-US');
                const dateFormat = datePipe.transform(data.filters.start_at, 'yyy-MM-dd');
                paramFilters.start_at = dateFormat;
            }

            if (data.filters.end_at) {
                const datePipe = new DatePipe('en-US');
                const dateFormat = datePipe.transform(data.filters.end_at, 'yyy-MM-dd');
                paramFilters.end_at = dateFormat;
            }
        }

        if (data.paginator) {
            paramFilters.page = data.paginator.currentPage;
            paramFilters.limit = data.paginator.perPage;
        }

        return paramFilters;
    }

    getExcel(data) {
        const idType = data.filters.type;

        const httpParams = new HttpParams({
            fromObject : this.getFiltersParamsToObject(data)
        });
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        const options = { params: httpParams, headers, responseType: 'blob' as 'json' };
        return this.http.get<any>(`${environment.apiUrl}/post/xls`, options);
    }

    transformDataFromApi(dataPost: any) {
        const generalConfig = this.appConfig.webConfig.blogData;

        const post = new BlogPost();

        post.sites = dataPost.sites;
        post._id = dataPost._id;
        post.alt = dataPost.alt;
        post.category = dataPost.category ? this.categoriesService.transformDataFromApi(dataPost.category) : null;
        post.content = dataPost.content;
        post.enabled = dataPost.enabled;
        post.highlighted = dataPost.highlighted;
        post.show_image_header_detail = dataPost.show_image_header_detail;
        post.image = dataPost.image;
        post.legend = dataPost.legend;

        post.page = dataPost.page ? this.pagesService.transformDataFromApi(dataPost.page) : null;

        post.title = dataPost.title;
        post.preview = dataPost.preview;

        post.published_at = dataPost.published_at;
        post.created_at = dataPost.created_at;
        post.updated_at = dataPost.updated_at;
        // post.published_at = dataPost.published_at.slice(0, -1);
        // post.created_at = dataPost.created_at.slice(0, -1);
        // post.updated_at = dataPost.updated_at.slice(0, -1);
        post.tags = dataPost.tags;
        post.url = dataPost.url;

        post.classes = dataPost.classes;

        post.hashtags = dataPost.hashtags;

        if (generalConfig.controlLangs) {
            post.languages = dataPost.languages ? dataPost.languages : [];
        }

        return post;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}