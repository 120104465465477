export class PageType {
    _id: string;
    view: string;
    image: string;
    name: any;
    config: any;
    enabled: boolean;
    created_at: string;
    updated_at: string;
    sites: Array<string>;
}