import { Action } from '@ngrx/store';

// TESTIMONIALS
export const DO_GET_INFO_TESTIMONIALS = '[Testimonials] Get Info Testimonials';
export class DoGetInfoTestimonials implements Action {
    type = DO_GET_INFO_TESTIMONIALS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_TESTIMONIALS_OK = '[Testimonials] Get Info Testimonials OK';
export class DoGetInfoTestimonialsOK implements Action {
    type = DO_GET_INFO_TESTIMONIALS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_TESTIMONIALS_KO = '[Testimonials] Get Info Testimonials KO';
export class DoGetInfoTestimonialsKO implements Action {
    type = DO_GET_INFO_TESTIMONIALS_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Testimonials] Get Info Filters';
export class DoGetInfoTestimonialsFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Testimonials] Get Info Filters OK';
export class DoGetInfoTestimonialsFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Testimonials] Get Info Filters KO';
export class DoGetInfoTestimonialsFiltersKO implements Action {
    type = DO_GET_INFO_TESTIMONIALS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Testimonials] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}

export type All = DoGetInfoTestimonials | DoGetInfoTestimonialsOK | DoGetInfoTestimonialsKO | 
    DoGetInfoTestimonialsFilters | DoGetInfoTestimonialsFiltersOK | DoGetInfoTestimonialsFiltersKO |
    DoSetSelectedFilters;
