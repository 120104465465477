import { BlogCategory } from './blog-category';
import { Page } from './page';

export class BlogPost {
    _id: string;
    alt: any;
    // category: BlogCategory | string;
    categories: any;
    category: any;
    content: any;
    enabled: boolean;
    highlighted: boolean;
    image: any;
    image_name: any;
    legend: any;
    page: Page;
    url: any;
    published_at: string;
    tags: any;
    hashtags: any;
    preview: any;
    title: any;

    show_image_header_detail: boolean;
    classes: any;

    languages: any;

    created_at: string;
    updated_at: string;
    sites: Array<string>;

}
