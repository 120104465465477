import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Video } from '../models/video';

import { VideosCategoriesService } from './videos-categories.service';
import { PagesService } from './pages.service';
import { CustomRequest } from '../helpers/custom-request';


@Injectable({ providedIn: 'root' })
export class VideosService {
    constructor(
        private categoriesService: VideosCategoriesService,
        private request: CustomRequest
    ) { }

    getFilters() {
        return this.request.get(`${environment.apiUrl}/video-category`);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/video`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/video/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/video/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/video/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/video/` + id);
    }

    order(data) {
        return this.request.post(`${environment.apiUrl}/video/order`, data);
    }
    
    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/video/` + id + `/enabled/` + status, null);
    }

    transformDataFromApi(data: any) {
        const video = new Video();

        /**
        - _id
        - category 
        - enabled (boolean)
        - image (string)
        - order (number)
        - title (object)
        - url (string)
        */

        video._id = data._id;
        video.category = data.category ? this.categoriesService.transformDataFromApi(data.category) : null;
        video.enabled = data.enabled;
        video.image = data.image;
        video.order = data.order;
        video.title = data.title;
        video.url = data.url;

        // solo corporate:
        video.published_at = data.published_at ? data.published_at : "";

        return video;
    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }
}