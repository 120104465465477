import { Action } from '@ngrx/store';

// BLOG
export const DO_GET_INFO_POLICIES_CATEGORIES = '[Policies Categories] Get Info Categories';
export class DoGetInfoPoliciesCategories implements Action {
    type = DO_GET_INFO_POLICIES_CATEGORIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_POLICIES_CATEGORIES_OK = '[Policies Categories] Get Info Categories OK';
export class DoGetInfoPoliciesCategoriesOK implements Action {
    type = DO_GET_INFO_POLICIES_CATEGORIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_POLICIES_CATEGORIES_KO = '[Policies Categories] Get Info Categories KO';
export class DoGetInfoPoliciesCategoriesKO implements Action {
    type = DO_GET_INFO_POLICIES_CATEGORIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Policies Categories] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoPoliciesCategories | DoGetInfoPoliciesCategoriesOK | DoGetInfoPoliciesCategoriesKO 
    | DoSetSelectedFilters
    ;
