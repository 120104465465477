import { ActionReducer, Action } from '@ngrx/store';
import * as fromActions from './aside-item.actions';
import { AsideItemState } from './aside-item.state';
import { SET_ERROR_CHILD } from './aside-item.actions';



const defaultState: AsideItemState = {
    data: null,
    mode: null,
    result: null,
    toSave: false,
    hideActions: false,
    saveChild: false,
    closeForm: false,
    error: null,
    errorChild: null
};

export type Action = fromActions.All;

export function AsideItemReducer(state = defaultState, action: any) {
    // console.log(state, action);
    switch (action.type) {

        // case fromActions.TOGGLE_COLLAPSED:
        //     return { ...state, isCollapseAside: !state.isCollapseAside };


        case fromActions.SET_INFO:
            // console.log('RELOAD ACTION PAYLOAD', action.payload);
            return { ...state,
                data: action.payload.data,
                mode: action.payload.mode
            };

        case fromActions.SET_RESULT:
            return { ...state,
                result: action.payload
            };

        case fromActions.CHANGE_TOSAVE:
            return { ...state,
                toSave: action.payload
            };

        case fromActions.RESET_INFO:
            return { ...defaultState };

        case fromActions.CHANGE_HIDE_ACTIONS:
            return { ...state,
                hideActions: action.payload
            };

        case fromActions.SAVE_CHILD_ACTIONS:
            return { ...state,
                saveChild: true
            };

        case fromActions.CANCEL_SAVE_CHILD_ACTIONS:
            return { ...state,
                saveChild: false
            };

        case fromActions.CLOSE_FORM_ACTIONS:
            return { ...state,
                closeForm: true,
                saveChild: false,
                hideActions: false
            };

        case fromActions.RESET_CLOSE_FORM_ACTIONS:
            return { ...state,
                closeForm: false,
                saveChild: false
            };

        case fromActions.SET_ERROR:
            return { ...state,
                error: action.payload
            };

        case fromActions.SET_ERROR_CHILD:
            return { ...state,
                errorChild: action.payload
            };

        
        default:
            return state;
    }
}
