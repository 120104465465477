import { ActionReducer, Action } from '@ngrx/store';
import * as fromActions from './aside.actions';
import { AsideState } from './aside.state';
import { SET_TO_SAVE, CHANGE_MODE } from './aside.actions';



const defaultState: AsideState = {
    data: null,
    mode: null,
    id_type: null,
    typeModule: null,
    title: null,
    result: null,
    done: false,
    toSave: false,
    section: '',
    delete: false,
    edit: false,
    active: false,
    add: null,
    index: null,
    box: null,
    hideActions: false,
    configMode: '',
    error: null
};

export type Action = fromActions.All;

export function AsideReducer(state = defaultState, action: any) {
    // console.log(state, action);
    switch (action.type) {

        // case fromActions.TOGGLE_COLLAPSED:
        //     return { ...state, isCollapseAside: !state.isCollapseAside };


        case fromActions.SET_INFO:
            // console.log('RELOAD ACTION PAYLOAD', action.payload);
            return { ...state,
                data: action.payload.data,
                mode: action.payload.mode,
                title: action.payload.title,
                section: action.payload.section,
                result: null,
                done: false,
                toSave: false,
                box: action.payload.box ? parseInt(action.payload.box, 10) : 0,
                id_type: action.payload.id_type,
                typeModule: action.payload.type
            };

        case fromActions.CHANGE_MODE:
            return { ...state,
                mode: action.payload.mode,
                title: action.payload.title,
                id_type: action.payload.id_type,
                typeModule: action.payload.type
            };

        case fromActions.SET_RESULT:
            return { ...state,
                result: action.payload,
                done: true,
                // toSave: false,
            };

        case fromActions.SET_TO_SAVE:
            return { ...state,
                toSave: true,
                done: false
            };

        case fromActions.CANCEL_TO_SAVE:
            return { ...state,
                toSave: false,
                done: false
            };

        case fromActions.SET_TO_DELETE:
            return { ...state,
                delete: true,
                index: action.payload instanceof Object ? parseInt(action.payload.index, 10) : action.payload,
                box: action.payload instanceof Object ? parseInt(action.payload.box, 10) : 0
            };

        case fromActions.SET_TO_EDIT:
            return { ...state,
                edit: true,
                index: action.payload instanceof Object  ? action.payload.index : action.payload,
                box: action.payload.box ? action.payload.box : 0
            };

        case fromActions.SET_TO_ACTIVE:
            return { ...state,
                index: action.payload instanceof Object ? parseInt(action.payload.index, 10) : action.payload,
                box: action.payload instanceof Object ? parseInt(action.payload.box, 10) : 0,
                active: true
            };

        case fromActions.SET_TO_ADD:
            return { ...state,
                box: action.payload instanceof Object ? parseInt(action.payload.box, 10) : 0,
                add: action.payload.add
            };

        case fromActions.END_TO_ACTIVE:
            return { ...state,
                index: -1,
                box: -1,
                active: false
            };

        case fromActions.CHANGE_HIDE_ACTIONS:
            return { ...state,
                hideActions: action.payload
            };

        case fromActions.RESET_INFO:
            return { ...defaultState };

        case fromActions.SET_CONFIG_MODE:
            return { ...state,
                configMode: action.payload
            };

        case fromActions.SET_ERROR:
            return { ...state,
                error: action.payload.error
            };

        default:
            return state;
    }
}
