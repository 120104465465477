import { Action } from '@ngrx/store';

// EVENTS
export const DO_GET_INFO_EVENTS = '[Events] Get Info Events';
export class DoGetInfoEvents implements Action {
    type = DO_GET_INFO_EVENTS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_EVENTS_OK = '[Events] Get Info Events OK';
export class DoGetInfoEventsOK implements Action {
    type = DO_GET_INFO_EVENTS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_EVENTS_KO = '[Events] Get Info Events KO';
export class DoGetInfoEventsKO implements Action {
    type = DO_GET_INFO_EVENTS_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Events] Get Info Filters';
export class DoGetInfoEventsFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Events] Get Info Filters OK';
export class DoGetInfoEventsFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Events] Get Info Filters KO';
export class DoGetInfoEventsFiltersKO implements Action {
    type = DO_GET_INFO_EVENTS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Events] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoEvents | DoGetInfoEventsOK | DoGetInfoEventsKO |
    DoGetInfoEventsFilters | DoGetInfoEventsFiltersOK | DoGetInfoEventsFiltersKO 
    | DoSetSelectedFilters
    ;
