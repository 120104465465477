import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { VideoCategory } from '../models/video-category';
import { CustomRequest } from '../helpers/custom-request';

@Injectable({ providedIn: 'root' })
export class VideosCategoriesService {
    constructor(
        private request: CustomRequest
    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/video-category`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/video-category/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/video-category/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/video-category/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/video-category/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/video-category/` + id + `/enabled/` + status, null);
    }

    transformCategoriesFromApi(data: any) {
        const categories = [];

        data.forEach(element => {
            categories.push(this.transformDataFromApi(element));
        });

        return categories;
    }

    transformDataFromApi(dataCategory: any) {
        const category = new VideoCategory();

        category._id = dataCategory._id;
        category.name = dataCategory.name;
        category.code = dataCategory.code;
        category.enabled = dataCategory.enabled;
        category.sites = dataCategory.sites;
        category.order = dataCategory.order;

        // category.image = dataCategory.image;
        // category.created_at = dataCategory.created_at;
        // category.updated_at = dataCategory.updated_at;

        // category.video = dataCategory.video;

        return category;
    }
}