import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemsCtasComponent } from './shared/components/modules/items-ctas/items-ctas.component';
import { ItemsCtasTranslateComponent } from './shared/components/modules/items-ctas-translate/items-ctas-translate.component';

import { FormsModule } from '@angular/forms';
import { PipesModule } from './shared/pipes/pipes.module';
import { DragDropModule } from '@angular/cdk/drag-drop';


@NgModule({
    declarations: [
        ItemsCtasComponent,
        ItemsCtasTranslateComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        DragDropModule
    ],
    providers: [
    ],
    exports: [
        ItemsCtasComponent,
        ItemsCtasTranslateComponent
    ]
})
export class CtasModuleModule { }
