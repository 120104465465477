import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ModulesUtils } from '@app/shared/helpers/modules-utils';
import { ConfigurationsModule, ConfigurationsModuleHelper } from '../../../models/configurationsModule';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '../../../../store/index';
import { Store } from '@ngrx/store';
import * as AsideActions from '@app/store/aside/aside.actions';
import { AppConfigService } from '../../../services/app-config.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-module-common-configurations-form',
    templateUrl: './common-configurations.component.html',
    providers: []
})
export class CommonConfigurationsComponent implements OnChanges {

    @Input() submitted: boolean;
    @Input() dataLoaded: boolean;
    @Input() section: string;
    @Input() data: any;
    @Input() type: string;
    @Output() sendConfig = new EventEmitter<ConfigurationsModule>();
    @Output() isValid = new EventEmitter<boolean>();

    validConfigurations = false;
    configurations: ConfigurationsModule;
    module: any;

    moduleConfig: any;

    classes: Array<any>;
    classesInside: Array<any>;
    htmlTags: Array<any>;
    modesBg: Array<any>;
    modesBgColor: Array<any>;
    modesBgDecoration: Array<any>;
    
    modesSloped: Array<any>;
    modes: Array<any>;

    anchorEnabled = false;

    errorName = false;
    errorMode = false;
    errorModeSloped = false;
    // submitted = false;

    opened = false;

    allClass = [];
    myClasses = [];
    selectClass = '';

    myClassesInside = [];
    selectClassInside = '';

    allClassInside = [];

    constructor(
        private store: Store<AppState>,
        public appConfig: AppConfigService
    ) {

    }


    getHtmlTags() {
        return [
            { name: 'H1', value: 'h1' },
            { name: 'H2', value: 'h2' },
            { name: 'H3', value: 'h3' },
            { name: 'H4', value: 'h4' },
            { name: 'H5', value: 'h5' },
            { name: 'H6', value: 'h6' }
        ];
    }
   
    getImgTextModes() {
        return [
            { name: 'Izquierda', value: 'left' },
            { name: 'Derecha', value: 'right' }
        ];
    }

    setModes() {
        this.modes = this.moduleConfig.configurations.modes ? this.moduleConfig.configurations.modes.cases : null;
    }
    setClasses() {
        if (!this.moduleConfig.configurations.classes) {
            return;
        }
        
        this.allClass = _.cloneDeep(this.appConfig.webConfig.modulesData.commonClasses);

        this.classes = this.moduleConfig.configurations.classes.common ? this.allClass : null;
        if (this.moduleConfig.configurations.classes.custom) {
            if (this.classes) {
                this.classes = this.moduleConfig.configurations.classes.custom.concat(this.classes);
            } else {
                this.classes = this.moduleConfig.configurations.classes.custom;
            }

        }
    }

    setClassesInternal() {
        if (!this.moduleConfig.configurations.classes_internal) {
            return;
        }
        this.allClassInside = _.cloneDeep(this.appConfig.webConfig.modulesData.commonClassesInternal);

        // console.log('inter', this.appConfig.webConfig.modulesData.commonClassesInternal);
        // console.log('newClasses', this.allClassInside);
        this.classesInside = this.moduleConfig.configurations.classes_internal.common ? this.allClassInside : null;
        if (this.moduleConfig.configurations.classes_internal.custom) {
            if (this.classesInside) {
                this.classesInside = this.moduleConfig.configurations.classes_internal.custom.concat(this.classesInside);
            } else {
                this.classesInside = this.moduleConfig.configurations.classes_internal.custom;
            }

        }
    }

    setModesSloped() {
        this.modesSloped = this.moduleConfig.configurations.modesSloped ? this.moduleConfig.configurations.modesSloped.cases : null;
    }

    setModesBgColor() {
        this.modesBgColor = this.moduleConfig.configurations.modesBgColor ? this.moduleConfig.configurations.modesBgColor.cases : null;
    }

    setModesBgDecoration() {
        this.modesBgDecoration = this.moduleConfig.configurations.modesBgDecoration ? this.moduleConfig.configurations.modesBgDecoration.cases : null;
    }

    setHtmlTags() {
        this.htmlTags = this.moduleConfig.configurations.htmlTags ? this.getHtmlTags() : null;
    }

    setConfigs() {
        if (this.moduleConfig.configurations) {
            this.setModes();
            this.setClasses();
            this.setClassesInternal();
            this.setModesSloped();
            this.setModesBgColor();
            this.setHtmlTags();

        }

    }

    initConfigModule() {
        this.moduleConfig = this.appConfig.webConfig.modulesData[this.type];
    }

    initData() {
        this.resetData();
        this.initConfigModule();
        this.setConfigs();
        if (this.classes) {
            this.orderClasses();
        }

        if (this.section === 'modules') {
            this.module = this.data ? this.data : null;
        } else {
            const isEdit = (this.data && this.data.action && this.data.action === 'edit');
            this.module = isEdit ? this.data.modules[this.data.index].module : null;
        }
    }
    initConfigurations() {
        this.initData();

        const conf = {
            name: this.module ? this.module.name : '',
            classes: (this.module && this.module.classes) ? this.module.classes : { utility: '', utility_internal: '' },
            title_tag: this.module ? this.module.title_tag : 'h2',
            mode: this.module ? this.module.mode : '',
            mode_sloped: this.module ? this.module.mode_sloped : '',
            mode_bg: this.module ? this.module.mode_bg : '',
            mode_bg_color: this.module ? this.module.mode_bg_color : '',
            id_anchor: this.module ? this.module.id_anchor : '',
        };

        this.prepareCustomClasses(conf.classes);
        this.prepareCustomClassesInside(conf.classes);

        this.configurations = ConfigurationsModuleHelper.fromObject(conf);

        // console.log('configurations', this.configurations);

        this.setInitialValue();
    }

    prepareCustomClasses(classes) {
        let values;
        if (classes && classes.utility) {
            if (classes.utility instanceof Array || classes.utility instanceof Object) {
                values = [values];
            } else {
                classes.utility = classes.utility.trim();
                values = classes.utility.split(' ');
            }
        } else {
            values = [];
        }

        let index;
        let item;
        if (values.length) {
            values.forEach(element => {
                item = this.getClassByValue(element);

                if (item && item instanceof Object) {
                    this.myClasses.push(item);

                    index = this.getIndexClassByValue(item);
                    if (index > -1) {
                        this.classes.splice(index, 1);
                    }
                }
            });
        }

    }

    prepareCustomClassesInside(classes) {
        let values;
        // console.log('classes', classes);
        if (classes && classes.utility_internal) {
            if (classes.utility_internal instanceof Array || classes.utility_internal instanceof Object) {
                values = [values];
            } else {
                classes.utility_internal = classes.utility_internal.trim();
                values = classes.utility_internal.split(' ');
            }
        } else {
            values = [];
        }

        // console.log('values', values);

        let index;
        let item;
        if (values.length) {
            values.forEach(element => {
                // console.log('element', element);
                item = this.getClassInsideByValue(element);
                // console.log('item', item);

                if (item && item instanceof Object) {
                    this.myClassesInside.push(item);

                    index = this.getIndexClassInsideByValue(item);
                    if (index > -1) {
                        this.classesInside.splice(index, 1);
                    }
                }
            });
        }

    }

    init() {

    }

    checkRequiredFields() {
        this.errorName = this.configurations.name === '';
        this.errorMode = this.modes && this.modes.length && (!this.configurations.mode || this.configurations.mode === '');
        this.errorModeSloped = this.modesSloped && this.modesSloped.length && (!this.configurations.mode_sloped || this.configurations.mode_sloped === '');
        this.validConfigurations = !this.errorName && !this.errorMode;
    }

    setInitialValue() {
        this.checkRequiredFields();
        this.sendConfig.emit(this.configurations);
        this.isValid.emit(this.validConfigurations);

        this.store.dispatch(new AsideActions.SetConfigMode(this.configurations.mode));
    }

    setClassesToConfig() {
        let values = '';
        if (this.myClasses.length > 0) {

            this.myClasses.forEach(element => {
                values += element.value + ' ';
            });

        }
        this.configurations.classes.utility = values;
    }

    setClassesInsideToConfig() {
        let values = '';
        if (this.myClassesInside.length > 0) {

            this.myClassesInside.forEach(element => {
                values += element.value + ' ';
            });

        }
        this.configurations.classes.utility_internal = values;
    }

    setValue() {
        this.checkRequiredFields();
        this.submitted = true;

        this.setClassesToConfig();
        this.setClassesInsideToConfig();

        this.sendConfig.emit(this.configurations);
        this.isValid.emit(this.validConfigurations);
    }

    setMode() {
        this.store.dispatch(new AsideActions.SetConfigMode(this.configurations.mode));
        this.setValue();
    }


    checkOpened() {
        if (!this.validConfigurations) {
            this.opened = true;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dataLoaded && this.dataLoaded) {
            this.initConfigurations();
        }

        if (changes.submitted) {
            this.checkOpened();
        }
    }

    addClass(ev) {
        const value = this.getClassByValue(this.selectClass);
        this.myClasses.push(value);

        const index = this.getIndexClassByValue(value);
        if (index > -1) {
            this.classes.splice(index, 1);
        }

        this.selectClass = '';

        this.setValue();
    }

    orderClasses() {
        this.classes.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
    }

    removeClass(ev, index) {
        const item = this.myClasses[index];
        this.classes.push(item);
        this.orderClasses();
        this.myClasses.splice(index, 1);

        this.selectClass = '';

        this.setValue();
    }

    getClassByValue(value) {
        const allClasses = this.classes.filter(x => x.value === value)[0];
        return allClasses;
    }

    getIndexClassByValue(item) {
        let find = false;
        let index = 0;
        this.classes.forEach(element => {
            if (element.value === item.value) {
                find = true;
            }

            if (!find) {
                index++;
            }
        });


        return find ? index : -1;
    }


    addClassInside(ev) {
        const value = this.getClassInsideByValue(this.selectClassInside);
        this.myClassesInside.push(value);

        const index = this.getIndexClassInsideByValue(value);
        if (index > -1) {
            this.classesInside.splice(index, 1);
        }

        this.selectClassInside = '';

        this.setValue();
    }

    orderClassesInside() {
        this.classesInside.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
    }

    removeClassInside(ev, index) {
        const item = this.myClassesInside[index];
        this.classesInside.push(item);
        this.orderClassesInside();
        this.myClassesInside.splice(index, 1);

        this.selectClassInside = '';

        this.setValue();
    }

    getClassInsideByValue(value) {
        const allClasses = this.classesInside.filter(x => x.value === value)[0];
        return allClasses;
    }

    getIndexClassInsideByValue(item) {
        let find = false;
        let index = 0;
        this.classesInside.forEach(element => {
            if (element.value === item.value) {
                find = true;
            }

            if (!find) {
                index++;
            }
        });


        return find ? index : -1;
    }

    resetData() {
        this.classes = [];
        this.myClasses = [];
        this.classesInside = [];
        this.myClassesInside = [];
    }
}
