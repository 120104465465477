import { Action } from '@ngrx/store';

// DOCUMENTS
export const DO_GET_INFO_DOCUMENTS = '[Documents] Get Info Documents';
export class DoGetInfoDocuments implements Action {
    type = DO_GET_INFO_DOCUMENTS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_DOCUMENTS_OK = '[Documents] Get Info Documents OK';
export class DoGetInfoDocumentsOK implements Action {
    type = DO_GET_INFO_DOCUMENTS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_DOCUMENTS_KO = '[Documents] Get Info Documents KO';
export class DoGetInfoDocumentsKO implements Action {
    type = DO_GET_INFO_DOCUMENTS_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Documents] Get Info Filters';
export class DoGetInfoDocumentsFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Documents] Get Info Filters OK';
export class DoGetInfoDocumentsFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Documents] Get Info Filters KO';
export class DoGetInfoDocumentsFiltersKO implements Action {
    type = DO_GET_INFO_DOCUMENTS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Documents] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoDocuments | DoGetInfoDocumentsOK | DoGetInfoDocumentsKO |
    DoGetInfoDocumentsFilters | DoGetInfoDocumentsFiltersOK | DoGetInfoDocumentsFiltersKO 
    | DoSetSelectedFilters
    ;
