// import { BlogCategory } from './blog-category';
import { Page } from './page';

export class PromotionPost {
    _id: string;
    title: any;
    antetitle: any;
    subtitle: any;
    ctas: any;
    icon: string;
    image: any;
    image_name: any;
    page: Page;
    order: any;

    // content: any; // no se usa

    sites: Array<string>;
    enabled: boolean;

    created_at: string; // no se usa
    published_at: string; // no se usa
    updated_at: string; // no se usa
    // ------------------
    // category: BlogCategory | string;
    // alt: any;
    // categories: any;
    // category: any;
    // highlighted: boolean;
    // legend: any;
    // url: string;
    // tags: any;
    // preview: any;
    // show_image_header_detail: boolean;
}
/*
{
      "_id": "5bc5b05b82910999351e6237",
      "antetitle": {
        "es_ES": "",
        "en_US": ""
      },
      "ctas": [
        {
          "name": "Haz clic y mira lo que hicieron",
          "text": {
            "es_ES": "Haz clic y mira lo que hicieron",
            "en_US": "Click and see what they did"
          },
          "url": {
            "es_ES": "/tu-familia-de-seguros",
            "en_US": "/en/your-insurance-family"
          }
        }
      ],
      "enabled": true,
      "icon": "/assets/images/casa-candado.svg",
      "image": "/assets/images/promocion-familia-seguros.png",
      "order": 9,
      "page": {
        "modules": [
          
        ],
        "sites": [
          
        ],
        "urls": [
          {
            "_id": "5bc5b05b82910999351e6234",
            "enabled": true,
            "i18n": "5bc5afc382910999351dbd86",
            "page": "5bc5b05b82910999351e6230",
            "site": "5bc5afc382910999351dbd89",
            "type": "page",
            "url": "/promocion/tu-familia-de-seguros",
            "__v": 0,
            "created_at": "2018-10-16T09:33:15.574Z",
            "updated_at": "2019-06-21T07:34:52.287Z",
            "alternative": {
              "_en": "/en/special-offer/your-insurance-family"
            }
          },
          {
            "_id": "5d0c889cc637c5fffe94069b",
            "alternative": {
              "_es": "/promocion/tu-familia-de-seguros"
            },
            "enabled": true,
            "i18n": "5bc5afc382910999351dbd8f",
            "page": "5bc5b05b82910999351e6230",
            "site": "5bc5afc382910999351dbd89",
            "type": "page",
            "url": "/en/special-offer/your-insurance-family",
            "created_at": "2019-06-21T07:34:52.291Z",
            "updated_at": "2019-06-21T07:34:52.291Z"
          }
        ],
        "_id": "5bc5b05b82910999351e6230",
        "enabled": true,
        "related_id": "5bc5b05b82910999351e6237",
        "related_table": "promotion",
        "seo": {
          "es_ES": {
            "title": "La familia nunca falla | Meridiano Seguros",
            "description": "Compañía de seguros. Desde 1966 garantizando tu tranquilidad y la de tu familia."
          },
          "en_US": {
            "description": "Insurance company. Since 1966, guaranteeing your tranquility and that of your family.",
            "title": "The Family Never Fails | Meridiano Seguros"
          }
        },
        "title": {
          "es_ES": "La familia nunca falla",
          "en_US": "The Family Never Fails"
        },
        "type": "5bc5b05b82910999351e622e",
        "__v": 1,
        "created_at": "2018-10-16T09:33:15.568Z",
        "updated_at": "2020-03-04T15:53:33.695Z",
        "hidden": true
      },
      "subtitle": {
        "es_ES": "Pedimos ayuda a nuestros familiares para hacer el nuevo spot de Meridiano… ¡Y vinieron encantados!  La familia nunca falla y si algo falla ahí está Meridiano.",
        "en_US": "We asked our relatives to help us make the new Meridiano ad... And they were delighted to do so! The family never fails - and if anything does fail, there's always Meridiano."
      },
      "title": {
        "es_ES": "La familia nunca falla",
        "en_US": "The Family Never Fails"
      },
      "__v": 0,
      "created_at": "2018-10-16T09:33:15.580Z",
      "updated_at": "2020-05-06T14:06:15.286Z"
    }

    */