import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { CatalogCategory } from '../models/catalog-category';
import { CustomRequest } from '../helpers/custom-request';
import { PagesService } from './pages.service';

@Injectable({ providedIn: 'root' })
export class CatalogCategoriesService {
    constructor(
        private request: CustomRequest,
        private pagesService: PagesService
    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/catalog-category`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/catalog-category/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/catalog-category/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/catalog-category/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/catalog-category/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/catalog-category/` + id + `/enabled/` + status, null);
    }

    transformCategoriesFromApi(data: any) {
        const categories = [];

        data.forEach(element => {
            categories.push(this.transformDataFromApi(element));
            
        });

        return categories;
    }

    transformDataFromApi(data: any) {
        const category = new CatalogCategory();

        category._id = data._id;
        category.code = data.code;
        category.name = data.name;
        category.name_img = data.name_img;
        category.description = data.description;
        category.image = data.image;
        category.icon_hero = data.icon_hero;
        category.icons = data.icons;
        category.title_list_products = data.title_list_products;
        category.description_list_products = data.description_list_products;
        category.products_list = data.products_list;
        category.products_carousel = data.products_carousel;
        category.order = data.order;
        category.enabled = data.enabled;

        category.page = data.page ? this.pagesService.transformDataFromApi(data.page) : null;

        category.sites = data.sites;
        category.catalogs = data.catalogs;
        category.subcategories = data.subcategories;

        category.created_at = data.created_at;
        category.updated_at = data.updated_at;

        return category;
    }
}
