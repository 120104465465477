import { VideoCategory } from './video-category';

export class Video {
    _id: string;
    category: any;
    enabled: boolean;
    image: string;
    image_name: string;
    order: number;
    title: any;
    url: string;
    sites: Array<string>;

    // solo corporate:
    published_at: string;

}