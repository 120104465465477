import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { LeadType } from '../models/lead-type';
import { CustomRequest } from '../helpers/custom-request';

@Injectable({ providedIn: 'root' })
export class LeadsTypesService {
    constructor(
        private request: CustomRequest
    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/form-data-type`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/form-data-type/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/form-data-type/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/form-data-type/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/form-data-type/` + id);
    }

    transformDataFromApi(dataType: any) {
        const type = new LeadType();

        type.name = dataType.name;
        type._id = dataType._id;
        type.enabled = dataType.enabled;
        type.code = dataType.code;
        type.config = dataType.config;
        type.created_at = dataType.created_at;
        type.updated_at = dataType.updated_at;

        return type;

    }
}