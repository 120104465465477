import { Injectable } from '@angular/core';
import { I18n } from '../models/i18n';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppState } from '../../store/index';
import { Store } from '@ngrx/store';
import * as LayoutActions from '@app/store/layout/layout.actions';
import { CustomRequest } from '../helpers';
import { environment } from '@environments/environment';


@Injectable({ providedIn: 'root' })
export class I18nService {
    currentLang: any;

    private currentLanguage: BehaviorSubject<I18n>;
    public currentLanguage$: Observable<I18n>;

    constructor(
        private store: Store<AppState>
    ) {
        this.initSuscriptions();
    }

    initSuscriptions() {
        this.currentLanguage = new BehaviorSubject<I18n>(new I18n());
        this.currentLanguage$ = this.currentLanguage.asObservable();

    }

    setI18nTemp() {
        const i18nData = [];

        const i18nEs = new I18n();
        i18nEs.name = 'Castellano';
        i18nEs._id = '5bc5afc382910999351dbd86';
        i18nEs.iso = 'es';
        i18nEs.locale = 'es_ES';
        i18nData.push(i18nEs);

        const i18nEn = new I18n();
        i18nEn.name = 'English';
        i18nEn._id = '5bc5afc382910999351dbd8f';
        i18nEn.iso = 'en';
        i18nEn.locale = 'en_US';

        i18nData.push(i18nEn);

        const i18nFr = new I18n();
        i18nFr.name = 'Francés';
        i18nFr._id = '1bc5afc382910999351dbd81';
        i18nFr.iso = 'fr';
        i18nFr.locale = 'fr_FR';

        i18nData.push(i18nFr);

        return i18nData;
    }

    transformDataFromApi(data: any) {
        const i18n = new I18n();

        if (data.hasOwnProperty('i18n')) {
            i18n.name = data.i18n.name;
            i18n.iso = data.i18n.iso;
            i18n.locale = data.i18n.locale;
            i18n._id = data.i18n._id;
        } else {
            i18n.name = data.name;
            i18n.iso = data.iso;
            i18n.locale = data.locale;
            i18n._id = data._id;
        }

        return i18n;
    }

    public get currentLanguageData(): I18n {
        return this.currentLanguage.value;
    }

    getLanguageByIso(languages, iso): I18n {
        return languages.filter(x => x.iso === iso)[0];
    }

    getLanguageById(languages, id): I18n {
        return languages.filter(x => x.id === id)[0];
    }

    setCurrentLanguage(i18n: I18n) {
        this.currentLang = I18n;
        this.currentLanguage.next(i18n);

        this.saveLanguageStorage(i18n);

        this.store.dispatch(new LayoutActions.SetReload(true));
    }

    saveLanguageStorage(language): void {
        sessionStorage.setItem('language', JSON.stringify(language));
        this.currentLang = language;
    }
    getCurrentLanguageStorage(): string {
        const lang = sessionStorage.getItem('language');

        return lang ? JSON.parse(lang) : null;
    }
    getCurrentLanguage() {
        if (!this.currentLang) {
            this.currentLang = this.getCurrentLanguageStorage();
        }
        return this.currentLang;
    }

    deleteLanguageData(): void {
        sessionStorage.removeItem('language');
    }

    hasLanguage() {
        if (this.getCurrentLanguage()) {
            return true;
        }

        return false;
    }
}
