import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as officesActions from './offices.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { OfficesService } from '../../shared/services/offices.service';



@Injectable()
export class OfficesEffects {

    constructor(
        private officesService: OfficesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoOffices$: Observable<Action> = this.actions$.pipe(
        ofType(officesActions.DO_GET_INFO_OFFICES),
        switchMap((action: officesActions.DoGetInfoOffices) => {
            return this.officesService.get(action).pipe(
                map( response => new officesActions.DoGetInfoOfficesOK(response)),
                catchError(error => of(new officesActions.DoGetInfoOfficesKO(error)))
            );
        }));

    @Effect()
    DoGetInfoOfficesFilters$: Observable<Action> = this.actions$.pipe(
        ofType(officesActions.DO_GET_INFO_FILTERS),
        switchMap((action: officesActions.DoGetInfoOfficesFilters) => {
            return this.officesService.getFiltersGroup().pipe(
                map( response => new officesActions.DoGetInfoOfficesFiltersOK(response)),
                catchError(error => of(new officesActions.DoGetInfoOfficesFiltersKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedFiltersCategories$: Observable<Action> = this.actions$.pipe(
        ofType(officesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: officesActions.DoSetSelectedFilters) => {
            return this.officesService.get(action).pipe(
                map( response => new officesActions.DoGetInfoOfficesOK(response)),
                catchError(error => of(new officesActions.DoGetInfoOfficesKO(error)))
            );
        }));
}
