import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
})
export class ModulesComponent  {

  @Input() data: any;
  @Input() modeView: string;
  @Input() index: number;
  @Input() box: number;
  @Input() section: string;
  @Input() typeSection: string;

}
