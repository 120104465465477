import { Action } from '@ngrx/store';

// MODULES
export const DO_GET_INFO_MODULES = '[Modules] Get Info Modules';
export class DoGetInfoModules implements Action {
    type = DO_GET_INFO_MODULES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_MODULES_OK = '[Modules] Get Info Modules OK';
export class DoGetInfoModulesOK implements Action {
    type = DO_GET_INFO_MODULES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_MODULES_KO = '[Modules] Get Info Modules KO';
export class DoGetInfoModulesKO implements Action {
    type = DO_GET_INFO_MODULES_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Modules] Get Info Filters';
export class DoGetInfoModulesFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Modules] Get Info Filters OK';
export class DoGetInfoModulesFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Modules] Get Info Filters KO';
export class DoGetInfoModulesFiltersKO implements Action {
    type = DO_GET_INFO_MODULES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Modules] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}

export type All = DoGetInfoModules | DoGetInfoModulesOK | DoGetInfoModulesKO | 
    DoGetInfoModulesFilters | DoGetInfoModulesFiltersOK | DoGetInfoModulesFiltersKO |
    DoSetSelectedFilters;
