import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as authActions from './auth.actions';

import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router } from '@angular/router';

import { RoutesService } from '../../shared/services/routes.service';
import { Md5 } from 'ts-md5/dist/md5';
import { SitesService } from '../../shared/services/sites.service';

import { AppState } from '../index';
import * as LayoutActions from '../layout/layout.actions';

@Injectable()
export class AuthEffects {

    constructor(
        private authenticationService: AuthenticationService,
        private sitesService: SitesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService,
        private store$: Store<AppState>
    ) { }

    @Effect()
    AutoLogin$: Observable<Action> = this.actions$.pipe(
        ofType(authActions.AUTO_LOGIN),
        switchMap((action: authActions.AutoLogin) => {
            return this.authenticationService.autoLogin().pipe(
                map( response => new authActions.AutoLoginSuccess(response)),
                catchError(error => of(new authActions.AutoLoginFail(error)))
            );
        }));

    @Effect({ dispatch: false })
    AutoLoginSuccess: Observable<any> = this.actions$.pipe(
        ofType(authActions.AUTO_LOGIN_SUCCESS),
        tap((data) => {
            this.sitesService.getSitesFromApi(false);
            return true;
        })
    );

    @Effect({ dispatch: false })
    AutoLoginFail: Observable<any> = this.actions$.pipe(
        ofType(authActions.AUTO_LOGIN_FAIL),
        tap(() => {
            this.authenticationService.deleteAuthData();
            this.sitesService.deleteSiteAndLanguageData();
            return false;
        })
    );

    @Effect()
    DoLogin$: Observable<Action> = this.actions$.pipe(
        ofType(authActions.DO_LOGIN),
        switchMap((action: authActions.DoLogin) => {
            return this.authenticationService.login(action.payload).pipe(
                // map( response => new authActions.DoLoginSuccess(response)),
                map( response => new authActions.DoLoginSuccess(this.authenticationService.authDataCredentials)),
                catchError(error => of(new authActions.DoLoginFail(error)))
            );
        }));

    @Effect({ dispatch: false })
    DoLoginSuccess: Observable<any> = this.actions$.pipe(
        ofType(authActions.DO_LOGIN_SUCCESS),
        tap((data) => {
            this.authenticationService.saveAuthData();
            this.sitesService.getSitesFromApi(true);
            // this.router.navigate([this.routesService.getRoute('dashboard')], {});
        })
    );

    @Effect({ dispatch: false })
    DoLogout$: Observable<Action> = this.actions$.pipe(
        ofType(authActions.DO_LOGOUT),
        tap((data) => {
            this.authenticationService.deleteAuthData();
            this.sitesService.deleteSiteAndLanguageData();
            this.store$.dispatch(new LayoutActions.CloseAsideConfig());
            this.router.navigate([this.routesService.getRoute('login')], {});
        })
    );

    // @Effect({ dispatch: false })
    // DoUpdateUser$: Observable<Action> = this.actions$.pipe(
    //     ofType(authActions.DO_UPDATE_USER),
    //     tap((data) => {
    //         // this.authenticationService.saveUser(data);
    //     })
    // );
   
    // @Effect()
    // DoLoginFail$: Observable<Action> = this.actions$.pipe(
    //     ofType(authActions.DO_LOGIN_FAIL));

}
